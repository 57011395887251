"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const colors_1 = require("uni/style/colors");
const colors = [
    colors_1.lavender,
    colors_1.rose,
    colors_1.water,
    colors_1.water2,
    colors_1.lake,
    colors_1.lavender2,
    colors_1.corn,
    colors_1.lake2,
    colors_1.corn2,
];
const colorFromIndex = (index) => {
    const colorIndex = index % (colors.length - 1);
    return colors[colorIndex];
};
exports.default = colorFromIndex;
