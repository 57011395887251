"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const types_1 = require("./DelegationCoachOption/types");
const delegationCoachCategoryToDelegationCoachCategoryType = (category) => {
    switch (category) {
        case generated_1.DelegationCoachCategory.CONTACT_MANAGEMENT:
        case generated_1.DelegationCoachCategory.HR_AND_RECRUITING:
        case generated_1.DelegationCoachCategory.SOCIAL_MEDIA:
        case generated_1.DelegationCoachCategory.EVENTS:
        case generated_1.DelegationCoachCategory.CALENDAR_AND_SCHEDULING:
        case generated_1.DelegationCoachCategory.MEETINGS:
        case generated_1.DelegationCoachCategory.TRAVEL:
        case generated_1.DelegationCoachCategory.PERSONAL_TASKS:
            return types_1.DelegationCoachCategoryType.TEAM;
        case generated_1.DelegationCoachCategory.EMAIL_AND_INBOX:
        case generated_1.DelegationCoachCategory.COMMUNICATIONS:
        case generated_1.DelegationCoachCategory.TECH:
        case generated_1.DelegationCoachCategory.PHONE:
            return types_1.DelegationCoachCategoryType.COMMUNICATIONS;
        case generated_1.DelegationCoachCategory.DOCUMENT_DRAFTING_AND_EDITING:
        case generated_1.DelegationCoachCategory.PROJECT_MANAGEMENT:
            return types_1.DelegationCoachCategoryType.DOCUMENT;
        case generated_1.DelegationCoachCategory.SALES:
        case generated_1.DelegationCoachCategory.SHIPPING:
            return types_1.DelegationCoachCategoryType.SURPRISE;
        case generated_1.DelegationCoachCategory.FINANCE:
            return types_1.DelegationCoachCategoryType.FINANCE;
    }
};
exports.default = delegationCoachCategoryToDelegationCoachCategoryType;
