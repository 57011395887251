"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function mergeFlags(fetchedFeatureFlags, defaultFeatures, allFlagCases, onReadKey) {
    const featureFlags = {};
    return Object.defineProperties(featureFlags, allFlagCases.reduce((currentFeatureFlags, flag) => {
        return Object.assign(Object.assign({}, currentFeatureFlags), { [flag]: {
                get: () => {
                    if ((fetchedFeatureFlags === null || fetchedFeatureFlags === void 0 ? void 0 : fetchedFeatureFlags[flag]) === undefined) {
                        // Do not log features until we know their remote values
                        return defaultFeatures[flag] || false;
                    }
                    const value = fetchedFeatureFlags[flag];
                    onReadKey(flag, value);
                    return value;
                },
            } });
    }, {}));
}
exports.default = mergeFlags;
