"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const react_1 = require("react");
const cache_1 = require("uni/graphql/helpers/cache");
const generated_1 = require("./generated");
const useReorderWorkspace = () => {
    const [mutate, result] = (0, generated_1.useReorderWorkspaceMutation)();
    const client = (0, client_1.useApolloClient)();
    const execute = (0, react_1.useCallback)((workspaceId, orderIndex) => {
        const frag = (0, cache_1.findFragment)(client, {
            id: `Workspace:${workspaceId}`,
            fragment: generated_1.GlobalWorkspaceFragmentDoc,
            fragmentName: 'GlobalWorkspace',
        });
        let optimisticResponse;
        if (frag) {
            optimisticResponse = {
                __typename: 'Mutation',
                reorderWorkspace: {
                    __typename: 'ReorderWorkspaceOutput',
                    workspace: Object.assign(Object.assign({}, frag), { orderIndex }),
                },
            };
        }
        mutate({
            variables: {
                input: {
                    orderIndex,
                    workspaceId,
                },
            },
            optimisticResponse,
        });
    }, [client, mutate]);
    return [execute, result];
};
exports.default = useReorderWorkspace;
