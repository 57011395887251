"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
const getNotificationPermission = () => {
    if (!constants_1.SUPPORTS_NOTIFICATIONS) {
        return 'denied';
    }
    // local storage takes precedence over browser permission if it's set to 'denied'
    // eg. user accepts browser permission, then disables notifications in settings
    if (localStorage.getItem(constants_1.NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY) === 'denied') {
        return 'denied';
    }
    return Notification.permission;
};
exports.default = getNotificationPermission;
