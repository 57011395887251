"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const resolvers_1 = require("../resolvers");
const useCreateFlashNotice = (key) => (0, react_1.useCallback)((content, type) => {
    (0, resolvers_1.flashNotices)((0, resolvers_1.flashNotices)().concat([
        { __typename: 'FlashNotice', key, content, type },
    ]));
    setTimeout(() => (0, resolvers_1.flashNotices)((0, resolvers_1.flashNotices)().filter((f) => f.key !== key)), 5000);
}, [key]);
exports.default = useCreateFlashNotice;
