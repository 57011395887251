"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flashNotices = void 0;
const client_1 = require("@apollo/client");
exports.flashNotices = (0, client_1.makeVar)([]);
const resolvers = {
    Query: {
        fields: {
            flashNotices: {
                read: () => (0, exports.flashNotices)(),
            },
            flashNoticeByKey: {
                read: (_existing, { args }) => (0, exports.flashNotices)().find((f) => args && args.key ? f.key === args.key : true),
            },
        },
    },
};
exports.default = resolvers;
