"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = exports.sharedButtonStyle = exports.lockedItemStyle = exports.commonItemStyle = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const reset_1 = require("uni/style/reset");
exports.commonItemStyle = (0, styled_components_1.css) `
  margin-bottom: 8px;
  border-radius: 11px;
  // 💩 Do not use overflow: hidden to avoid clipping the tooltips
  // Will be fixed with https://double.height.app/T-4964
`;
exports.lockedItemStyle = (0, styled_components_1.css) `
  ${exports.commonItemStyle}
  border: 0.5px solid ${colors_1.gray4};
  background-color: ${colors_1.gray1};
`;
const sharedButtonStyle = (disabled) => (0, styled_components_1.css) `
  ${reset_1.buttonReset}

  color: ${colors_1.gray6};
  background-color: transparent;
  border: none;
  border-radius: 6px;
  padding: 0px;

  cursor: default;

  ${!disabled &&
    (0, styled_components_1.css) `
    cursor: pointer;

    :hover {
      background-color: ${colors_1.gray1};
    }
  `}
`;
exports.sharedButtonStyle = sharedButtonStyle;
exports.Button = styled_components_1.default.button `
  ${({ disabled }) => (0, exports.sharedButtonStyle)(!!disabled)}
`;
