"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const metrics_1 = require("uni/lib/metrics");
const types_1 = require("uni/lib/metrics/types");
const trackUniversalLink = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    if (params.get('campaignName') && params.get('linkName')) {
        metrics_1.metrics.track(types_1.TrackingEvent.UNIVERSAL_LINK_OPENED, {
            campaignName: params.get('campaignName'),
            linkName: params.get('linkName'),
        });
    }
};
exports.default = trackUniversalLink;
