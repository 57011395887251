"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLeftPosition = exports.getTopPosition = exports.getDraggedDom = void 0;
const DRAG_ATTRIBUTE = 'data-rbd-drag-handle-draggable-id';
const getDraggedDom = (draggableId) => document.querySelector(`[${DRAG_ATTRIBUTE}='${draggableId}']`);
exports.getDraggedDom = getDraggedDom;
const getTopPosition = (draggedDOM, children, index) => {
    const draggedDOMStyle = window.getComputedStyle(draggedDOM);
    // getTopPosition won't get called unless parentElement is not null
    const parentStyle = window.getComputedStyle(draggedDOM.parentElement);
    return (parseFloat(parentStyle.marginTop) +
        parseFloat(parentStyle.paddingTop) +
        parseFloat(draggedDOMStyle.marginTop) +
        parseFloat(draggedDOMStyle.paddingTop) +
        children.slice(0, index).reduce((total, childElement) => {
            const style = window.getComputedStyle(childElement);
            const marginTop = parseFloat(style.marginTop);
            const paddingTop = parseFloat(style.paddingTop);
            const marginBottom = parseFloat(style.marginBottom);
            const paddingBottom = parseFloat(style.paddingBottom);
            return (total +
                childElement.clientHeight +
                marginTop +
                paddingTop +
                marginBottom +
                paddingBottom);
        }, 0));
};
exports.getTopPosition = getTopPosition;
const getLeftPosition = (draggedDOM) => {
    const draggedDOMStyle = window.getComputedStyle(draggedDOM);
    // getLeftPosition won't get called unless parentElement is not null
    const parentStyle = window.getComputedStyle(draggedDOM.parentElement);
    return (parseFloat(parentStyle.paddingLeft) +
        parseFloat(parentStyle.marginLeft) +
        parseFloat(draggedDOMStyle.paddingLeft) +
        parseFloat(draggedDOMStyle.marginLeft));
};
exports.getLeftPosition = getLeftPosition;
