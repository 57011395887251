"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Calendar = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const mixins_1 = require("uni/style/mixins");
const text_1 = require("uni/style/text");
const calendarText = (0, styled_components_1.css) `
  font: 'Inter';
  font-weight: 400;
  font-size: 11px;
  line-height: normal;
  letter-spacing: -0.01rem;
`;
const timeslotText = (0, styled_components_1.css) `
  ${calendarText}
  font-weight: 600;
`;
const fullCalendarStyle = (0, styled_components_1.css) `
  ${calendarText}

  .fc-scrollgrid {
    border: none;
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-body > td {
    border: none;
  }

  .fc-timegrid-divider {
    display: none;
  }

  .fc-daygrid-day {
    border: none;
  }

  .fc-timegrid-axis {
    border: none;
  }

  .fc-daygrid-body .fc-timegrid-axis-cushion {
    display: none;
  }

  .fc-daygrid-body .fc-timegrid-axis {
    border: none;
  }

  .fc-daygrid-body {
    border-bottom: 1px solid ${colors_1.gray3};
  }

  .fc-col-header-cell {
    border: none;
    ${text_1.labelRegular}
    color: ${colors_1.gray6};

    &.fc-day-today {
      font-weight: 600;
      color: ${colors_1.black};
    }
  }

  .fc-event {
    border-width: 0;
    padding: 2px;
    border-radius: 4px;
    cursor: default;
  }

  .fc-event-title {
    ${timeslotText}
    color: #ffffff;
  }

  .fc-event-time {
    ${calendarText}
    color: #ffffff;
  }

  .fc-event.hold {
    border-width: 2px;
    padding: 0 2px;
    cursor: move;

    .fc-event-title,
    .fc-event-time {
      color: ${colors_1.purple5};
    }

    &:hover::after {
      content: '';
      height: 2px;
      width: 30%;
      background-color: #9082f7;
      position: absolute;
      bottom: 2px;
      border-radius: 4px;
      margin-left: 32%;
    }
  }

  .fc-event.start-here {
    padding: 0;

    .fc-event-title {
      color: ${colors_1.white};
      text-align: center;
    }
    .fc-event-time {
      visibility: hidden;
    }
  }

  .fc-daygrid-day.fc-day-today,
  .fc-timegrid-col.fc-day-today {
    background-color: #f7f6ff; /* rgba($purple5Color, 0.05); */
  }

  .fc-timegrid-col {
    border: none;
  }

  .fc-timegrid-slot {
    border-color: ${colors_1.gray3};
    border-left: none;
  }

  .fc-timegrid-slot-label {
    border: none;
    ${text_1.labelSemibold}
    color: ${colors_1.gray5};
    transform: translateY(-9px);
    // display: inline-block;
    // padding: 0 16px 0 0;
    text-align: right;
    // width: 100%;
    &[data-time='00:00:00'] {
      transform: none;
    }
  }

  .fc-timegrid-slot-minor {
    border: none;
  }

  .fc-event-mirror {
    background: ${colors_1.gray4};
  }

  // Now Indicator
  .fc-timegrid-now-indicator-arrow {
    display: none;
  }
  .fc-timegrid-now-indicator-line {
    background-color: ${colors_1.flamingo5};
    border: 0;
    height: 1px;

    &::before {
      position: absolute;
      ${(0, mixins_1.circle)(8)};
      top: 0.93px;
      left: 0;
      transform: translateY(-50%);
      background-color: ${colors_1.flamingo5};
      content: '';
    }
  }

  .fc-scrollgrid-section-header > td {
    border: none;
  }

  .fc-non-business {
    // The angle is empirically set to work well with the width of the confirm match modal
    background: repeating-linear-gradient(
      312.7deg,
      ${colors_1.gray1},
      ${colors_1.gray1} 5px,
      ${colors_1.gray2} 5px,
      ${colors_1.gray2} 10px
    );
  }
`;
exports.Outer = styled_components_1.default.div `
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;

  .fc {
    ${fullCalendarStyle}
  }
`;
exports.Calendar = styled_components_1.default.div `
  flex-grow: 1;
  height: 100%;
`;
