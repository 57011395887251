"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("../generated");
const useHideDossierProgress = () => {
    const [mutate, result] = (0, generated_1.useHideDossierProgressMutation)();
    const execute = (0, react_1.useCallback)(({ dossierId, execId }) => __awaiter(void 0, void 0, void 0, function* () {
        const optimisticResponse = {
            __typename: 'Mutation',
            hideDossierProgress: {
                __typename: 'HideDossierProgressOutput',
                dossier: {
                    __typename: 'WorkspaceUserDossier',
                    id: dossierId,
                    isProgressHidden: true,
                },
            },
        };
        return yield mutate({
            variables: {
                input: {
                    userId: execId,
                },
            },
            optimisticResponse: optimisticResponse || undefined,
        });
    }), [mutate]);
    return [execute, result];
};
exports.default = useHideDossierProgress;
