"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const useGetSignedUrl = (setLoading) => {
    const client = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)((file, upload) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading === null || setLoading === void 0 ? void 0 : setLoading(true);
        try {
            const { data } = yield client.mutate({
                mutation: generated_1.UploadPublicFileDocument,
                variables: {
                    input: {
                        name: file.name,
                        mime: file.type,
                    },
                },
            });
            (data === null || data === void 0 ? void 0 : data.upload) && upload(Object.assign({ fileKey: '' }, data.upload));
        }
        catch (error) {
            console.error(error);
            setLoading === null || setLoading === void 0 ? void 0 : setLoading(false);
        }
    }), [client, setLoading]);
};
exports.default = useGetSignedUrl;
