"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateMostRecentWorkspaceId = exports.getMostRecentWorkspaceId = void 0;
const MOST_RECENT_WORKSPACE_KEY = 'double-most-recent-workspace-id';
const getMostRecentWorkspaceId = () => {
    try {
        return localStorage.getItem(MOST_RECENT_WORKSPACE_KEY);
    }
    catch (error) {
        return null;
    }
};
exports.getMostRecentWorkspaceId = getMostRecentWorkspaceId;
const updateMostRecentWorkspaceId = (newValue) => {
    localStorage.setItem(MOST_RECENT_WORKSPACE_KEY, newValue);
};
exports.updateMostRecentWorkspaceId = updateMostRecentWorkspaceId;
