"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const cache_1 = require("uni/graphql/helpers/cache");
const generated_1 = require("uni/graphql/hooks/generated");
const useProjectById = (projectId, { skip = false } = {}) => {
    const client = (0, client_1.useApolloClient)();
    const result = (0, generated_1.useGetTopicByIdQuery)({
        variables: { id: projectId || '' },
        fetchPolicy: 'cache-and-network',
        skip,
    });
    if (result.loading) {
        const cachedTopic = (0, cache_1.findFragment)(client, {
            id: `Project:${projectId}`,
            fragment: generated_1.ProjectListItemFragmentDoc,
            fragmentName: 'ProjectListItem',
        });
        if (cachedTopic) {
            return {
                data: cachedTopic,
                loading: false,
            };
        }
    }
    return {
        data: (result.data && result.data.topic) || null,
        loading: result.loading,
    };
};
exports.default = useProjectById;
