"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useToggleSection = (initialState) => {
    const [toggleSectionStates, setStates] = (0, react_1.useState)(initialState);
    const toggleSection = (0, react_1.useCallback)((sectionName, onOff) => setStates((toggleSectionStates) => (Object.assign(Object.assign({}, toggleSectionStates), { [sectionName]: typeof onOff === 'boolean'
            ? onOff
            : !toggleSectionStates[sectionName] }))), [setStates]);
    return {
        toggleSectionStates,
        toggleSection,
    };
};
exports.default = useToggleSection;
