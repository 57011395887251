"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const useAdhocTodoAmountLabel = ({ promotionCode, }) => {
    const { data: price, error: priceError, loading: priceLoading, } = (0, generated_1.useGetAdhocTodoPriceQuery)();
    const { data: coupon, error: couponError, loading: couponLoading, } = (0, generated_1.useGetAdhocTodoCouponQuery)({
        variables: { promotionCode: promotionCode !== null && promotionCode !== void 0 ? promotionCode : '' },
        skip: !promotionCode,
    });
    const loading = priceLoading || couponLoading;
    const error = priceError || couponError;
    const tokenCountToAmountLabel = (0, react_1.useCallback)((tokenCount, maximumFractionDigits, minimumFractionDigits) => {
        var _a, _b, _c;
        if (!price)
            return { fullAmount: undefined, discountedAmount: undefined };
        let fullAmount = null;
        let discountedAmount = null;
        const unitAmount = price.adhocTodoPrice.unitAmount;
        const currency = price.adhocTodoPrice.currency;
        if (unitAmount && currency) {
            fullAmount = Intl.NumberFormat(navigator.language, {
                style: 'currency',
                currency: currency,
                maximumFractionDigits: maximumFractionDigits !== null && maximumFractionDigits !== void 0 ? maximumFractionDigits : 0,
                minimumFractionDigits: minimumFractionDigits !== null && minimumFractionDigits !== void 0 ? minimumFractionDigits : 0,
            }).format(tokenCount * (unitAmount / 100));
        }
        if (unitAmount) {
            const couponAmountOff = (_a = coupon === null || coupon === void 0 ? void 0 : coupon.adhocTodoCoupon) === null || _a === void 0 ? void 0 : _a.amountOff;
            const couponPercentOff = (_b = coupon === null || coupon === void 0 ? void 0 : coupon.adhocTodoCoupon) === null || _b === void 0 ? void 0 : _b.percentOff;
            const couponCurrency = (_c = coupon === null || coupon === void 0 ? void 0 : coupon.adhocTodoCoupon) === null || _c === void 0 ? void 0 : _c.currency;
            if (couponAmountOff && couponCurrency && couponCurrency === currency) {
                discountedAmount = Intl.NumberFormat(navigator.language, {
                    style: 'currency',
                    currency: currency,
                    maximumFractionDigits: 0,
                }).format(Math.max(tokenCount * unitAmount - couponAmountOff, 0) / 100);
            }
            else if (couponPercentOff) {
                discountedAmount = Intl.NumberFormat(navigator.language, {
                    style: 'currency',
                    currency: currency,
                    maximumFractionDigits: 2,
                }).format(Math.round(tokenCount * (unitAmount * (100 - couponPercentOff))) /
                    10000);
            }
        }
        return { fullAmount, discountedAmount };
    }, [price, coupon]);
    return { tokenCountToAmountLabel, loading, error };
};
exports.default = useAdhocTodoAmountLabel;
