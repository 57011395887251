"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TECH_CHECK_UP_LABEL = exports.BEST_PRACTICE_LABEL = exports.BEFORE_START_LABEL = void 0;
exports.BEFORE_START_LABEL = 'ONBOARDING_CALL';
exports.BEST_PRACTICE_LABEL = 'FIRST_30_DAYS';
exports.TECH_CHECK_UP_LABEL = 'TECH_CHECK_UP';
const ONBOARDING_LABELS = [
    exports.BEFORE_START_LABEL,
    exports.BEST_PRACTICE_LABEL,
    exports.TECH_CHECK_UP_LABEL,
];
const isOnboardingListTodo = (todo) => !!todo.labels &&
    todo.labels.some((label) => ONBOARDING_LABELS.includes(label));
exports.default = isOnboardingListTodo;
