"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeIsGroupsEmpty = exports.computeGroups = void 0;
const utils_1 = require("uni/components/MatchingCatalog/List/UtilityBar/SortByPopButton/utils");
const types_1 = require("../../types");
const utils_2 = require("../../utils");
const types_2 = require("../types");
const filtering_1 = require("./filtering");
const matchingToGroup = (matching) => {
    const stage = (0, utils_2.getApplicationStage)(matching);
    switch (stage) {
        case types_1.ApplicationStage.IS_WAITING_FOR_MY_REPLY:
        case types_1.ApplicationStage.ACCEPTED:
        case types_1.ApplicationStage.MORE_INFO:
        case types_1.ApplicationStage.SCHEDULED:
        case types_1.ApplicationStage.ONBOARDED:
            return types_2.GroupType.MATCHED;
        case types_1.ApplicationStage.APPLIED:
            return types_2.GroupType.INTEREST_SUBMITTED;
        case types_1.ApplicationStage.NO_MATCH:
        case types_1.ApplicationStage.DECLINE:
            return types_2.GroupType.NO_MATCH;
        default:
            return types_2.GroupType.NOT_APPLIED;
    }
};
const computeGroups = (matchings, sortByOption, filter) => {
    const processedMatchings = matchings
        .map((matching) => {
        var _a, _b;
        const country = (0, filtering_1.normalizeCountry)((_a = matching.profiles[0]) === null || _a === void 0 ? void 0 : _a.country);
        const hoursNeeded = (0, filtering_1.normalizeHoursRange)((_b = matching.profiles[0]) === null || _b === void 0 ? void 0 : _b.hoursNeededRange);
        return Object.assign(Object.assign({}, matching), { country,
            hoursNeeded });
    })
        .sort((0, utils_1.sortFromOption)(sortByOption));
    const groupsInit = {
        [types_2.GroupType.MATCHED]: {
            label: types_2.GroupType.MATCHED,
            matchings: [],
        },
        [types_2.GroupType.INTEREST_SUBMITTED]: {
            label: types_2.GroupType.INTEREST_SUBMITTED,
            matchings: [],
        },
        [types_2.GroupType.NOT_APPLIED]: {
            label: types_2.GroupType.NOT_APPLIED,
            matchings: [],
        },
        [types_2.GroupType.NO_MATCH]: {
            label: types_2.GroupType.NO_MATCH,
            matchings: [],
        },
    };
    const groups = processedMatchings.reduce((groups, matching) => {
        const matchingStage = (0, utils_2.getApplicationStage)(matching);
        // A matching with an ApplicationStage means the assistant applied to it
        const hasApplied = !!matchingStage;
        if ((0, filtering_1.matchesFilter)(filter, matching) ||
            // Always display matchings that the assistant applied to
            hasApplied) {
            groups[matchingToGroup(matching)].matchings.push(matching);
        }
        return groups;
    }, groupsInit);
    return [
        groups[types_2.GroupType.MATCHED],
        groups[types_2.GroupType.INTEREST_SUBMITTED],
        groups[types_2.GroupType.NOT_APPLIED],
        groups[types_2.GroupType.NO_MATCH],
    ];
};
exports.computeGroups = computeGroups;
const computeIsGroupsEmpty = (groups) => groups.every((group) => group.matchings.length === 0);
exports.computeIsGroupsEmpty = computeIsGroupsEmpty;
