"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputReset = exports.buttonReset = void 0;
const styled_components_1 = require("styled-components");
exports.buttonReset = (0, styled_components_1.css) `
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  text-align: left;
`;
exports.inputReset = (0, styled_components_1.css) `
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  text-align: left;
`;
