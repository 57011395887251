"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENT_VIEW_TYPE = exports.LegacyAttendeeStatus = exports.getOneWeekRangeStartingNow = void 0;
const luxon_1 = require("luxon");
const getOneWeekRangeStartingNow = () => ({
    start: luxon_1.DateTime.now(),
    end: luxon_1.DateTime.now().plus({ days: 7 }),
});
exports.getOneWeekRangeStartingNow = getOneWeekRangeStartingNow;
var LegacyAttendeeStatus;
(function (LegacyAttendeeStatus) {
    LegacyAttendeeStatus["NEEDS_ACTION"] = "needsAction";
    LegacyAttendeeStatus["DECLINED"] = "declined";
    LegacyAttendeeStatus["TENTATIVE"] = "tentative";
    LegacyAttendeeStatus["ACCEPTED"] = "accepted";
})(LegacyAttendeeStatus = exports.LegacyAttendeeStatus || (exports.LegacyAttendeeStatus = {}));
var EVENT_VIEW_TYPE;
(function (EVENT_VIEW_TYPE) {
    EVENT_VIEW_TYPE["EVENT"] = "event";
    EVENT_VIEW_TYPE["HOLD"] = "hold";
    EVENT_VIEW_TYPE["TIMESLOT"] = "timeslot";
})(EVENT_VIEW_TYPE = exports.EVENT_VIEW_TYPE || (exports.EVENT_VIEW_TYPE = {}));
