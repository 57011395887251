"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const POLLING_INTERVAL = 30 * 60 * 1000; // 30 minutes
function notNull(o) {
    return o != null;
}
const useMatchings = ({ polling = false } = {}) => {
    const result = (0, generated_1.useListMatchingsQuery)({
        pollInterval: polling ? POLLING_INTERVAL : undefined,
    });
    const matchings = (0, react_1.useMemo)(() => {
        var _a;
        return ((_a = result.data) === null || _a === void 0 ? void 0 : _a.list.items.filter(notNull).filter(({ application, proposal, profiles }) => {
            var _a, _b, _c, _d;
            return application ||
                proposal ||
                ((_b = (_a = profiles[0]) === null || _a === void 0 ? void 0 : _a.bioSections) === null || _b === void 0 ? void 0 : _b.length) ||
                (((_c = profiles[0]) === null || _c === void 0 ? void 0 : _c.country) && ((_d = profiles[0]) === null || _d === void 0 ? void 0 : _d.hoursNeededRange));
        })) || [];
    }, [result.data]);
    return (0, react_1.useMemo)(() => (Object.assign({ matchings }, result)), [matchings, result]);
};
exports.default = useMatchings;
