"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statusOverrides = void 0;
const client_1 = require("@apollo/client");
const luxon_1 = require("luxon");
const recurrence_1 = require("uni/components/TopicView/List/helpers/recurrence");
const weekday_1 = require("uni/components/TopicView/List/helpers/weekday");
const topicFields = {
    createdAtDateTime: {
        read: (_existing, { readField }) => {
            const createdAt = readField('createdAt');
            return typeof createdAt === 'string' ? luxon_1.DateTime.fromISO(createdAt) : null;
        },
        merge: false,
    },
};
const schedulableFields = {
    dueDateDateTime: {
        read: (_existing, { readField }) => {
            const dueDate = readField('dueDate');
            return typeof dueDate === 'string' ? luxon_1.DateTime.fromISO(dueDate) : null;
        },
        merge: false,
    },
};
exports.statusOverrides = (0, client_1.makeVar)({});
const statusableFields = {
    overrideStatus: {
        read: (_existing, { readField }) => {
            const id = readField('id');
            if (id) {
                const override = (0, exports.statusOverrides)()[id];
                return override !== null && override !== void 0 ? override : null;
            }
            return null;
        },
        merge: false,
    },
};
const resolvers = {
    Todo: {
        fields: Object.assign(Object.assign(Object.assign({}, topicFields), schedulableFields), statusableFields),
    },
    Project: {
        fields: Object.assign({}, schedulableFields),
    },
    TodoMonthlyRecurrence: {
        fields: {
            previousRecurrenceDateTime: {
                read: (_existing, { readField }) => {
                    const now = luxon_1.DateTime.local();
                    const day = readField('day');
                    return typeof day === 'number'
                        ? (0, recurrence_1.computePreviousMonthlyRecurrenceDate)(now, day)
                        : null;
                },
                merge: false,
            },
            nextRecurrenceDateTime: {
                read: (_existing, { readField }) => {
                    const now = luxon_1.DateTime.local();
                    const day = readField('day');
                    return typeof day === 'number'
                        ? (0, recurrence_1.computeNextMonthlyRecurrenceDate)(now, day)
                        : null;
                },
                merge: false,
            },
        },
    },
    TodoWeeklyRecurrence: {
        fields: {
            previousRecurrenceDateTime: {
                read: (_existing, { readField }) => {
                    const now = luxon_1.DateTime.local();
                    const days = readField('days');
                    const formattedDays = Array.isArray(days)
                        ? days.map((wd) => (0, weekday_1.translateGQLToLuxonWeekday)(wd))
                        : [];
                    return (0, recurrence_1.computePreviousWeeklyRecurrenceDate)(now, formattedDays);
                },
                merge: false,
            },
            nextRecurrenceDateTime: {
                read: (_existing, { readField }) => {
                    const now = luxon_1.DateTime.local();
                    const days = readField('days');
                    const formattedDays = Array.isArray(days)
                        ? days.map((wd) => (0, weekday_1.translateGQLToLuxonWeekday)(wd))
                        : [];
                    return (0, recurrence_1.computeNextWeeklyRecurrenceDate)(now, formattedDays);
                },
                merge: false,
            },
        },
    },
    TopicTimelineReceipt: {
        fields: {
            activityAtDateTime: {
                read: (_existing, { readField }) => {
                    const activityAt = readField('activityAt');
                    return typeof activityAt === 'string'
                        ? luxon_1.DateTime.fromISO(activityAt)
                        : null;
                },
                merge: false,
            },
        },
    },
};
exports.default = resolvers;
