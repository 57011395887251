"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.beginningOfWeekShifted = exports.endOfWeekShifted = exports.DAYS_IN_WEEK = void 0;
const absoluteModulo = (a, b) => {
    return ((a % b) + b) % b;
};
exports.DAYS_IN_WEEK = 7;
const endOfWeekShifted = (date, shiftToRight) => {
    const day = date.weekday;
    const diff = absoluteModulo(exports.DAYS_IN_WEEK - day - shiftToRight, exports.DAYS_IN_WEEK);
    return date.plus({ days: diff });
};
exports.endOfWeekShifted = endOfWeekShifted;
const beginningOfWeekShifted = (date, shiftToRight) => {
    const day = date.weekday;
    const negDiff = (day + shiftToRight - 1) % exports.DAYS_IN_WEEK;
    return date.minus({ days: negDiff });
};
exports.beginningOfWeekShifted = beginningOfWeekShifted;
