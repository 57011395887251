"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const redirectToAuthenticationPage = (client, { hint, onboardingToken, redirectUrl, isPoolUser, adhocTodoId, workingTimeZone, } = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const { data } = yield client.query({
        query: generated_1.GetGoogleAuthenticationUrlDocument,
        variables: Object.assign({ redirectUrl: redirectUrl || window.location.href.replace(window.location.search, ''), emailHint: hint, onboardingToken }, (isPoolUser && {
            poolUserInput: {
                adhocTodoId,
                workingTimeZone,
            },
        })),
    });
    window.open(data.popupUrl, '_self');
});
exports.default = redirectToAuthenticationPage;
