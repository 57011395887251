"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const ROUTES_1 = require("uni/components/ROUTES");
const useIsPoolWorkspace_1 = require("../workspace/useIsPoolWorkspace");
const useWorkspace_1 = require("../workspace/useWorkspace");
const useGetWorkspacedRoute = () => {
    const activeWorkspace = (0, useWorkspace_1.useWorkspaceOptional)();
    return (0, react_1.useCallback)((path = '/', workspaceId) => {
        if (typeof workspaceId === 'undefined') {
            workspaceId = activeWorkspace === null || activeWorkspace === void 0 ? void 0 : activeWorkspace.id;
        }
        if (!workspaceId ||
            !activeWorkspace ||
            path.startsWith(ROUTES_1.MATCHED_WORKSPACES_ROUTE) ||
            path.startsWith(ROUTES_1.POOL_WORKSPACES_ROUTE)) {
            return path;
        }
        return `${(0, useIsPoolWorkspace_1.isPoolWorkspace)(activeWorkspace)
            ? ROUTES_1.POOL_WORKSPACES_ROUTE
            : ROUTES_1.MATCHED_WORKSPACES_ROUTE}/${workspaceId}${path}`;
    }, [activeWorkspace]);
};
exports.default = useGetWorkspacedRoute;
