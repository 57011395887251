"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../../CandidateCard/types");
const useConfirmationMessages = (selectedCandidate, otherCandidate) => {
    if (!selectedCandidate || !otherCandidate)
        return {
            confirmMessage: '',
            cancelButtonMessage: '',
            confirmButtonMessage: '',
        };
    const selectedCandidateFirstName = selectedCandidate.assistant.givenName || 'your new assistant';
    const otherCandidateFirstName = otherCandidate.assistant.givenName || 'the other assistant';
    const otherCandidateCallIsPending = otherCandidate.stage === types_1.IntroCallStage.PENDING;
    // The confirm message is the main modal message displayed in the center of the modal
    const confirmMessage = otherCandidateCallIsPending
        ? `Great choice! We'll cancel your scheduled intro call with ${otherCandidateFirstName}, so they can be matched with someone else.`
        : `Great! To onboard with ${selectedCandidateFirstName}, you'll be unmatched from ${otherCandidateFirstName}.`;
    // The cancel button messagge is displayed in the secondary button, the button meant to cancel the operation
    const cancelButtonMessage = selectedCandidate.stage === types_1.IntroCallStage.COMPLETED &&
        otherCandidate.stage === types_1.IntroCallStage.COMPLETED
        ? 'Cancel'
        : `Meet with ${otherCandidateFirstName} first`;
    // The confirm button messagge is displayed in the primary button, the button meant to confirm the operation
    const confirmButtonMessage = `Onboard with ${selectedCandidateFirstName}`;
    return {
        confirmMessage,
        cancelButtonMessage,
        confirmButtonMessage,
    };
};
exports.default = useConfirmationMessages;
