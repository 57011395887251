"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ordering_1 = require("@withdouble/ordering");
/*
* WEIRD `newOrderIndex` logic - between
  This `newOrderIndex` logic might seem very ridiculous. I think so too.
  But it has to do with the way our Drag-And-Drop library calcuates re-ordering:

  Pretend our list has this many items; the numbers are the:
  [1, 2, 3, 4, 5, 6, 7]

  Pretend you want to put '3' at position '6':
  [1, 2, 3, 4, 5, 6, 7]
          |________^

  Your initial thought would be correct: between(5, 6).
  That would push what was '6' to '7'
    and leave '6' free for '3'.

  BUT THAT ONLY WORKS IF IT IS A DIFFERENT LIST.
  This because, in this Drag-And-Drop (DnD) library,
    it seems to calculate indexes by deleting before it adds.

  Doing this,
  [1, 2, 3, 4, 5, 6, 7]
          |________^

  Is actually,
  DELETE  [1, 2, 4, 5, 6, 7]
  ADD     [1, 2, 3, 4, 5, 6, 7] << 3
  So what was in position '6' is now in position '5'
    and what was position '7' is now in position '6'.

  credit:
    Adrien Atzert
    https://github.com/withDouble/double.web/pull/570
*/
const getNewOrderIndex = ({ sourceIndex, destinationIndex, destinationItemList, sourceSectionType, destinationSectionType, }) => {
    if (destinationItemList.length === 0) {
        return (0, ordering_1.middleish)();
    }
    if (destinationIndex === 0) {
        return (0, ordering_1.before)(destinationItemList[0].orderIndex);
    }
    // treat it like the same section if no source and destination section type
    const isDroppedInCurrentSection = (!sourceSectionType && !destinationSectionType) ||
        sourceSectionType === destinationSectionType;
    if (!isDroppedInCurrentSection &&
        destinationIndex > destinationItemList.length - 1) {
        // * ref 'WEIRD `newOrderIndex` logic - between'
        // same idea but applied to finding the 'last' item
        return (0, ordering_1.after)(destinationItemList[destinationItemList.length - 1].orderIndex);
    }
    if (isDroppedInCurrentSection &&
        destinationIndex >= destinationItemList.length - 1) {
        // * ref 'WEIRD `newOrderIndex` logic - between'
        // same idea but applied to finding the 'last' item
        return (0, ordering_1.after)(destinationItemList[destinationItemList.length - 1].orderIndex);
    }
    if (isDroppedInCurrentSection && destinationIndex >= sourceIndex) {
        // * ref 'WEIRD `newOrderIndex` logic - between'
        return (0, ordering_1.between)(destinationItemList[destinationIndex].orderIndex, destinationItemList[destinationIndex + 1].orderIndex);
    }
    else {
        // * ref 'WEIRD `newOrderIndex` logic - between'
        return (0, ordering_1.between)(destinationItemList[destinationIndex - 1].orderIndex, destinationItemList[destinationIndex].orderIndex);
    }
};
exports.default = getNewOrderIndex;
