"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddButton = exports.RemoveButton = exports.ButtonContainer = exports.UncheckedIcon = exports.CheckedIcon = exports.CheckboxContainer = exports.DateInputContainer = exports.TextArea = exports.TextInput = exports.InputTitle = exports.InputRow = exports.ExperienceContainer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const checkbox_off_l_svg_1 = require("uni/system/icons/checkbox-off-l.svg");
const checkbox_on_l_svg_1 = require("uni/system/icons/checkbox-on-l.svg");
const textInputStyle = (0, styled_components_1.css) `
  ${text_1.bodyRegular}
  padding: 8px 12px;
  width: 100%;
  background: ${colors_1.gray2};
  border: 2px solid ${colors_1.gray2};
  border-radius: 12px;
  color: ${colors_1.black};

  &:focus {
    border-color: ${colors_1.purple4};
    background: ${colors_1.white};
  }

  &::placeholder {
    color: ${colors_1.gray6};
  }

  ${({ $invalid }) => $invalid &&
    (0, styled_components_1.css) `
      border-color: ${colors_1.flamingo6};
    `}
`;
const buttonStyle = (0, styled_components_1.css) `
  cursor: pointer;
  padding: 10px 10px 10px 6px;
  margin-top: 10px;
  background-color: transparent;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    ${text_1.bodyRegular}
  }

  svg {
    margin-right: 4px;
  }
`;
exports.ExperienceContainer = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:not(:first-child) {
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid ${colors_1.gray2};
  }
`;
exports.InputRow = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
`;
exports.InputTitle = styled_components_1.default.div `
  ${text_1.bodyRegular}
  margin-bottom: 8px;
`;
exports.TextInput = styled_components_1.default.input `
  ${textInputStyle}
`;
exports.TextArea = styled_components_1.default.textarea `
  ${textInputStyle}
  min-height: 128px;
  resize: vertical;
`;
exports.DateInputContainer = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > div {
    flex: 1;
  }
`;
exports.CheckboxContainer = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  span {
    ${text_1.bodyRegular}
    margin-left: 10px;
  }
`;
exports.CheckedIcon = (0, styled_components_1.default)(checkbox_on_l_svg_1.ReactComponent) `
  color: ${colors_1.purple5};
`;
exports.UncheckedIcon = (0, styled_components_1.default)(checkbox_off_l_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
`;
exports.ButtonContainer = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
exports.RemoveButton = styled_components_1.default.button `
  ${buttonStyle}

  span {
    color: ${colors_1.flamingo6};
  }

  svg {
    color: ${colors_1.flamingo6};
  }
`;
exports.AddButton = styled_components_1.default.button `
  ${buttonStyle}

  span {
    color: ${colors_1.purple5};
  }

  svg {
    color: ${colors_1.purple5};
  }
`;
