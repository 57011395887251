"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const ensureLocalStorageItem = (key, initial) => {
    const data = localStorage.getItem(key);
    if (!data) {
        return initial;
    }
    try {
        return JSON.parse(data);
    }
    catch (error) { }
    return initial;
};
const writeLocalStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};
const makeLocalStorageVar = (key, initial) => {
    const v = (0, client_1.makeVar)(ensureLocalStorageItem(key, initial));
    return ((...args) => {
        if (args.length > 0) {
            writeLocalStorageItem(key, args[0]);
            return v(args[0]);
        }
        else {
            return v();
        }
    });
};
exports.default = makeLocalStorageVar;
