"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const types_1 = require("../../types");
const getSectionTypeFromId = (areaId) => {
    const unsafeSectionType = areaId;
    if (!!generated_1.TodoStatus[unsafeSectionType]) {
        return unsafeSectionType;
    }
    else if (unsafeSectionType.includes(types_1.projectSection)) {
        return types_1.projectSection;
    }
    return null;
};
exports.default = getSectionTypeFromId;
