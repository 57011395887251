"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopUpDirection = exports.PopUpPosition = void 0;
const react_1 = require("react");
const PopUpBoundaryRectProvider_1 = require("../PopUpBoundaryRectProvider");
/**
 * PopUpPosition indicates the position of the anchor, realtive to the given HTML
 * element, from which the PopUp will be positionned
 *
 * TOP_LEFT                TOP              TOP_RIGHT
 *      X-------------------X-------------------X
 *      |                                       |
 *      |                                       |
 * LEFT X                 ANCHOR                X  RIGHT
 *      |                                       |
 *      |                                       |
 *      X-------------------X-------------------X
 * BOTTOM_LEFT            BOTTOM           BOTTOM_RIGHT
 */
var PopUpPosition;
(function (PopUpPosition) {
    PopUpPosition["TOP"] = "top";
    PopUpPosition["TOP_RIGHT"] = "top-right";
    PopUpPosition["RIGHT"] = "right";
    PopUpPosition["BOTTOM_RIGHT"] = "bottom-right";
    PopUpPosition["BOTTOM"] = "bottom";
    PopUpPosition["BOTTOM_LEFT"] = "bottom-left";
    PopUpPosition["LEFT"] = "left";
    PopUpPosition["TOP_LEFT"] = "top-left";
})(PopUpPosition = exports.PopUpPosition || (exports.PopUpPosition = {}));
/**
 * PopUpDirection indicates what direction the popover will flow (for lack of a better term) from the anchor
 *
 * TOP          ⬆️   (the bottom, center of the popover will be touching the anchor)
 * TOP_RIGHT    ↗️   (the bottom, left of the popover will be touching the anchor)
 * RIGHT        ➡️   (the middle, left of the popover will be touching the anchor)
 * BOTTOM_RIGHT ↘️   (the top, left of the popover will be touching the anchor)
 * BOTTOM       ⬇️   (the top, center of the popover will be touching the anchor)
 * BOTTOM_LEFT  ↙️   (the top, right of the popover will be touching the anchor)
 * LEFT         ⬅️   (the middle, right of the popover will be touching the anchor)
 * TOP_LEFT     ↖️   (the bottom, right of the popover will be touching the anchor)
 */
var PopUpDirection;
(function (PopUpDirection) {
    PopUpDirection["TOP"] = "top";
    PopUpDirection["TOP_RIGHT"] = "top-right";
    PopUpDirection["RIGHT"] = "right";
    PopUpDirection["BOTTOM_RIGHT"] = "bottom-right";
    PopUpDirection["BOTTOM"] = "bottom";
    PopUpDirection["BOTTOM_LEFT"] = "bottom-left";
    PopUpDirection["LEFT"] = "left";
    PopUpDirection["TOP_LEFT"] = "top-left";
})(PopUpDirection = exports.PopUpDirection || (exports.PopUpDirection = {}));
const useDerivedStyle = ({ inPortal, domRect, direction = PopUpDirection.BOTTOM_RIGHT, position = PopUpPosition.TOP_LEFT, safeMargin = {}, offsetX = 0, offsetY = 0, show, applyDOMRectWidth, }) => {
    // Use BoundaryRect in case the app does not take up the full viewport (eg.
    // when an Intercom banner is present)
    const boundaryRect = (0, PopUpBoundaryRectProvider_1.usePopUpBoundaryRect)(!show);
    const { x: safeMarginX = 8, y: safeMarginY = 8 } = safeMargin;
    const left = (0, react_1.useMemo)(() => {
        switch (position) {
            case PopUpPosition.LEFT:
            case PopUpPosition.TOP_LEFT:
            case PopUpPosition.BOTTOM_LEFT:
                return 0;
            case PopUpPosition.RIGHT:
            case PopUpPosition.TOP_RIGHT:
            case PopUpPosition.BOTTOM_RIGHT:
                return domRect.width;
            case PopUpPosition.TOP:
            case PopUpPosition.BOTTOM:
                return domRect.width / 2;
        }
    }, [domRect.width, position]);
    const top = (0, react_1.useMemo)(() => {
        switch (position) {
            case PopUpPosition.TOP_RIGHT:
            case PopUpPosition.TOP_LEFT:
            case PopUpPosition.TOP:
                return 0;
            case PopUpPosition.BOTTOM_LEFT:
            case PopUpPosition.BOTTOM:
            case PopUpPosition.BOTTOM_RIGHT:
                return domRect.height;
            case PopUpPosition.RIGHT:
            case PopUpPosition.LEFT:
                return domRect.height / 2;
        }
    }, [domRect.height, position]);
    const anchorX = domRect.x + left + offsetX;
    const anchorY = domRect.y + top + offsetY;
    const translateXMin = `${safeMarginX + boundaryRect.x - anchorX}px`;
    const translateXMax = `calc(${boundaryRect.width - anchorX - safeMarginX}px - 100%)`;
    const translateYMin = `${safeMarginY + boundaryRect.y - anchorY}px`;
    const translateYMax = `calc(${boundaryRect.height - anchorY - safeMarginY}px - 100%)`;
    const translateXFromRight = `-100%`;
    const translateYFromBottom = `-100%`;
    const transformStyle = (0, react_1.useMemo)(() => {
        switch (direction) {
            case PopUpDirection.TOP:
                return `
          translateX(min(max(-50%, ${translateXMin}), ${translateXMax}))
          translateY(min(max(${translateYMin}, ${translateYFromBottom}), ${translateYMax}))
        `;
            case PopUpDirection.RIGHT:
                return `
          translateX(min(0px, ${translateXMax}))
          translateY(min(max(-50%, ${translateYMin}), ${translateYMax}))
        `;
            case PopUpDirection.BOTTOM:
                return `
          translateX(min(max(-50%, ${translateXMin}), ${translateXMax}))
          translateY(min(0px, ${translateYMax}))
        `;
            case PopUpDirection.LEFT:
                return `
          translateX(max(${translateXFromRight}, ${translateXMin}))
          translateY(min(max(-50%, ${translateYMin}), ${translateYMax}))
        `;
            case PopUpDirection.TOP_LEFT:
                return `
          translateX(max(${translateXFromRight}, ${translateXMin}))
          translateY(max(${translateYFromBottom}, ${translateYMin}))
        `;
            case PopUpDirection.TOP_RIGHT:
                return `
          translateX(min(0px, ${translateXMax}))
          translateY(max(${translateYFromBottom}, ${translateYMin}))
        `;
            case PopUpDirection.BOTTOM_LEFT:
                return `
          translateX(max(${translateXFromRight}, ${translateXMin}))
          translateY(min(0px, ${translateYMax}))
        `;
            case PopUpDirection.BOTTOM_RIGHT:
                return `
          translateX(min(0px, ${translateXMax}))
          translateY(min(0px, ${translateYMax}))
        `;
        }
    }, [
        direction,
        translateXFromRight,
        translateXMax,
        translateXMin,
        translateYFromBottom,
        translateYMax,
        translateYMin,
    ]);
    return (0, react_1.useMemo)(() => (Object.assign({ left: left + offsetX + (inPortal ? domRect.x : 0), top: top + offsetY + (inPortal ? domRect.y : 0), transform: transformStyle }, (applyDOMRectWidth ? { width: domRect.width } : {}))), [
        applyDOMRectWidth,
        domRect.width,
        domRect.x,
        domRect.y,
        inPortal,
        left,
        offsetX,
        offsetY,
        top,
        transformStyle,
    ]);
};
exports.default = useDerivedStyle;
