"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useInterlocutor_1 = require("uni/hooks/workspace/useInterlocutor");
const useGeneralChatTitle = () => {
    const interlocutor = (0, useInterlocutor_1.useInterlocutorGivenName)({
        capitalized: false,
        your: true,
    });
    return `Chat with ${interlocutor}`;
};
exports.default = useGeneralChatTitle;
