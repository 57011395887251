"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdhocTodoAvailabilityStatus = void 0;
var AdhocTodoAvailabilityStatus;
(function (AdhocTodoAvailabilityStatus) {
    AdhocTodoAvailabilityStatus["ASSIGNED_TO_SELF"] = "ASSIGNED_TO_SELF";
    AdhocTodoAvailabilityStatus["AVAILABLE"] = "AVAILABLE";
    AdhocTodoAvailabilityStatus["NOT_AVAILABLE"] = "NOT_AVAILABLE";
    AdhocTodoAvailabilityStatus["COMPLETED_BY_SELF"] = "COMPLETED_BY_SELF";
})(AdhocTodoAvailabilityStatus = exports.AdhocTodoAvailabilityStatus || (exports.AdhocTodoAvailabilityStatus = {}));
