"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mapStatesToValues = ({ cardCompleteState, emailState, countryState, cardholderNameState, streetState, cityState, zipcodeState, stateState, aptState, }) => ({
    name: cardholderNameState,
    cardComplete: cardCompleteState,
    email: emailState,
    address: {
        line1: streetState,
        line2: aptState,
        city: cityState,
        state: stateState,
        postalCode: zipcodeState,
        country: countryState,
    },
});
exports.default = mapStatesToValues;
