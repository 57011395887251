"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const attachments_1 = require("uni/helpers/attachments");
const asFiles = (files) => files.map((file) => file.getAsFile()).filter(Boolean);
const useOnPasteFilesEffect = (container, onAttachFiles) => {
    (0, react_1.useEffect)(() => {
        const onPaste = (event) => {
            // Do not upload when the focus is outside the drop zone
            if (!container.current ||
                !container.current.contains(event.target) ||
                !('clipboardData' in event) ||
                !event.clipboardData) {
                return;
            }
            const items = asFiles(Array.from(event.clipboardData.items || [])).filter(attachments_1.isFile);
            if (items.length === 0) {
                return;
            }
            event.preventDefault();
            onAttachFiles(items);
        };
        window.addEventListener('paste', onPaste, false);
        return () => {
            window.removeEventListener('paste', onPaste, false);
        };
    }, [container.current, onAttachFiles]);
};
const OnPasteFilesHandler = ({ rootRef, onAttachFiles }) => {
    useOnPasteFilesEffect(rootRef, onAttachFiles);
    return null;
};
exports.default = OnPasteFilesHandler;
