"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLIENT_VIEW_DID_SHOW = exports.CLIENT_VIEW_WILL_SHOW = exports.MAIN_WINDOW_DID_INITIALIZE = exports.MAIN_WINDOW_DID_AUTHENTICATED = exports.MAIN_WINDOW_DID_READY = exports.WORKSPACE_DID_SHOW = exports.WORKSPACE_DID_MOUNT_END = exports.WORKSPACE_DID_MOUNT_BEGIN = exports.CLIENT_LIST_DID_LOAD = exports.CATCHUP_DID_MOUNT = exports.TOPICS_DETAIL_WILL_RENDER = exports.TOPICS_LIST_TOPIC_PRESSED = exports.TOPICS_DID_LOAD = exports.DATA_FETCHED_HOT = exports.DATA_FETCHED_COLD = exports.SPLASH_SCREEN_DID_HIDE = exports.APPLICATION_BECAME_ACTIVE = exports.APPLICATION_DID_SELECT_CLIENT = exports.APPLICATION_DID_MOUNT = exports.APPLICATION_DID_START = exports.APPLICATION_DID_RUN = exports.APPLICATION_WILL_RUN = exports.APPLICATION_DID_RENDER = exports.MAIN_APPLICATION_DID_RUN = exports.MAIN_WINDOW_DID_SHOW = exports.MAIN_DID_READY = exports.MAIN_DID_START = void 0;
exports.MAIN_DID_START = 'MAIN_DID_START';
exports.MAIN_DID_READY = 'MAIN_DID_READY';
exports.MAIN_WINDOW_DID_SHOW = 'MAIN_WINDOW_DID_SHOW';
exports.MAIN_APPLICATION_DID_RUN = 'MAIN_APPLICATION_DID_RUN';
exports.APPLICATION_DID_RENDER = 'APPLICATION_DID_RENDER';
exports.APPLICATION_WILL_RUN = 'APPLICATION_WILL_RUN';
exports.APPLICATION_DID_RUN = 'APPLICATION_DID_RUN';
exports.APPLICATION_DID_START = 'APPLICATION_DID_START';
exports.APPLICATION_DID_MOUNT = 'APPLICATION_DID_MOUNT';
exports.APPLICATION_DID_SELECT_CLIENT = 'APPLICATION_DID_SELECT_CLIENT';
exports.APPLICATION_BECAME_ACTIVE = 'APPLICATION_BECAME_ACTIVE';
exports.SPLASH_SCREEN_DID_HIDE = 'SPLASH_SCREEN_DID_HIDE';
exports.DATA_FETCHED_COLD = 'DATA_FETCHED_COLD';
exports.DATA_FETCHED_HOT = 'DATA_FETCHED_HOT';
exports.TOPICS_DID_LOAD = 'TOPICS_DID_LOAD';
exports.TOPICS_LIST_TOPIC_PRESSED = 'TOPICS_LIST_TOPIC_PRESSED';
exports.TOPICS_DETAIL_WILL_RENDER = 'TOPICS_DETAIL_WILL_RENDER';
exports.CATCHUP_DID_MOUNT = 'CATCHUP_DID_MOUNT';
exports.CLIENT_LIST_DID_LOAD = 'CLIENT_LIST_DID_LOAD';
exports.WORKSPACE_DID_MOUNT_BEGIN = 'WORKSPACE_DID_MOUNT_BEGIN';
exports.WORKSPACE_DID_MOUNT_END = 'WORKSPACE_DID_MOUNT_END';
exports.WORKSPACE_DID_SHOW = 'WORKSPACE_DID_SHOW';
exports.MAIN_WINDOW_DID_READY = 'MAIN_WINDOW_DID_READY';
exports.MAIN_WINDOW_DID_AUTHENTICATED = 'MAIN_WINDOW_DID_AUTHENTICATED';
exports.MAIN_WINDOW_DID_INITIALIZE = 'MAIN_WINDOW_DID_INITIALIZE';
exports.CLIENT_VIEW_WILL_SHOW = 'CLIENT_VIEW_WILL_SHOW';
exports.CLIENT_VIEW_DID_SHOW = 'CLIENT_VIEW_DID_SHOW';
