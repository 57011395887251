"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const diffNowToRelativeString = (date) => {
    const diff = date.diffNow([
        'minutes',
        'hours',
        'days',
        'weeks',
        'months',
        'years',
    ]);
    const inOrAgo = (value, text) => value < 0 ? `${text} ago` : `in ${text}`;
    const singularPrefix = '1';
    // Years
    if (Math.abs(diff.years) > 1) {
        return inOrAgo(diff.years, `${Math.abs(diff.years)} years`);
    }
    if (Math.abs(diff.years) === 1) {
        return inOrAgo(diff.years, `${singularPrefix} year`);
    }
    if (diff.years === -1) {
        return 'last year';
    }
    // Months
    if (Math.abs(diff.months) > 1) {
        return inOrAgo(diff.months, `${Math.abs(diff.months)} months`);
    }
    if (Math.abs(diff.months) === 1) {
        return inOrAgo(diff.months, `${singularPrefix} month`);
    }
    if (diff.months === -1) {
        return 'last month';
    }
    if (diff.months === 1) {
        return 'next month';
    }
    // Weeks
    if (Math.abs(diff.weeks) > 1) {
        return inOrAgo(diff.weeks, `${Math.abs(diff.weeks)} weeks`);
    }
    if (Math.abs(diff.weeks) === 1) {
        return inOrAgo(diff.weeks, `${singularPrefix} week`);
    }
    if (diff.weeks === -1) {
        return 'last week';
    }
    if (diff.weeks === 1) {
        return 'next week';
    }
    // Days
    if (Math.abs(diff.days) > 1) {
        return inOrAgo(diff.days, `${Math.round(Math.abs(diff.days))} days`);
    }
    if (Math.abs(diff.days) === 1) {
        return inOrAgo(diff.days, `${singularPrefix} day`);
    }
    if (diff.days === -1) {
        return 'yesterday';
    }
    if (diff.days === 1) {
        return 'tomorrow';
    }
    // Hours
    if (Math.abs(diff.hours) > 1) {
        return inOrAgo(diff.hours, `${Math.abs(diff.hours)} hours`);
    }
    if (Math.abs(diff.hours) === 1) {
        return inOrAgo(diff.hours, '1 hour');
    }
    // Minutes
    if (Math.abs(diff.minutes) > 5) {
        return inOrAgo(diff.minutes, `${Math.round(Math.abs(diff.minutes))} min`);
    }
    return 'now';
};
exports.default = diffNowToRelativeString;
