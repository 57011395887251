"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingSource = exports.ApplicationStage = void 0;
var ApplicationStage;
(function (ApplicationStage) {
    ApplicationStage["APPLIED"] = "APPLIED";
    ApplicationStage["IS_WAITING_FOR_MY_REPLY"] = "IS_WAITING_FOR_MY_REPLY";
    ApplicationStage["NO_MATCH"] = "NO_MATCH";
    ApplicationStage["ACCEPTED"] = "ACCEPTED";
    ApplicationStage["DECLINE"] = "DECLINE";
    ApplicationStage["MORE_INFO"] = "MORE_INFO";
    ApplicationStage["SCHEDULED"] = "SCHEDULED";
    ApplicationStage["ONBOARDED"] = "ONBOARDED";
})(ApplicationStage = exports.ApplicationStage || (exports.ApplicationStage = {}));
var TrackingSource;
(function (TrackingSource) {
    TrackingSource["MATCHING_CATALOG"] = "matching_catalog";
    TrackingSource["MATCHING_CATALOG_CONFIRM_MODAL"] = "matching_catalog_confirm_modal";
    TrackingSource["MATCHING_CATALOG_APPLICATION_MODAL"] = "matching_catalog_application_modal";
    TrackingSource["MATCHING_CATALOG_FILTERS_MODAL"] = "matching_catalog_filters_modal";
    TrackingSource["MATCHING_CATALOG_SORT_BY_MODAL"] = "matching_catalog_sort_by_modal";
})(TrackingSource = exports.TrackingSource || (exports.TrackingSource = {}));
