"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_router_dom_1 = require("react-router-dom");
const ROUTES_1 = require("../ROUTES");
const useAdhocTodoId = () => {
    var _a, _b;
    return (_b = (_a = (0, react_router_dom_1.useRouteMatch)({
        path: [ROUTES_1.CREATE, `/**/${ROUTES_1.ADHOC_ID}`],
        exact: true,
        strict: false,
    })) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id;
};
exports.default = useAdhocTodoId;
