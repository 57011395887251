"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Overflow = exports.AvatarOuter = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
exports.Outer = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const itemStyle = (0, styled_components_1.css) `
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors_1.white};

  margin-right: -6px;
  &:last-child {
    margin-right: 0;
  }
`;
exports.AvatarOuter = styled_components_1.default.div `
  ${itemStyle}
`;
const overflowVerticalPadding = 1;
exports.Overflow = styled_components_1.default.div `
  ${itemStyle}

  display: flex;
  align-items: center;

  background-color: ${colors_1.gray2};

  padding: ${overflowVerticalPadding}px 4px;
  ${({ $small }) => ($small ? text_1.labelRegular : text_1.captionRegular)}
  height: ${({ $height }) => $height + overflowVerticalPadding * 2}px;

  color: ${colors_1.gray6};
`;
