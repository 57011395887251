"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getSections = (items) => {
    const sectionList = [];
    for (let i = 0; i < items.length; i++) {
        const currentItem = items[i];
        const sectionIdx = sectionList.findIndex((section) => section.title === currentItem.section.title);
        if (sectionIdx !== -1) {
            sectionList[sectionIdx].items.push(currentItem);
        }
        else {
            sectionList.push({
                title: currentItem.section.title,
                key: currentItem.section.key,
                items: [currentItem],
            });
        }
    }
    return sectionList;
};
exports.default = getSections;
