"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseIcon = exports.SearchIcon = exports.ResetButton = exports.Input = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const devices_1 = require("uni/style/devices");
const reset_1 = require("uni/style/reset");
const text_1 = require("uni/style/text");
const close_xs_svg_1 = require("uni/system/icons/close-xs.svg");
const search_s_svg_1 = require("uni/system/icons/search-s.svg");
exports.Outer = styled_components_1.default.div `
  display: flex;
  flex: 1;
  position: relative;
`;
exports.Input = styled_components_1.default.input `
  ${reset_1.inputReset}
  ${text_1.bodyRegular}
  height: 32px;
  width: 192px;
  border: 1px solid ${colors_1.gray2};
  box-sizing: border-box;
  border-radius: 10px;

  color: ${colors_1.black};
  padding: 0 29px 0 35px;
  background: transparent
    url-svg-stroke('~uni/system/icons/search-s.svg', ${colors_1.gray6}) no-repeat 6px
    center;
  &::placeholder {
    color: ${colors_1.gray5};
  }
  &.__focus,
  &:focus {
    box-shadow: 0px 0px 0px 2px ${colors_1.purple2};
    border: 1px solid ${colors_1.purple4};
  }

  ${(0, devices_1.screenM)((0, styled_components_1.css) `
    flex: 1;

    &::placeholder {
      color: ${colors_1.gray6};
    }
  `)}
`;
exports.ResetButton = styled_components_1.default.button `
  ${reset_1.buttonReset}
  position: absolute;
  top: 4px;
  right: 5px;

  ${(0, devices_1.screenM)((0, styled_components_1.css) `
    right: 8px;
  `)}
`;
exports.SearchIcon = (0, styled_components_1.default)(search_s_svg_1.ReactComponent) `
  position: absolute;
  top: 4px;
  left: 8px;
  color: ${colors_1.gray6};
`;
exports.CloseIcon = (0, styled_components_1.default)(close_xs_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
`;
