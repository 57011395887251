"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DotsContainer = exports.Hr = exports.SummaryText = exports.RightAlignedRow = exports.TotalRow = exports.TokenBalanceRow = exports.SummaryRow = exports.SummaryInput = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
exports.SummaryInput = styled_components_1.default.div `
  display: flex;
  border-radius: 12px;
  background: ${colors_1.gray1};
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
const summaryRowStyles = (0, styled_components_1.css) `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  ${text_1.bodyRegular}
`;
exports.SummaryRow = styled_components_1.default.div `
  ${summaryRowStyles}
  justify-content: space-between;

  ${({ $isLight }) => $isLight && `color: ${colors_1.gray6};`}
`;
exports.TokenBalanceRow = styled_components_1.default.div `
  ${summaryRowStyles}
  gap: 8px;
  margin-top: 6px;
`;
exports.TotalRow = (0, styled_components_1.default)(exports.SummaryRow) `
  ${text_1.titleMedium}
`;
exports.RightAlignedRow = styled_components_1.default.div `
  ${summaryRowStyles}
  justify-content: flex-end;
`;
exports.SummaryText = styled_components_1.default.div `
  ${text_1.calloutRegular}
  color: ${colors_1.gray7};
  & > strong {
    ${text_1.calloutSemibold}
  }
`;
exports.Hr = styled_components_1.default.hr `
  width: 100%;
  border-top: 1px solid ${colors_1.gray3};
  margin: 12px 0;
`;
exports.DotsContainer = styled_components_1.default.div `
  width: 24px;
`;
