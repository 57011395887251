"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const shortcut_1 = require("uni/system/utils/shortcut");
const useShortcutEffect = (shortcut, onClick) => {
    (0, react_1.useEffect)(() => {
        if (!shortcut || !onClick)
            return;
        const handle = (event) => {
            const keys = shortcut || [];
            if ((0, shortcut_1.match)(keys, event)) {
                event.preventDefault();
                event.stopPropagation();
                onClick(event);
            }
        };
        document.addEventListener('keydown', handle, true);
        return () => {
            document.removeEventListener('keydown', handle, true);
        };
    }, [onClick, ...(shortcut || [])]);
};
exports.default = useShortcutEffect;
