"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const google_libphonenumber_1 = require("google-libphonenumber");
const react_1 = require("react");
const DEFAULT_REGION_CODE = 'US';
const usePhoneNumberUtil = () => {
    const phoneNumberUtil = (0, react_1.useMemo)(() => google_libphonenumber_1.PhoneNumberUtil.getInstance(), []);
    const formattedPhoneNumber = (0, react_1.useMemo)(() => (phoneNumber, regionCode) => {
        try {
            const number = phoneNumberUtil.parseAndKeepRawInput(phoneNumber.trim(), regionCode || DEFAULT_REGION_CODE);
            return phoneNumberUtil.format(number, google_libphonenumber_1.PhoneNumberFormat.E164);
        }
        catch (e) {
            return undefined;
        }
    }, [phoneNumberUtil]);
    const isValidPhoneNumberUI = (0, react_1.useMemo)(() => (phoneNumber, regionCode) => {
        if (phoneNumber.length === 0) {
            return true;
        }
        try {
            return phoneNumberUtil.isValidNumber(phoneNumberUtil.parse(phoneNumber, regionCode || DEFAULT_REGION_CODE));
        }
        catch (e) {
            return false;
        }
    }, [phoneNumberUtil]);
    return { formattedPhoneNumber, isValidPhoneNumberUI };
};
exports.default = usePhoneNumberUtil;
