"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const useOrientation = () => {
    var _a;
    const queryResult = (0, generated_1.useGetOrientationQuery)();
    const [update, mutationResult] = (0, generated_1.useUpdateOrientationMutation)();
    return Object.assign(Object.assign({}, queryResult), { loading: queryResult.loading || mutationResult.loading, error: queryResult.error || mutationResult.error, orientation: (_a = queryResult.data) === null || _a === void 0 ? void 0 : _a.me.orientation, update });
};
exports.default = useOrientation;
