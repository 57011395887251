"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeDateRangeFromDates = exports.getDefaultDateRange = void 0;
const luxon_1 = require("luxon");
// 5 weeks is the max number of weeks that can be loaded at once before the API
// starts throwing errors
const LOAD_WEEKS = 5;
const setTimeZone = (datetime, timeZoneName) => datetime.setZone(timeZoneName, {
    keepLocalTime: true,
});
const getDefaultDateRange = (range) => {
    // X weeks from most recent monday
    // do not set TZ to not depend on it
    let timeMin = luxon_1.DateTime.utc().startOf('week').startOf('day');
    let timeMax = timeMin.endOf('week').endOf('day').plus({ weeks: LOAD_WEEKS });
    // Move cursor by X weeks until anchor is in range
    while (timeMax.valueOf() < range.end.valueOf()) {
        timeMin = timeMin.plus({ weeks: LOAD_WEEKS });
        timeMax = timeMax.plus({ weeks: LOAD_WEEKS });
    }
    // Get a bit more around the edges to play nice with TZ
    return {
        start: timeMin.minus({ hours: 24 }),
        end: timeMax.plus({ hours: 24 }),
    };
};
exports.getDefaultDateRange = getDefaultDateRange;
const makeDateRangeFromDates = ([lower, upper], timeZoneName) => ({
    timeMin: setTimeZone(luxon_1.DateTime.fromJSDate(lower).startOf('day'), timeZoneName),
    timeMax: setTimeZone(luxon_1.DateTime.fromJSDate(upper).endOf('day'), timeZoneName),
});
exports.makeDateRangeFromDates = makeDateRangeFromDates;
