"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsMatchedWorkspace = exports.useWorkspaceAssistantModel = exports.useTeamWorkspaces = exports.useWorkspaceTeam = exports.useWorkspaceOptional = exports.workspaceContext = void 0;
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
exports.workspaceContext = (0, react_1.createContext)(null);
const useWorkspaceOptional = () => (0, react_1.useContext)(exports.workspaceContext);
exports.useWorkspaceOptional = useWorkspaceOptional;
const useWorkspace = () => {
    const workspace = (0, react_1.useContext)(exports.workspaceContext);
    if (!workspace) {
        throw new Error('useWorkspace: Missing active workspace');
    }
    return workspace;
};
const useWorkspaceTeam = () => {
    const workspace = useWorkspace();
    return (0, react_1.useMemo)(() => workspace.team, [workspace]);
};
exports.useWorkspaceTeam = useWorkspaceTeam;
const useTeamWorkspaces = ({ ownerWorkspaceId, } = {}) => {
    const workspace = useWorkspace();
    const team = (0, exports.useWorkspaceTeam)();
    return (0, react_1.useMemo)(() => {
        if (!team) {
            return null;
        }
        return [...team.workspaces].sort(({ id: idA }, { id: idB }) => {
            // topic owner first
            if (idA === ownerWorkspaceId)
                return -1;
            // then current workspace
            if (idA === workspace.id && idB !== ownerWorkspaceId)
                return -1;
            // everything else
            return 1;
        });
    }, [ownerWorkspaceId, team, workspace.id]);
};
exports.useTeamWorkspaces = useTeamWorkspaces;
const useWorkspaceAssistantModel = () => {
    const workspace = useWorkspace();
    return workspace.assistantModel;
};
exports.useWorkspaceAssistantModel = useWorkspaceAssistantModel;
const useIsMatchedWorkspace = () => {
    const workspaceAssistantModel = (0, exports.useWorkspaceAssistantModel)();
    return workspaceAssistantModel === generated_1.WorkspaceAssistantModel.MATCHED;
};
exports.useIsMatchedWorkspace = useIsMatchedWorkspace;
exports.default = useWorkspace;
