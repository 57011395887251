"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSlotIntersectionWithBusinessHours = exports.slotIsInBusinessHours = exports.dateIsInBusinessHours = exports.addBusinessHours = exports.addBusinessDays = void 0;
const luxon_1 = require("luxon");
const luxon_business_days_1 = require("luxon-business-days");
// 💩 workaround for the non-typed module so we don't lose DateTime typing
const addBusinessDays = (date, daysToAdd) => {
    const businessDateTime = luxon_business_days_1.DateTime.fromObject(date.toObject(), {
        zone: date.zone,
    });
    const newDate = businessDateTime.plusBusiness({ days: daysToAdd });
    return luxon_1.DateTime.fromObject(newDate.toObject(), { zone: date.zone });
};
exports.addBusinessDays = addBusinessDays;
const addBusinessHours = ({ date, businessHours }, hoursToAdd) => {
    // if no business hours provided, add hours and return
    if (!businessHours)
        return date.plus({ hours: hoursToAdd });
    // sync up timezone to the provided business hours
    let newDate = date.setZone(businessHours.timeZoneName);
    // if not in business hours, set to start of next business day
    if (!(0, exports.dateIsInBusinessHours)(newDate, businessHours)) {
        if (newDate.hour < businessHours.startHour) {
            newDate = newDate.set({
                hour: businessHours.startHour,
                minute: 0,
            });
        }
        else if (newDate.hour > businessHours.endHour) {
            newDate = (0, exports.addBusinessDays)(newDate, 1);
            newDate = newDate.set({
                hour: businessHours.startHour,
                minute: 0,
            });
        }
    }
    // add business hours (and days if necessary)
    const numberOfBusinessHoursInDay = businessHours.endHour - businessHours.startHour;
    const businessDaysToAdd = Math.floor(hoursToAdd / numberOfBusinessHoursInDay);
    const remainingHoursToAdd = hoursToAdd % numberOfBusinessHoursInDay;
    newDate = (0, exports.addBusinessDays)(newDate, businessDaysToAdd);
    newDate = newDate.plus({ hours: remainingHoursToAdd });
    // if in business hours, we're good to go
    if ((0, exports.dateIsInBusinessHours)(newDate, businessHours))
        return newDate;
    // otherwise, handle the overage
    const overageHours = newDate.hour - businessHours.endHour;
    newDate = (0, exports.addBusinessDays)(newDate, 1);
    newDate = newDate.set({
        hour: businessHours.startHour,
        minute: newDate.minute,
    });
    newDate = newDate.plus({ hours: overageHours });
    // reset to the original timezone
    return newDate.setZone(date.zone);
};
exports.addBusinessHours = addBusinessHours;
const getBusinessHoursInterval = (date, businessHours) => {
    const startingHour = date
        .setZone(businessHours.timeZoneName)
        .set({ hour: businessHours.startHour })
        .startOf('hour');
    const endingHour = startingHour.set({
        hour: businessHours.endHour,
    });
    return luxon_1.Interval.fromDateTimes(startingHour, endingHour);
};
const dateIsInBusinessHours = (date, businessHours) => {
    const businessHoursInterval = getBusinessHoursInterval(date, businessHours);
    return businessHoursInterval.contains(date);
};
exports.dateIsInBusinessHours = dateIsInBusinessHours;
const slotIsInBusinessHours = ({ start, end, }, businessHours) => {
    if (!end) {
        return (0, exports.dateIsInBusinessHours)(start, businessHours);
    }
    const businessHoursInterval = getBusinessHoursInterval(start, businessHours);
    return !!businessHoursInterval.engulfs(luxon_1.Interval.fromDateTimes(start, end));
};
exports.slotIsInBusinessHours = slotIsInBusinessHours;
const getSlotIntersectionWithBusinessHours = ({ start, end, }, businessHours) => {
    const businessHoursInterval = getBusinessHoursInterval(start, businessHours);
    return businessHoursInterval.intersection(luxon_1.Interval.fromDateTimes(start, end));
};
exports.getSlotIntersectionWithBusinessHours = getSlotIntersectionWithBusinessHours;
