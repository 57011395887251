"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const ClientsManager_1 = require("uni/components/apollo/ClientsManager");
const generated_1 = require("./generated");
const useDeleteTimeTrackingEntry = () => {
    const { getNonWorkspacedApolloClient } = (0, ClientsManager_1.useClientsManager)();
    const client = getNonWorkspacedApolloClient();
    const [mutate, result] = (0, generated_1.useDeleteTimeTrackingEntryMutation)({
        client,
    });
    const execute = (0, react_1.useCallback)((id) => {
        mutate({
            variables: {
                input: {
                    id,
                },
            },
            update(cache, { data }) {
                if (!(data === null || data === void 0 ? void 0 : data.deleteTimeTrackingEntry.isSuccess))
                    return;
                cache.evict({ id: `TimeTrackingEntry:${id}` });
                cache.gc();
            },
        });
    }, [mutate]);
    return [execute, result];
};
exports.default = useDeleteTimeTrackingEntry;
