"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const datadog_1 = require("uni/lib/logger/datadog");
const useConnectSlackWorkflow = () => {
    const client = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield client.query({
            query: generated_1.GetSlackConnectUrlDocument,
            variables: {
                redirectUrl: `${window.location.href.replace(window.location.search, '')}?fromSlackConnect=1`,
            },
        });
        if (result.data) {
            window.open(result.data.connectSlackUrl, '_blank');
        }
        else {
            (0, datadog_1.logErrorToDatadog)('slack.connect')(result.error);
        }
    }), [client]);
};
exports.default = useConnectSlackWorkflow;
