"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenIntervalPickerButton = exports.CalendarIcon = exports.ChevronRight = exports.ChevronLeft = exports.Button = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const reset_1 = require("uni/style/reset");
const text_1 = require("uni/style/text");
const calendar_due_date_s_svg_1 = require("uni/system/icons/calendar-due-date-s.svg");
const chevron_left_s_svg_1 = require("uni/system/icons/chevron-left-s.svg");
const chevron_right_s_svg_1 = require("uni/system/icons/chevron-right-s.svg");
exports.Outer = styled_components_1.default.div `
  min-width: 309px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid ${colors_1.gray2};
  border-radius: 8px;
`;
exports.Button = styled_components_1.default.button `
  ${reset_1.buttonReset};
  padding: 6px;

  cursor: default;
  ${({ disabled }) => !disabled
    ? (0, styled_components_1.css) `
          cursor: pointer;
          :hover {
            background-color: ${colors_1.gray1};
          }
        `
    : `
      opacity: 0.4;
    `}
`;
exports.ChevronLeft = (0, styled_components_1.default)(chevron_left_s_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
`;
exports.ChevronRight = (0, styled_components_1.default)(chevron_right_s_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
`;
exports.CalendarIcon = (0, styled_components_1.default)(calendar_due_date_s_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
`;
exports.OpenIntervalPickerButton = styled_components_1.default.button `
  ${reset_1.buttonReset};
  padding: 6px;

  span {
    padding: 0px 6px;
    ${text_1.bodyMedium};
  }

  border-right: 1px solid ${colors_1.gray2};
  border-left: 1px solid ${colors_1.gray2};
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  :hover {
    background-color: ${colors_1.gray1};
  }
`;
