"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWritableJpegFileStream = exports.createWritableCSVFileStream = exports.createWritableFileStream = void 0;
const native_file_system_adapter_ts_1 = require("native-file-system-adapter-ts");
const createWritableFileStream = (options) => __awaiter(void 0, void 0, void 0, function* () {
    // create a new handle
    const newHandle = yield (window.showSaveFilePicker || native_file_system_adapter_ts_1.showSaveFilePicker)({
        suggestedName: options.filename,
        types: [
            {
                description: options.type.description,
                accept: { [options.type.mime]: [options.type.extension] },
            },
        ],
    });
    // create a FileSystemWritableFileStream to write to
    return yield newHandle.createWritable();
});
exports.createWritableFileStream = createWritableFileStream;
const createWritableCSVFileStream = (filename) => (0, exports.createWritableFileStream)({
    filename,
    type: {
        mime: 'text/csv',
        description: 'CSV file',
        extension: '.csv',
    },
});
exports.createWritableCSVFileStream = createWritableCSVFileStream;
const createWritableJpegFileStream = (filename) => (0, exports.createWritableFileStream)({
    filename,
    type: {
        mime: 'image/jpeg',
        description: 'JPG image',
        extension: '.jpg',
    },
});
exports.createWritableJpegFileStream = createWritableJpegFileStream;
