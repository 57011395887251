"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computePreviousWeeklyRecurrenceDate = exports.computeNextWeeklyRecurrenceDate = exports.computePreviousMonthlyRecurrenceDate = exports.computeNextMonthlyRecurrenceDate = void 0;
const computeNextMonthlyRecurrenceDate = (now, day) => (day <= now.day
    ? now.plus({ month: 1 }).set({ day })
    : now.set({ day })).endOf('day');
exports.computeNextMonthlyRecurrenceDate = computeNextMonthlyRecurrenceDate;
const computePreviousMonthlyRecurrenceDate = (now, day) => (day > now.day
    ? now.minus({ month: 1 }).set({ day })
    : now.set({ day })).startOf('day');
exports.computePreviousMonthlyRecurrenceDate = computePreviousMonthlyRecurrenceDate;
const computeNextWeeklyRecurrenceDate = (now, days) => {
    // From tomorrow, to the same week day next week. If today is Tuesday, iterate from tomorrow Wednesday to Tuesday next week
    for (let cursor = now.weekday + 1; cursor < now.weekday + 8; cursor++) {
        const zeroDay = cursor % 7;
        // Modulo 7 is between 0 and 6, we want a 1-7 value. 7 = 0 = Sunday.
        const day = (zeroDay === 0 ? 7 : zeroDay);
        if (days.includes(day)) {
            if (day > now.weekday) {
                // Next recurrence is still this week
                return now.set({ weekday: day }).endOf('day');
            }
            else {
                // Next recurrence is still next week
                return now.plus({ week: 1 }).set({ weekday: day }).endOf('day');
            }
        }
    }
    return null;
};
exports.computeNextWeeklyRecurrenceDate = computeNextWeeklyRecurrenceDate;
const computePreviousWeeklyRecurrenceDate = (now, days) => {
    // From today, back to the day after the same weekday last week. If today is Wednedsay, iterate today to Tuesday last week.
    for (let cursor = now.weekday; cursor > now.weekday - 7; cursor--) {
        const zeroDay = (cursor + 7) % 7;
        // Modulo 7 is between 0 and 6, we want a 1-7 value. 7 = 0 = Sunday.
        const day = (zeroDay === 0 ? 7 : zeroDay);
        if (days.includes(day)) {
            if (day <= now.weekday) {
                // Previous recurrence was earlier this same week
                return now.set({ weekday: day }).startOf('day');
            }
            else {
                // Previous recurrence was earlier last
                return now.minus({ week: 1 }).set({ weekday: day }).startOf('day');
            }
        }
    }
    return null;
};
exports.computePreviousWeeklyRecurrenceDate = computePreviousWeeklyRecurrenceDate;
