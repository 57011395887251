"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const datadog_1 = require("uni/lib/logger/datadog");
const getWebPushSubscription = (apolloClient) => __awaiter(void 0, void 0, void 0, function* () {
    yield navigator.serviceWorker.register('/sw.js');
    const serviceWorker = yield navigator.serviceWorker.ready;
    const maybeSubscription = yield serviceWorker.pushManager.getSubscription();
    if (maybeSubscription)
        return maybeSubscription;
    const { data: vapidKeyData, error: vapidKeyError } = yield apolloClient.query({
        query: generated_1.GetVapidPublicKeyDocument,
    });
    if (vapidKeyError) {
        (0, datadog_1.logErrorToDatadog)('web-push.get-vapid-key')(vapidKeyError);
        throw vapidKeyError;
    }
    const vapidKey = vapidKeyData.vapidPublicKey;
    const subscription = yield serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidKey,
    });
    if (!subscription) {
        const error = new Error('Failed to create web push subscription');
        (0, datadog_1.logErrorToDatadog)('web-push.initialize')(error);
        throw error;
    }
    return subscription;
});
exports.default = getWebPushSubscription;
