"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useToggle = (initialValue = false) => {
    const [state, setState] = (0, react_1.useState)(initialValue);
    const toggleState = (0, react_1.useCallback)(() => {
        setState(!state);
    }, [state, setState]);
    const setStateToTrue = (0, react_1.useCallback)(() => {
        setState(true);
    }, [setState]);
    const setStateToFalse = (0, react_1.useCallback)(() => {
        setState(false);
    }, [setState]);
    return {
        state,
        toggleState,
        setStateToTrue,
        setStateToFalse,
    };
};
exports.default = useToggle;
