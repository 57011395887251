"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const STARTER_ID = '32a10cb5-8397-4574-b1ba-93ed28277094';
const PLUS_ID = 'eeec7eff-596b-431f-863f-dd499d8c820e';
const useInvitePricingNotice = () => {
    const { data, loading } = (0, generated_1.useGetPricingQuery)();
    let notice = null;
    if (data &&
        data.me.invoicedPricing &&
        data.me.invoicedPricing.id === STARTER_ID) {
        const plusPricing = data.pricingsList
            ? data.pricingsList.items.find((item) => item.id === PLUS_ID)
            : null;
        const plusLabel = plusPricing ? plusPricing.label : 'Plus';
        // Ideally we'd find the hour data from the Pricing too, but the number of hours is not accessible, only a long description
        notice = `Your monthly plan will be upgraded from ${data.me.invoicedPricing.label} (5h) to ${plusLabel} (15h) when your teammate starts.`;
    }
    return {
        notice,
        loading,
    };
};
exports.default = useInvitePricingNotice;
