"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const react_1 = require("react");
const useNbTokenToDuration = (minutesPerToken) => {
    return (0, react_1.useCallback)((tokenCount) => luxon_1.Duration.fromDurationLike({
        minutes: tokenCount * minutesPerToken,
    })
        // @ts-ignore Need to update @types/luxon to 3.xx but it's gonna be tedious
        .rescale()
        .toHuman({ unitDisplay: 'short' }), [minutesPerToken]);
};
exports.default = useNbTokenToDuration;
