"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const useWorkspace_1 = require("uni/hooks/workspace/useWorkspace");
const useIsSharedWithEverybody = (todo) => {
    const teamWorkspaces = (0, useWorkspace_1.useTeamWorkspaces)();
    return (0, react_1.useMemo)(() => !!teamWorkspaces &&
        todo.defaultCollaboratorPermission === generated_1.CollaboratorPermission.WRITE, [teamWorkspaces, todo.defaultCollaboratorPermission]);
};
exports.default = useIsSharedWithEverybody;
