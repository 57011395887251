"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useGetCurrentFieldDetails = ({ enabledFields, formValues, extendedProps, currentFieldIndex, }) => {
    const currentField = (0, react_1.useMemo)(() => enabledFields[currentFieldIndex], [currentFieldIndex, enabledFields]);
    const isLastField = (0, react_1.useMemo)(() => currentFieldIndex + 1 === enabledFields.length, [currentFieldIndex, enabledFields.length]);
    const extendedPropId = (0, react_1.useMemo)(() => { var _a; return (_a = extendedProps.find((prop) => prop.fieldId === currentField.id)) === null || _a === void 0 ? void 0 : _a.id; }, [currentField.id, extendedProps]);
    const currentFieldValues = (0, react_1.useMemo)(() => formValues[currentField.id] || [], [currentField.id, formValues]);
    return {
        currentField,
        currentFieldValues,
        extendedPropId,
        isLastField,
    };
};
exports.default = useGetCurrentFieldDetails;
