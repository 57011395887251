"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Error = exports.Placeholder = exports.outerStyle = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
exports.outerStyle = (0, styled_components_1.css) `
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
exports.Placeholder = styled_components_1.default.div `
  ${exports.outerStyle};
  gap: 7px;

  span {
    text-align: center;
    max-width: 200px;
    ${text_1.bodyMedium};
  }

  span,
  svg {
    color: ${colors_1.gray6};
  }
`;
exports.Error = styled_components_1.default.div `
  ${exports.outerStyle};
  margin: auto;
  color: ${colors_1.black};
  text-align: center;

  max-width: 200px;

  * {
    margin: 0;
  }

  h1 {
    ${text_1.headingBold};
  }

  h2 {
    ${text_1.titleMedium};
  }

  span {
    ${text_1.bodyMedium};
  }
`;
