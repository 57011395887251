"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentModalView = exports.AttachmentType = void 0;
var AttachmentType;
(function (AttachmentType) {
    AttachmentType["IMAGE"] = "IMAGE";
    AttachmentType["AUDIO"] = "AUDIO";
    AttachmentType["FILE"] = "FILE";
})(AttachmentType = exports.AttachmentType || (exports.AttachmentType = {}));
var AttachmentModalView;
(function (AttachmentModalView) {
    AttachmentModalView["SINGLE_FILE"] = "SINGLE_FILE";
    AttachmentModalView["SINGLE_IMAGE"] = "SINGLE_IMAGE";
    AttachmentModalView["MULTI_IMAGE"] = "MULTI_IMAGE";
    AttachmentModalView["LIST"] = "LIST";
})(AttachmentModalView = exports.AttachmentModalView || (exports.AttachmentModalView = {}));
