"use strict";
//
// PopoverDirection indicates what direction the popover will flow (for lack of a better term) from the anchor
//
// TOP          ⬆️   (the bottom, center of the popover will be touching the anchor)
// TOP_RIGHT    ↗️   (the bottom, left of the popover will be touching the anchor)
// RIGHT        ➡️   (the middle, left of the popover will be touching the anchor)
// BOTTOM_RIGHT ↘️   (the top, left of the popover will be touching the anchor)
// BOTTOM       ⬇️   (the top, center of the popover will be touching the anchor)
// BOTTOM_LEFT  ↙️   (the top, right of the popover will be touching the anchor)
// LEFT         ⬅️   (the middle, right of the popover will be touching the anchor)
// TOP_LEFT     ↖️   (the bottom, right of the popover will be touching the anchor)
//
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopoverDirection = void 0;
var PopoverDirection;
(function (PopoverDirection) {
    PopoverDirection["TOP"] = "top";
    PopoverDirection["TOP_RIGHT"] = "top-right";
    PopoverDirection["RIGHT"] = "right";
    PopoverDirection["BOTTOM_RIGHT"] = "bottom-right";
    PopoverDirection["BOTTOM"] = "bottom";
    PopoverDirection["BOTTOM_LEFT"] = "bottom-left";
    PopoverDirection["TOP_LEFT"] = "top-left";
    PopoverDirection["LEFT"] = "left";
})(PopoverDirection = exports.PopoverDirection || (exports.PopoverDirection = {}));
