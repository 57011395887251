"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonthLabel = exports.Day = exports.DayInner = exports.DayOuter = exports.DayStatus = exports.WeekDay = exports.WeekDaysRow = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
exports.Outer = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 1px;
`;
exports.WeekDaysRow = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  align-items: stretch;

  min-height: 32px;
`;
const getInIntervalOuterStyle = ({ isLast, isFirst, }) => (0, styled_components_1.css) `
    background-color: ${colors_1.purple6};
    border-top-right-radius: ${isLast ? '10px' : '0px'};
    border-bottom-right-radius: ${isLast ? '10px' : '0px'};
    border-bottom-left-radius: ${isFirst ? '10px' : '0px'};
    border-top-left-radius: ${isFirst ? '10px' : '0px'};
  `;
exports.WeekDay = styled_components_1.default.span `
  flex: 1 1 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${text_1.labelSemibold}
  color: ${colors_1.gray6};
`;
var DayStatus;
(function (DayStatus) {
    DayStatus[DayStatus["EXCLUSIVELY_SELECTABLE"] = 0] = "EXCLUSIVELY_SELECTABLE";
    DayStatus[DayStatus["NORMAL"] = 1] = "NORMAL";
    DayStatus[DayStatus["DISABLED"] = 2] = "DISABLED";
})(DayStatus = exports.DayStatus || (exports.DayStatus = {}));
exports.DayOuter = styled_components_1.default.div `
  flex: 1 1 0px;
  text-align: center;

  display: flex;
  align-items: stretch;
  justify-content: center;
  ${({ hideNonSelectableDays, status }) => hideNonSelectableDays &&
    status === DayStatus.DISABLED &&
    (0, styled_components_1.css) `
      visibility: hidden;
    `}

  ${({ status, isLast, isInInterval, isFirst, dark }) => {
    switch (status) {
        case DayStatus.EXCLUSIVELY_SELECTABLE:
            return (0, styled_components_1.css) `
          ${!dark && `background-color: ${colors_1.gray1};`}
          span {
            ${dark && `color: ${colors_1.gray1};`}
          }
          cursor: pointer;
          border-top-right-radius: ${isLast ? '10px' : '0px'};
          border-bottom-right-radius: ${isLast ? '10px' : '0px'};
          border-bottom-left-radius: ${isFirst ? '10px' : '0px'};
          border-top-left-radius: ${isFirst ? '10px' : '0px'};

          ${isInInterval && getInIntervalOuterStyle({ isLast, isFirst })}
        `;
        case DayStatus.NORMAL:
            return (0, styled_components_1.css) `
          span {
            color: ${colors_1.white};
          }
          cursor: pointer;
          border-radius: 10px;

          ${isInInterval && getInIntervalOuterStyle({ isLast, isFirst })}
        `;
        case DayStatus.DISABLED:
            return (0, styled_components_1.css) `
          span {
            color: ${colors_1.gray5} !important;
          }
        `;
    }
}}}
`;
exports.DayInner = styled_components_1.default.div `
  margin: 2px;
  border-radius: 8px;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ selectable, isToday, isSelected, dark }) => isSelected && selectable
    ? (0, styled_components_1.css) `
          background-color: ${colors_1.purple5};
          border-radius: 10px;
          span {
            color: ${colors_1.white};
          }
        `
    : isToday &&
        (0, styled_components_1.css) `
          border: 1px solid ${dark ? colors_1.gray7 : colors_1.gray4};
        `}

  ${({ selectable, isInInterval, isSelected, dark }) => {
    if (selectable && !isSelected) {
        const backgroundColor = dark
            ? isInInterval
                ? colors_1.purple5
                : colors_1.gray8
            : isInInterval
                ? colors_1.purple5
                : colors_1.gray2;
        return (0, styled_components_1.css) `
        &:hover {
          background-color: ${backgroundColor};
        }
      `;
    }
}}
`;
exports.Day = styled_components_1.default.span `
  ${({ isSelected }) => (isSelected ? text_1.calloutMedium : text_1.calloutRegular)}
`;
exports.MonthLabel = styled_components_1.default.span `
  ${text_1.labelMedium}
  margin-bottom: -6px;
`;
