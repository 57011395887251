"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const useIsPausedOrTransitioning = () => {
    var _a;
    const { data: workspacePreferences, loading, error, } = (0, generated_1.useGetWorkspacePreferencesQuery)();
    return {
        isPausedOrTransitioning: (_a = workspacePreferences === null || workspacePreferences === void 0 ? void 0 : workspacePreferences.workspaceById) === null || _a === void 0 ? void 0 : _a.isPausedOrTransitioning,
        loading,
        error,
    };
};
exports.default = useIsPausedOrTransitioning;
