"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.availabilityBodyText = void 0;
const types_1 = require("uni/lib/availability/types");
const availabilityBodyText = (option) => {
    if (!option) {
        return '';
    }
    switch (option) {
        case types_1.Availability.AVAILABLE:
            return `You’re ready to support an additional executive.`;
        case types_1.Availability.TEMP_AVAILABLE:
            return `You’re temporarily available to support another executive.`;
        case types_1.Availability.NOT_AVAILABLE:
            return `You’re busy and not available to support anyone.`;
    }
};
exports.availabilityBodyText = availabilityBodyText;
