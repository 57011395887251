"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const downloadFile = (url, filename) => {
    const disposableLink = document.createElement('a');
    disposableLink.setAttribute('href', url);
    disposableLink.setAttribute('download', filename);
    disposableLink.target = '_blank';
    document.body.appendChild(disposableLink);
    disposableLink.click();
    document.body.removeChild(disposableLink);
};
exports.default = downloadFile;
