"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const ClientsManager_1 = require("uni/components/apollo/ClientsManager");
const generated_1 = require("uni/graphql/hooks/generated");
const datadog_1 = require("uni/lib/logger/datadog");
const useTimeTrackingEntryUpsertedEffect = (apolloClient) => {
    (0, generated_1.useTimeTrackingEntryUpsertedSubscription)({
        client: apolloClient,
        onData: ({ client, data }) => {
            var _a;
            const timeEntriesListQueryData = client.cache.readQuery({
                query: generated_1.TimeTrackingEntriesListDocument,
            });
            const upsertedTimeTrackingEntry = (_a = data.data) === null || _a === void 0 ? void 0 : _a.timeTrackingEntryUpserted;
            if (!upsertedTimeTrackingEntry || !timeEntriesListQueryData)
                return;
            const isEntryAlreadyInCache = timeEntriesListQueryData.timeTrackingEntriesList.items.some((entry) => entry.id === upsertedTimeTrackingEntry.id);
            // The cached entry will automatically be updated by the subscription,
            // so we don't need to do anything
            if (isEntryAlreadyInCache)
                return;
            client.cache.writeQuery({
                query: generated_1.TimeTrackingEntriesListDocument,
                data: Object.assign(Object.assign({}, timeEntriesListQueryData), { timeTrackingEntriesList: Object.assign(Object.assign({}, timeEntriesListQueryData.timeTrackingEntriesList), { items: [
                            ...timeEntriesListQueryData.timeTrackingEntriesList.items.filter(({ id }) => !id.includes(upsertedTimeTrackingEntry.id)),
                            upsertedTimeTrackingEntry,
                        ] }) }),
            });
        },
    });
};
const useTimeTrackingEntriesList = (onErrorLabel = 'time_tracking.entry_list_query', params = {}, workspaceId) => {
    const { getApolloClient, getNonWorkspacedApolloClient } = (0, ClientsManager_1.useClientsManager)();
    const client = (0, react_1.useMemo)(() => workspaceId
        ? getApolloClient(workspaceId)
        : getNonWorkspacedApolloClient(), [getApolloClient, getNonWorkspacedApolloClient, workspaceId]);
    const entriesListResult = (0, generated_1.useTimeTrackingEntriesListQuery)(Object.assign({ client, onError: (0, datadog_1.logErrorToDatadog)(onErrorLabel), fetchPolicy: 'cache-and-network' }, params));
    useTimeTrackingEntryUpsertedEffect(client);
    (0, generated_1.useTimeTrackingEntryDeletedSubscription)({
        client,
    });
    return entriesListResult;
};
exports.default = useTimeTrackingEntriesList;
