"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const constants_1 = require("./constants");
const styled_1 = require("./styled");
const StarRating = ({ rating: ratingOnAdhocTodo, setRating }) => {
    const [hoveredRating, setHoveredRating] = (0, react_1.useState)();
    const onClick = (0, react_1.useCallback)((newRating) => () => {
        if (!setRating)
            return;
        setRating(newRating);
        setHoveredRating(undefined);
    }, [setRating]);
    const currentRating = ratingOnAdhocTodo || hoveredRating;
    return (react_1.default.createElement(styled_1.Outer, { "$disabled": !setRating }, constants_1.RATINGS.map((rating) => {
        return currentRating && rating <= currentRating ? (react_1.default.createElement(styled_1.LargeStarFilled, { key: rating, "$hovered": !!setRating, onMouseEnter: () => setHoveredRating(rating), onMouseLeave: () => setHoveredRating((prev) => (prev === rating ? undefined : prev)), onClick: onClick(rating) })) : (react_1.default.createElement(styled_1.LargeStar, { key: rating, onMouseEnter: () => setHoveredRating(rating), onMouseLeave: () => setHoveredRating((prev) => (prev === rating ? undefined : prev)), onClick: onClick(rating) }));
    })));
};
exports.default = StarRating;
