"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.screenXL = exports.screenLG = exports.screenM = exports.screenS = exports.SCREEN_XL_WIDTH = exports.SCREEN_LG_WIDTH = exports.SCREEN_M_WIDTH = exports.SCREEN_S_WIDTH = void 0;
const mixins_1 = require("./mixins");
exports.SCREEN_S_WIDTH = 576;
exports.SCREEN_M_WIDTH = 768;
exports.SCREEN_LG_WIDTH = 992;
exports.SCREEN_XL_WIDTH = 1200;
exports.screenS = (0, mixins_1.maxScreenWidth)(exports.SCREEN_S_WIDTH - 1);
exports.screenM = (0, mixins_1.maxScreenWidth)(exports.SCREEN_M_WIDTH - 1);
exports.screenLG = (0, mixins_1.maxScreenWidth)(exports.SCREEN_LG_WIDTH - 1);
exports.screenXL = (0, mixins_1.maxScreenWidth)(exports.SCREEN_XL_WIDTH - 1);
