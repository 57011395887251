"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const colors_1 = require("uni/style/colors");
const SIZE = 24;
const STROKE_WIDTH = 3;
function coordinatesFromPercentage(percentage, radius) {
    const ORIGIN_ANGLE = -(Math.PI / 2);
    const TOTAL_ANGLE = 2 * Math.PI;
    const angle = TOTAL_ANGLE * percentage + ORIGIN_ANGLE;
    return {
        x: Math.cos(angle) * radius,
        y: radius * (1 + Math.sin(angle)),
    };
}
const ProgressionCircle = ({ percentage, size = SIZE, strokeWidth = STROKE_WIDTH, baseColor = colors_1.gray2, progressColor = colors_1.gray6, }) => {
    const [radius, originX, originY] = (0, react_1.useMemo)(() => {
        const radius = size / 2 - strokeWidth / 2;
        const originX = size / 2;
        const originY = strokeWidth / 2;
        return [radius, originX, originY];
    }, [size, strokeWidth]);
    const { x, y } = (0, react_1.useMemo)(() => coordinatesFromPercentage(percentage, radius), [percentage, radius]);
    return (react_1.default.createElement("svg", { style: { height: size, width: size } },
        react_1.default.createElement("path", { fill: 'none', stroke: percentage < 1 ? baseColor : progressColor, d: `M ${originX} ${originY} a ${radius} ${radius} 0 1 0 0.0001 0`, opacity: '1', strokeWidth: `${strokeWidth}` }),
        percentage < 1 && (react_1.default.createElement("path", { fill: 'none', stroke: progressColor, d: `M ${originX} ${originY} a ${radius} ${radius} 0 ${x <= 0 ? 1 : 0} 1 ${x} ${y}`, opacity: '1', strokeWidth: `${strokeWidth}`, strokeLinecap: 'round' }))));
};
exports.default = ProgressionCircle;
