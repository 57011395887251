"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_SORT = exports.SortByOption = void 0;
var SortByOption;
(function (SortByOption) {
    // T-4720 sorting by recommended: waiting on API from backend
    // RECOMMENDED = 'Recommended',
    SortByOption["MOST_RECENT"] = "Most recent";
    SortByOption["HOURS_LOW_HIGH"] = "Hours (low to high)";
    SortByOption["HOURS_HIGH_LOW"] = "Hours (high to low)";
})(SortByOption = exports.SortByOption || (exports.SortByOption = {}));
exports.DEFAULT_SORT = SortByOption.MOST_RECENT;
