"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LargeStarFilled = exports.LargeStar = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const devices_1 = require("uni/style/devices");
const star_l_filled_32px_svg_1 = require("uni/system/icons/star-l-filled-32px.svg");
const star_filled_gradient_svg_1 = require("./images/star-filled-gradient.svg");
exports.Outer = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  ${(0, devices_1.screenM)((0, styled_components_1.css) `
    justify-content: center;
  `)}
`;
const sharedStarStyle = (0, styled_components_1.css) `
  transition: color 300ms ease;
`;
exports.LargeStar = (0, styled_components_1.default)(star_l_filled_32px_svg_1.ReactComponent) `
  ${sharedStarStyle}
  color: ${colors_1.gray3};
`;
exports.LargeStarFilled = (0, styled_components_1.default)(star_filled_gradient_svg_1.ReactComponent) `
  ${sharedStarStyle}

  ${({ $hovered }) => $hovered &&
    (0, styled_components_1.css) `
      opacity: 70%;
    `}
`;
