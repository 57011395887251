"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconOuter = exports.Description = exports.Title = exports.DetailsOuter = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const mixins_1 = require("uni/style/mixins");
const text_1 = require("uni/style/text");
exports.Outer = styled_components_1.default.div `
  padding: 9px 10px;
  border-radius: 20px;
  background: linear-gradient(180deg, #f9f9f9 0%, #fdfdff 100%);
  box-shadow: 0px 1px 0px 0px ${colors_1.gray3};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  ${({ $isDisabled, $isClickable }) => {
    if ($isDisabled)
        return (0, styled_components_1.css) `
        opacity: 0.4;
      `;
    if ($isClickable)
        return (0, styled_components_1.css) `
        cursor: pointer;
        &:hover {
          background: linear-gradient(
            180deg,
            ${(0, colors_1.hexaToRgba)(colors_1.gray7, 0.06)} 0%,
            ${(0, colors_1.hexaToRgba)(colors_1.gray7, 0.06)} 100%
          );
        }
      `;
}}
`;
exports.DetailsOuter = styled_components_1.default.div `
  display: flex;
  flex: 1;
  flex-direction: column;
`;
exports.Title = styled_components_1.default.span `
  ${text_1.bodySemibold}
`;
exports.Description = styled_components_1.default.span `
  ${text_1.calloutRegular}
  color: ${colors_1.gray7};
`;
exports.IconOuter = styled_components_1.default.div `
  position: relative; // For the tooltip

  height: 20px;
  width: 20px;
  flex: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(0, mixins_1.rounded)()}

  ${({ $checked, onClick }) => (0, styled_components_1.css) `
      ${$checked
    ? (0, styled_components_1.css) `
            background: ${colors_1.purple5};
          `
    : ''}
      ${!!onClick
    ? (0, styled_components_1.css) `
            &:hover {
              background: ${$checked ? colors_1.purple6 : colors_1.gray2};
            }
          `
    : ''}
    `}
`;
