"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTodoTemplate = void 0;
const client_1 = require("@apollo/client");
const possibleTypes_1 = require("uni/components/apollo/possibleTypes");
Object.defineProperty(exports, "isTodoTemplate", { enumerable: true, get: function () { return possibleTypes_1.isTodoTemplate; } });
const cache_1 = require("uni/graphql/helpers/cache");
const generated_1 = require("uni/graphql/hooks/generated");
const useTemplate = (templateId, options) => {
    const client = (0, client_1.useApolloClient)();
    const todoTemplate = (0, cache_1.findFragment)(client, {
        id: `TodoTemplate:${templateId}`,
        fragment: generated_1.TodoTemplateItemFragmentDoc,
        fragmentName: 'TodoTemplateItem',
    });
    const cachedTemplate = todoTemplate;
    const _a = (0, generated_1.useGetTemplatableByIdQuery)(Object.assign({ variables: {
            templateId,
        } }, options)), { data } = _a, rest = __rest(_a, ["data"]);
    const template = (data && data.template) || cachedTemplate || null;
    return Object.assign({ template }, rest);
};
exports.default = useTemplate;
