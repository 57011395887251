"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styled_1 = require("./styled");
const PricingListSection = ({ pricings, currentPricing, selectedPricing, onSelectPricing, }) => {
    const maxHours = (0, react_1.useMemo)(() => pricings.reduce((maxHours, pricing) => Math.max(pricing.includedHours || 0, maxHours), 0), [pricings]);
    const disabledPricing = selectedPricing || currentPricing;
    const buttonText = (0, react_1.useMemo)(() => {
        if (selectedPricing && selectedPricing.id !== (currentPricing === null || currentPricing === void 0 ? void 0 : currentPricing.id)) {
            return 'Pending plan change';
        }
        else if (currentPricing) {
            return 'Current plan';
        }
        return 'Select';
    }, [currentPricing, selectedPricing]);
    return (react_1.default.createElement(styled_1.Outer, null,
        react_1.default.createElement(styled_1.Plans, null, pricings.map((pricing, index) => {
            const timeRatio = pricing.includedHours
                ? Math.min(pricing.includedHours / maxHours, 0.95)
                : index / 3;
            return (react_1.default.createElement(styled_1.PlanContainer, { key: pricing.id, "$planIndex": index, "$disabled": (currentPricing === null || currentPricing === void 0 ? void 0 : currentPricing.id) === pricing.id },
                react_1.default.createElement(styled_1.PlanDetail, null,
                    react_1.default.createElement(styled_1.PlanHeader, null,
                        react_1.default.createElement(styled_1.PlanLabel, { "$planIndex": index }, pricing.label),
                        react_1.default.createElement(styled_1.PlanPriceDescription, null, pricing.priceDescription),
                        react_1.default.createElement(styled_1.PlanDescription, null,
                            react_1.default.createElement(styled_1.TimePie, { "$planIndex": index, "$ratio": timeRatio }),
                            react_1.default.createElement(styled_1.PlanDescriptionText, null,
                                react_1.default.createElement(styled_1.DescriptionTopLine, null,
                                    'Includes',
                                    "\u00A0",
                                    react_1.default.createElement(styled_1.Hours, null, `${pricing.includedHours} hours`),
                                    "\u00A0",
                                    'per month'),
                                react_1.default.createElement("div", null, `Every additional hour is $${pricing.hourlyPrice}`)))),
                    react_1.default.createElement(styled_1.SelectButton, { "$planIndex": index, onClick: () => onSelectPricing(pricing.id, index), "$disabled": (disabledPricing === null || disabledPricing === void 0 ? void 0 : disabledPricing.id) === pricing.id, disabled: (disabledPricing === null || disabledPricing === void 0 ? void 0 : disabledPricing.id) === pricing.id }, (disabledPricing === null || disabledPricing === void 0 ? void 0 : disabledPricing.id) === pricing.id
                        ? buttonText
                        : `Select ${pricing.label}`),
                    react_1.default.createElement(styled_1.LineSeparator, null),
                    pricing.sellingPointsNotice && (react_1.default.createElement(styled_1.SellingPointsNotice, null, `${pricing.sellingPointsNotice}:`)),
                    react_1.default.createElement(styled_1.PlanSellingPoints, null, pricing.sellingPoints.map((sellingPoint, i) => (react_1.default.createElement(styled_1.PlanSellingPoint, { key: `pricing-${pricing.id}-selling-point-${i}` },
                        react_1.default.createElement("div", null,
                            react_1.default.createElement(styled_1.CheckIcon, { "$planIndex": index })),
                        react_1.default.createElement("div", null, sellingPoint))))))));
        }))));
};
exports.default = PricingListSection;
