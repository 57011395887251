"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getItemId = exports.getDraggableId = void 0;
const getDraggableId = (parentId, itemId) => JSON.stringify({ droppableId: parentId, itemId });
exports.getDraggableId = getDraggableId;
const getItemId = (draggableId) => {
    try {
        return JSON.parse(draggableId)['itemId'];
    }
    catch (error) {
        console.error('DraggableId helper: getItemId failed: ', error);
    }
};
exports.getItemId = getItemId;
