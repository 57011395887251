"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
/**
 * A hook that returns a boolean state that is turned off after a delay.
 * Every time turnOn is called, the delay is reset.
 */
const useTimedOutSwitch = (delay = 1000) => {
    const [isOn, setIsOn] = (0, react_1.useState)(false);
    const [booleanStateToTriggerEffect, setBooleanStateToTriggerEffect] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (!isOn) {
            return;
        }
        const timeoutId = setTimeout(() => setIsOn(false), delay);
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOn, booleanStateToTriggerEffect]);
    const turnOn = (0, react_1.useCallback)(() => {
        if (isOn) {
            // triggers the reset of the timeout if toggle is set to true before it falls back to false (debounce)
            setBooleanStateToTriggerEffect((prev) => !prev);
        }
        else {
            setIsOn(true);
        }
    }, [isOn]);
    return [
        isOn,
        (0, react_1.useCallback)(() => setIsOn((prev) => !prev), [setIsOn]),
        turnOn,
        (0, react_1.useCallback)(() => setIsOn(false), [setIsOn]),
        setIsOn,
    ];
};
exports.default = useTimedOutSwitch;
