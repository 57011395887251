"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssistantProfileContainer = exports.Inner = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const devices_1 = require("uni/style/devices");
exports.Inner = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 39px;
  padding: 0px 20px 60px;
  overflow-y: auto;
`;
exports.AssistantProfileContainer = styled_components_1.default.div `
  display: flex;
  justify-content: center;
  width: 100%;

  ${(0, devices_1.screenLG)((0, styled_components_1.css) `
    margin-top: 24px;
  `)}
`;
