"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useCompletedTopicListQuery_1 = require("../useCompletedTopicListQuery");
const useEmailDigestTopicListQuery_1 = require("../useEmailDigestTopicListQuery");
const useMainTopicListQuery_1 = require("../useMainTopicListQuery");
const mayRemoveFromTopicListQueries = (cache, topic) => {
    if (!(0, useMainTopicListQuery_1.shouldBeInList)(topic)) {
        (0, useMainTopicListQuery_1.removeFromList)(cache, topic);
    }
    if (!(0, useEmailDigestTopicListQuery_1.shouldBeInList)(topic)) {
        (0, useEmailDigestTopicListQuery_1.removeFromList)(cache, topic);
    }
    if (!(0, useCompletedTopicListQuery_1.shouldBeInList)(topic)) {
        (0, useCompletedTopicListQuery_1.removeFromList)(cache, topic);
    }
};
exports.default = mayRemoveFromTopicListQueries;
