"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const numberFormat = new Intl.NumberFormat();
function formatDurationHours(durationHours) {
    if (!durationHours) {
        return '-';
    }
    const durationMinutes = durationHours * 60;
    const m = durationMinutes % 60;
    const h = (durationMinutes - m) / 60;
    const H = h === 0 ? '' : `${numberFormat.format(h)}hours`;
    const M = m === 0 ? '' : `${H ? ' ' : ''}${numberFormat.format(Math.round(m))}min`;
    return H + M;
}
exports.default = formatDurationHours;
