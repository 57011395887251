"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findQuery = exports.updateFragment = exports.findFragment = void 0;
const client_1 = require("@apollo/client");
const findFragment = (client, options, optimistic) => {
    let fragment = null;
    try {
        fragment = client.readFragment(options, optimistic);
    }
    catch (error) {
        // ApolloClient.readFragment will throw if all the data are not set
        if (error instanceof client_1.MissingFieldError) {
            return null;
        }
        if (error instanceof Error && error.name === 'Invariant Violation') {
            return null;
        }
        throw error;
    }
    return fragment;
};
exports.findFragment = findFragment;
const updateFragment = (client, options, patchFragment, optimistic) => {
    const fragment = (0, exports.findFragment)(client, options, optimistic);
    if (fragment) {
        const updatedFragment = patchFragment(fragment);
        client.writeFragment(Object.assign(Object.assign({}, options), { data: updatedFragment }));
        return updatedFragment;
    }
    return null;
};
exports.updateFragment = updateFragment;
const findQuery = (client, options, optimistic) => {
    let query = null;
    try {
        query = client.readQuery(options, optimistic);
    }
    catch (error) {
        // ApolloClient.readQuery will throw if all the data are not set
        if (error instanceof client_1.MissingFieldError) {
            return null;
        }
        if (error instanceof Error && error.name === 'Invariant Violation') {
            return null;
        }
        throw error;
    }
    return query;
};
exports.findQuery = findQuery;
