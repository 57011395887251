"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergePathAndSearch = exports.mergeSearchParamsStrings = void 0;
// TODO this does not de-duplicate `searchParams`: https://double.height.app/T-3545
const mergeSearchParamsStrings = (searchParams) => searchParams
    .filter((searchParam) => searchParam.length !== 0)
    // Remove superfluous question marks or add one if needed
    .map((searchParam, index) => {
    if (index === 0 && !searchParam.startsWith('?')) {
        return `?${searchParam}`;
    }
    else if (index > 0 && searchParam.startsWith('?')) {
        return searchParam.substring(1);
    }
    return searchParam;
})
    .join('&');
exports.mergeSearchParamsStrings = mergeSearchParamsStrings;
const mergePathAndSearch = (path, search) => {
    if (!path.includes('?')) {
        return `${path}${search}`;
    }
    return path.concat('&', search.substring(1));
};
exports.mergePathAndSearch = mergePathAndSearch;
