"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferencesFields = exports.PreferencesSections = exports.PreferencesFolders = void 0;
var PreferencesFolders;
(function (PreferencesFolders) {
    PreferencesFolders["Essential"] = "f42c667c-41c7-11ec-8ce2-95a5a69fad54";
})(PreferencesFolders = exports.PreferencesFolders || (exports.PreferencesFolders = {}));
var PreferencesSections;
(function (PreferencesSections) {
    PreferencesSections["Work"] = "45e1b4cc-41c8-11ec-8ce2-95a5a69fad54";
})(PreferencesSections = exports.PreferencesSections || (exports.PreferencesSections = {}));
var PreferencesFields;
(function (PreferencesFields) {
    PreferencesFields["Role"] = "751f942a-48ad-11ec-962a-a9ec24b4fbc6";
    PreferencesFields["Company"] = "59267b8a-41c8-11ec-8ce2-95a5a69fad54";
    PreferencesFields["Industry"] = "10cb319c-e2b1-4056-9c40-64f83ed3e370";
})(PreferencesFields = exports.PreferencesFields || (exports.PreferencesFields = {}));
