"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.match = exports.ShortcutKey = exports.isMacintosh = void 0;
exports.isMacintosh = (() => navigator.platform.indexOf('Mac') > -1)();
var ShortcutKey;
(function (ShortcutKey) {
    ShortcutKey["CMD_OR_CTRL"] = "CMD_OR_CTRL";
})(ShortcutKey = exports.ShortcutKey || (exports.ShortcutKey = {}));
const isCmdOrCtrl = (event) => exports.isMacintosh ? event.metaKey : event.ctrlKey;
const match = (keys, event) => keys
    .map((key) => {
    switch (key) {
        case ShortcutKey.CMD_OR_CTRL:
            return isCmdOrCtrl(event);
        default:
            return event.key === key.toLocaleLowerCase();
    }
})
    .reduce((acc, test, index) => {
    if (index === 0) {
        return test;
    }
    return acc && test;
}, false);
exports.match = match;
