"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const getDOMRect = (element) => {
    if (!element)
        return new DOMRect();
    const node = (0, react_dom_1.findDOMNode)(element);
    if (!(node instanceof Element)) {
        throw new TypeError('DOM node should be an Element');
    }
    return node.getBoundingClientRect();
};
const useDOMRect = (element, { disabled } = {}) => {
    const [rect, setRect] = (0, react_1.useState)(getDOMRect(element));
    (0, react_1.useEffect)(() => {
        const node = (0, react_dom_1.findDOMNode)(element);
        if (!(node instanceof Element))
            return;
        const onResize = () => {
            if (disabled)
                return;
            setRect(getDOMRect(element));
        };
        const itemResizeObserver = new ResizeObserver(onResize);
        itemResizeObserver.observe(node);
        itemResizeObserver.observe(document.body);
        return () => {
            itemResizeObserver.unobserve(node);
            itemResizeObserver.unobserve(document.body);
            itemResizeObserver.disconnect();
        };
    }, [disabled, element]);
    return rect;
};
exports.default = useDOMRect;
