"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const types_1 = require("./types");
const useAvailability = () => {
    const { data } = (0, generated_1.useGetAssistantProfileQuery)();
    if (data === null || data === void 0 ? void 0 : data.me) {
        if (data.me.isOpenToMatch) {
            return types_1.Availability.AVAILABLE;
        }
        if (data.me.isOpenToSupport) {
            return types_1.Availability.TEMP_AVAILABLE;
        }
        return types_1.Availability.NOT_AVAILABLE;
    }
    return null;
};
exports.default = useAvailability;
