"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const datadog_1 = require("uni/lib/logger/datadog");
const metrics_1 = require("uni/lib/metrics");
const types_1 = require("uni/lib/metrics/types");
const useCompleteStep = ({ stepId, }) => {
    const [complete, { loading, error: mutationError }] = (0, generated_1.useUpdateOnboardingStepMutation)({
        onError: (0, datadog_1.logErrorToDatadog)(`onboarding.complete_step[${stepId}]`),
    });
    const completeStep = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        yield complete({
            variables: {
                input: {
                    stepId,
                    data: data || {},
                    isCompleted: true,
                },
            },
        });
        metrics_1.metrics.track(types_1.TrackingEvent.ONBOARDING_STEP_UPDATED, { stepId });
        (0, datadog_1.logToDatadog)(`onboarding.complete_step: Just completed ${stepId}`);
    }), [complete]);
    return [completeStep, { loading, error: mutationError }];
};
exports.default = useCompleteStep;
