"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calendarsToFCEventSources = void 0;
const color_1 = require("uni/lib/utils/color");
function notNull(item) {
    return !!item;
}
const eventToFCEvent = ({ account, calendar, event, }) => {
    var _a, _b;
    if (!event.start || !event.end) {
        return null;
    }
    const fcEvent = {
        id: event.id,
        title: event.title || '',
        allDay: !!event.start.date,
        start: event.start.dateTime || event.start.date || undefined,
        end: event.end.dateTime || event.end.date || undefined,
        extendedProps: {
            account,
            calendar,
            event,
        },
    };
    if ((_a = event.colors) === null || _a === void 0 ? void 0 : _a.background) {
        fcEvent.backgroundColor = (0, color_1.modernizeGmailColor)(event.colors.background);
        fcEvent.borderColor = (0, color_1.modernizeGmailColor)(event.colors.background);
    }
    if ((_b = event.colors) === null || _b === void 0 ? void 0 : _b.foreground) {
        fcEvent.textColor = (0, color_1.modernizeGmailColor)(event.colors.foreground);
    }
    return fcEvent;
};
const calendarsToFCEventSources = (calendars, account) => {
    return calendars.map((calendar) => {
        var _a, _b, _c;
        return ({
            id: calendar.id,
            events: calendar.eventsList.items
                .map((event) => eventToFCEvent({ calendar, event, account }))
                .filter(notNull),
            backgroundColor: (0, color_1.modernizeGmailColor)((_a = calendar.colors) === null || _a === void 0 ? void 0 : _a.background),
            borderColor: (0, color_1.modernizeGmailColor)((_b = calendar.colors) === null || _b === void 0 ? void 0 : _b.background),
            textColor: (0, color_1.modernizeGmailColor)((_c = calendar.colors) === null || _c === void 0 ? void 0 : _c.foreground),
            editable: false,
        });
    });
};
exports.calendarsToFCEventSources = calendarsToFCEventSources;
const accountToFCEventSources = (account) => (0, exports.calendarsToFCEventSources)(account.calendars, account);
exports.default = accountToFCEventSources;
