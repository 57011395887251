"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentWeekNumber = exports.getEndOfWeek = exports.getStartOfWeek = void 0;
const luxon_1 = require("luxon");
// Eventually, all DateTime helpers should be moved in `uni/lib/dateTime` T-3583
/**
 * Returns the start of the week (Sunday) for the given date (defaults to now in the current timezone)
 */
const getStartOfWeek = (date = luxon_1.DateTime.local()) => date.plus({ days: 1 }).startOf('week').minus({ days: 1 });
exports.getStartOfWeek = getStartOfWeek;
/**
 * Returns the end of the week (Saturday) for the given date (defaults to now in the current timezone)
 */
const getEndOfWeek = (date = luxon_1.DateTime.local()) => date.plus({ days: 1 }).endOf('week').minus({ days: 1 });
exports.getEndOfWeek = getEndOfWeek;
const getCurrentWeekNumber = () => (0, exports.getEndOfWeek)().weekNumber;
exports.getCurrentWeekNumber = getCurrentWeekNumber;
