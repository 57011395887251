"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
/**
 * A hook that returns a state that is reset to its initial value after a delay.
 */
const useTimedOutState = (initialState, timeout) => {
    const [state, setState] = (0, react_1.useState)(initialState);
    (0, react_1.useEffect)(() => {
        const timeoutId = setTimeout(() => {
            setState(initialState);
        }, timeout);
        return () => clearTimeout(timeoutId);
    }, [initialState, timeout, state]);
    return [state, setState];
};
exports.default = useTimedOutState;
