"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Source = void 0;
var Source;
(function (Source) {
    Source["Discover"] = "discover";
    Source["TopicList"] = "topic-list";
    Source["TopicDetail"] = "topic-detail";
    Source["DelegationCoach"] = "delegation-coach";
    Source["TemplateIntro"] = "template-intro";
})(Source = exports.Source || (exports.Source = {}));
