"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventName = exports.TrackingSource = exports.TimerStatus = void 0;
var TimerStatus;
(function (TimerStatus) {
    TimerStatus[TimerStatus["RECORDING"] = 0] = "RECORDING";
    TimerStatus[TimerStatus["PAUSED"] = 1] = "PAUSED";
    TimerStatus[TimerStatus["STOPPED"] = 2] = "STOPPED";
})(TimerStatus = exports.TimerStatus || (exports.TimerStatus = {}));
// Tracking
var TrackingSource;
(function (TrackingSource) {
    TrackingSource["CONTROLS"] = "collapsed-controls";
    TrackingSource["COMPOSER"] = "modal-composer";
})(TrackingSource = exports.TrackingSource || (exports.TrackingSource = {}));
var EventName;
(function (EventName) {
    // Controls
    EventName["START"] = "time-tracking_start";
    EventName["STOP"] = "time-tracking_stop";
    EventName["PAUSE"] = "time-tracking_pause";
    EventName["RESUME"] = "time-tracking_resume";
    // Composer
    EventName["ADD_ENTRY_MANUALLY"] = "time-tracking_add-entry-manually";
    EventName["DISABLED_MAIN_BUTTON"] = "time-tracking_disabled-main-button";
    // Time range modal
    EventName["EDIT_START_TIME"] = "time-tracking_edit-start-time";
    EventName["EDIT_END_TIME"] = "time-tracking_edit-end-time";
    EventName["EDIT_HOUR_DURATION"] = "time-tracking_edit-hour-duration";
    EventName["EDIT_MINUTE_DURATION"] = "time-tracking_edit-minute-duration";
    EventName["EDIT_DATE"] = "time-tracking_edit-date";
    // Entries
    EventName["DELETE_ENTRY"] = "time-tracking_delete-entry";
    EventName["DUPLICATE_ENTRY"] = "time-tracking_duplicate-entry";
})(EventName = exports.EventName || (exports.EventName = {}));
