"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const topicToTimeTrackingTopic = (topic) => {
    const { __typename, id } = topic;
    return {
        __typename: 'TimeTrackingTopic',
        title: 'title' in topic ? topic.title : undefined,
        id,
        isProject: __typename === 'Project',
    };
};
exports.default = topicToTimeTrackingTopic;
