"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const resolvers_1 = require("../resolvers");
const useUpdateTodoDraft = () => {
    return (0, react_1.useCallback)((draft, patch) => {
        const drafts = (0, resolvers_1.todoDrafts)();
        const key = (0, resolvers_1.todoDraftKey)((draft.project && draft.project.id) || undefined);
        const existing = drafts[key];
        if (existing && existing.id === draft.id) {
            const updated = Object.assign(Object.assign({}, existing), patch);
            (0, resolvers_1.todoDrafts)(Object.assign(Object.assign({}, drafts), { [key]: updated }));
            return updated;
        }
    }, []);
};
exports.default = useUpdateTodoDraft;
