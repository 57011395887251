"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const ROUTES_1 = require("../ROUTES");
const useCurrentStepIndex = () => {
    const location = (0, react_router_dom_1.useLocation)();
    const stepIndex = (0, react_1.useMemo)(() => {
        if (location.pathname === `${ROUTES_1.SCHEDULE_INTRO_CALL_PATH}${ROUTES_1.GET_ONBOARD_PATH}`) {
            return 1;
        }
        if (location.pathname === `${ROUTES_1.SCHEDULE_INTRO_CALL_PATH}${ROUTES_1.SUCCESS}`) {
            return 3; // Returned so that the last step is checked.
        }
        return 0;
    }, [location.pathname]);
    return stepIndex;
};
exports.default = useCurrentStepIndex;
