"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoiceIcon = exports.VoiceButton = exports.DigestModeButton = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const microphone_s_svg_1 = require("uni/system/icons/microphone-s.svg");
const buttonStyle = (0, styled_components_1.css) `
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  cursor: pointer;
  border: none;
  margin-top: -3px;
  margin-left: -3px;
`;
const roundedButtonStyle = (0, styled_components_1.css) `
  ${buttonStyle}
  width: 28px;
  border-radius: 28px;
`;
const ActivableButton = styled_components_1.default.button `
  background-color: ${({ active }) => (active ? colors_1.purple5 : colors_1.gray4)};
`;
exports.DigestModeButton = (0, styled_components_1.default)(ActivableButton) `
  ${text_1.calloutRegular}
  border-radius: 8px;
  padding: 0px 10px 0px 6px;

  span {
    margin-left: 4px;
  }

  ${buttonStyle}
  color: #fff;
`;
exports.VoiceButton = styled_components_1.default.button `
  ${roundedButtonStyle}
  background-color: #fff;
`;
exports.VoiceIcon = (0, styled_components_1.default)(microphone_s_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
`;
