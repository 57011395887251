"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const formatDateTime = (dt) => {
    if (!dt) {
        return '';
    }
    const now = luxon_1.DateTime.local();
    const days = dt.diff(now, 'days').days;
    if (dt.hasSame(now, 'day')) {
        return ('Today ' +
            dt.toLocaleString({
                hour: 'numeric',
                minute: 'numeric',
            }));
    }
    if (dt.hasSame(now.plus({ day: 1 }), 'day')) {
        return ('Tomorrow ' +
            dt.toLocaleString({
                hour: 'numeric',
                minute: 'numeric',
            }));
    }
    if (days > 0 && days <= 7) {
        return dt.toLocaleString({
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
        });
    }
    return dt.toLocaleString({
        day: 'numeric',
        month: 'short',
        year: dt.hasSame(now, 'year') ? undefined : 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
};
exports.default = formatDateTime;
