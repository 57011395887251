"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usageFormat = exports.totalUsage = void 0;
const luxon_1 = require("luxon");
const total = (workspaceUsages) => {
    return workspaceUsages.reduce((totalHours, usage) => totalHours + (usage.monthlyUsages[0].hours || 0), 0);
};
const totalUsage = (selfAndTeamUsage) => {
    const isWorkspaceUsage = (usage) => Boolean(usage);
    const team = selfAndTeamUsage.teamUsages
        .map(({ workspaceUsage }) => workspaceUsage)
        .filter(isWorkspaceUsage);
    return total([selfAndTeamUsage.selfUsage, ...team]);
};
exports.totalUsage = totalUsage;
const usageFormat = (hours) => {
    return luxon_1.Duration.fromObject({ hours: hours }).toFormat("h'h 'mm'min'");
};
exports.usageFormat = usageFormat;
