"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const PreferencesData_1 = require("../PreferencesData");
const useRoleDescription = (folders) => (0, react_1.useMemo)(() => {
    var _a, _b, _c, _d, _e, _f, _g;
    const workSection = (_a = folders === null || folders === void 0 ? void 0 : folders.find((folder) => folder.id === PreferencesData_1.PreferencesFolders.Essential)) === null || _a === void 0 ? void 0 : _a.sections.find((section) => section.id === PreferencesData_1.PreferencesSections.Work);
    const roles = (_b = workSection === null || workSection === void 0 ? void 0 : workSection.fields.find((field) => field.id === PreferencesData_1.PreferencesFields.Role)) === null || _b === void 0 ? void 0 : _b.values;
    const companies = (_c = workSection === null || workSection === void 0 ? void 0 : workSection.fields.find((field) => field.id === PreferencesData_1.PreferencesFields.Company)) === null || _c === void 0 ? void 0 : _c.values;
    const industries = (_d = workSection === null || workSection === void 0 ? void 0 : workSection.fields.find((field) => field.id === PreferencesData_1.PreferencesFields.Industry)) === null || _d === void 0 ? void 0 : _d.values;
    // ***** TODO: assuming only 1 value in the values list right now
    const role = (_e = roles === null || roles === void 0 ? void 0 : roles[0]) === null || _e === void 0 ? void 0 : _e.text;
    const company = (_f = companies === null || companies === void 0 ? void 0 : companies[0]) === null || _f === void 0 ? void 0 : _f.text;
    const industry = (_g = industries === null || industries === void 0 ? void 0 : industries[0]) === null || _g === void 0 ? void 0 : _g.text;
    return !!role
        ? `${role}${!!company ? ` at ${company}` : industry ? ` in ${industry}` : ''}`
        : null;
}, [folders]);
exports.default = useRoleDescription;
