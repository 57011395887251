"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ClientsManager_1 = require("uni/components/apollo/ClientsManager");
const generated_1 = require("uni/graphql/hooks/generated");
const useMeId = () => {
    const { getNonWorkspacedApolloClient } = (0, ClientsManager_1.useClientsManager)();
    const { data, loading, error } = (0, generated_1.useMeIdQuery)({
        // Use non-workspaced client for the request to be only done once and not at each workspace change
        client: getNonWorkspacedApolloClient(),
    });
    return { meId: (data === null || data === void 0 ? void 0 : data.me.id) || null, loading, error };
};
exports.default = useMeId;
