"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fieldIsEnabled = void 0;
const generated_1 = require("uni/graphql/hooks/generated");
const fieldIsEnabled = (field, valuesMap) => field.enabledIf ? fieldMatchCondition(field.enabledIf, valuesMap) : true;
exports.fieldIsEnabled = fieldIsEnabled;
// Inspired by TopicForm helpers
const fieldMatchCondition = (condition, valuesMap) => {
    if (condition.__typename === 'TopicFormFieldValueCondition') {
        const values = valuesMap[condition.fieldId] || [];
        const conditionValue = condition.value.__typename === 'TopicExtendedPropValueText'
            ? condition.value.text
            : null;
        return values.some((v) => v === conditionValue);
    }
    else if (condition.__typename === 'TopicFormOperatorCondition') {
        return condition.conditions[condition.operator === generated_1.TopicFormOperator.AND ? 'every' : 'some']((c) => fieldMatchCondition(c, valuesMap));
    }
    else {
        // unsupported condition type, discard
        return false;
    }
};
exports.default = exports.fieldIsEnabled;
