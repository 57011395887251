"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackFromIdleActivity = void 0;
const react_1 = require("react");
var BackFromIdleActivity;
(function (BackFromIdleActivity) {
    BackFromIdleActivity["NETWORK"] = "network";
    BackFromIdleActivity["TAB_QUICK"] = "tab-quick";
    BackFromIdleActivity["TAB_LONG"] = "tab-long";
})(BackFromIdleActivity = exports.BackFromIdleActivity || (exports.BackFromIdleActivity = {}));
const TAB_QUICK_THRESHOLD = 15000; // 15 secs
const useBackFromIdleEffect = (cb, deps) => {
    const lastTimeVisible = (0, react_1.useRef)(null);
    const isOffline = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        const onOnline = () => {
            if (isOffline.current) {
                cb(BackFromIdleActivity.NETWORK);
                isOffline.current = false;
            }
        };
        const onOffline = () => {
            isOffline.current = true;
        };
        const onDocumentVisibilityChange = () => {
            const now = +new Date();
            if (document.visibilityState === 'visible') {
                const elapsedTime = lastTimeVisible.current === null
                    ? null
                    : now - lastTimeVisible.current;
                const activity = elapsedTime !== null
                    ? elapsedTime > TAB_QUICK_THRESHOLD
                        ? BackFromIdleActivity.TAB_LONG
                        : BackFromIdleActivity.TAB_QUICK
                    : BackFromIdleActivity.TAB_LONG;
                cb(activity);
                lastTimeVisible.current = null;
            }
            else {
                lastTimeVisible.current = now;
            }
        };
        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);
        document.addEventListener('visibilitychange', onDocumentVisibilityChange);
        return () => {
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
            document.removeEventListener('visibilitychange', onDocumentVisibilityChange);
        };
    }, [...deps, cb]);
};
exports.default = useBackFromIdleEffect;
