"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
const globalCSS = (0, styled_components_1.css) `
  * {
    font-family: SF Pro Text, system, -apple-system, BlinkMacSystemFont,
      '.SFNSDisplay-Regular', 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica,
      Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    outline: none;
    box-sizing: border-box;
  }

  svg {
    display: block;
  }
`;
exports.default = globalCSS;
