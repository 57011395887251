"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styled_1 = require("./styled");
// Add a small margin of error, for Edge
const ADJUST_LINES_THRESHOLD = 2;
const isRefObject = (ref) => {
    return ref !== null && typeof ref !== 'function';
};
const TextInput = (0, react_1.forwardRef)((_a, ref) => {
    var { onChange, accessory, minRows = 1, maxRows = 1 } = _a, props = __rest(_a, ["onChange", "accessory", "minRows", "maxRows"]);
    const localRef = (0, react_1.useRef)(null);
    const _ref = isRefObject(ref) ? ref : localRef;
    // TODO Update and use useAdjustTextareaLines with this code
    const adjustLines = (0, react_1.useCallback)(() => {
        const textAreaEl = _ref.current;
        if (!textAreaEl)
            return;
        textAreaEl.rows = minRows;
        let rows = minRows;
        while (textAreaEl.scrollHeight >
            textAreaEl.clientHeight + ADJUST_LINES_THRESHOLD &&
            rows < maxRows) {
            rows += 1;
            textAreaEl.rows = rows;
        }
    }, [_ref, maxRows, minRows]);
    (0, react_1.useEffect)(() => {
        adjustLines();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.value,
        // Adjust lines on style changes
        props.className,
        props.style,
    ]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(styled_1.StyledTextArea, Object.assign({}, props, { ref: _ref, onChange: ({ target: { value } }) => onChange(value) })),
        accessory));
});
exports.default = TextInput;
