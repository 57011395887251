"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable camelcase */
const wording = {
    en_us: {
        recurrenceType: {
            none: "Don't Repeat",
            weekly: 'Weekly',
            monthly: 'Monthly',
        },
    },
};
exports.default = wording.en_us;
