"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("./generated");
const assertNever = (templateTypename) => {
    throw new Error(`Unsupported template typename: "${templateTypename}"`);
};
function useTemplatesList(type, labels = []) {
    const _a = (0, generated_1.useGetTemplatesListQuery)({
        variables: {
            types: [type],
            labels,
        },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const templates = !data
        ? []
        : data.list.items.filter(({ __typename }) => {
            if (__typename === 'FlowTemplate') {
                return type === generated_1.TemplateType.FLOW;
            }
            else if (__typename === 'GoalTemplate') {
                return type === generated_1.TemplateType.GOAL;
            }
            else if (__typename === 'TodoTemplate') {
                return type === generated_1.TemplateType.TODO;
            }
            else {
                assertNever(__typename);
            }
        });
    return Object.assign(Object.assign({}, rest), { data,
        templates });
}
exports.default = useTemplatesList;
