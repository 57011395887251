"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Split a string into an array of strings, where each string is either a match
 * or a non-match of the query.
 */
const explode = (query, input) => {
    query = query.toLowerCase();
    const lowerCaseInput = input.toLowerCase();
    const parts = [];
    let cursor = 0;
    while (cursor < lowerCaseInput.length) {
        const prevCursor = cursor;
        cursor = lowerCaseInput.indexOf(query, cursor);
        // If the query is not found, add the rest of the input and break.
        if (cursor === -1) {
            parts.push({ value: input.substring(prevCursor), match: false });
            break;
        }
        const match = cursor === prevCursor;
        if (match) {
            cursor = prevCursor + query.length;
        }
        parts.push({ value: input.substring(prevCursor, cursor), match });
    }
    return parts;
};
exports.default = explode;
