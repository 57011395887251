"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.browserCountryKey = exports.formErrors = exports.stripeElementStyle = void 0;
const countries_1 = require("uni/lib/countries");
exports.stripeElementStyle = {
    base: {
        fontFamily: '"Inter", "Helvetica Neue", "Helvetica",  "Arial", sans-serif',
        fontWeight: 'normal',
        fontSize: '15px',
    },
};
const MANDATORY_VALUES = [
    'name',
    'email',
    'country',
    'state',
    'city',
    'postal_code',
    'street',
];
const formErrors = (values = {}, isCardComplete) => {
    const errors = [];
    errors.push(...MANDATORY_VALUES.filter((field) => {
        if (field === 'state') {
            return !(values.state ||
                ((values.country && countries_1.statesByCountry[values.country]) || []).length ===
                    0);
        }
        else {
            return !values[field];
        }
    }));
    if (!isCardComplete) {
        errors.push('card');
    }
    return errors;
};
exports.formErrors = formErrors;
// 💩 window.navigator.userLanguage not supported, window.navigator['userLanguage'] not allowed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const browserCountryKey = () => (window.navigator.userLanguage || window.navigator.language || '')
    .toUpperCase()
    .split('-')
    .pop();
exports.browserCountryKey = browserCountryKey;
