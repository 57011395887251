"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubtopicsDroppableId = exports.getProjectDroppableId = exports.getProjectId = void 0;
const types_1 = require("../../types");
const SEPARATOR_CHAR = '|';
const getProjectId = (areaId) => { var _a; return (_a = areaId.match(RegExp(`^${types_1.projectSection}([^${SEPARATOR_CHAR}]*)`))) === null || _a === void 0 ? void 0 : _a[1]; };
exports.getProjectId = getProjectId;
const getProjectDroppableId = (projectId) => types_1.projectSection + projectId;
exports.getProjectDroppableId = getProjectDroppableId;
const getSubtopicsDroppableId = (droppableId) => `${droppableId}${SEPARATOR_CHAR}SUBTOPICS`;
exports.getSubtopicsDroppableId = getSubtopicsDroppableId;
