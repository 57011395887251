"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const mapFunctionsToDescriptions = (functions) => functions.reduce((acc, copilotFunction) => (Object.assign(Object.assign({}, acc), { [copilotFunction.name]: copilotFunction })), {});
const useGetCopilotPrompts = () => {
    const { data, loading, error } = (0, generated_1.useGetCopilotPromptQuery)();
    return (0, react_1.useMemo)(() => {
        var _a, _b, _c, _d, _e, _f;
        const copilotFunctions = (_a = data === null || data === void 0 ? void 0 : data.copilot) === null || _a === void 0 ? void 0 : _a.functions.filter(Boolean);
        return {
            data: !data
                ? undefined
                : {
                    functionsEnabled: (_d = (_c = (_b = data.workspace.rawPreferences.copilot) === null || _b === void 0 ? void 0 : _b.functionsEnabled) !== null && _c !== void 0 ? _c : data.copilot.functionsEnabled) !== null && _d !== void 0 ? _d : false,
                    messagesPrompt: ((_e = data.workspace.rawPreferences.copilot) === null || _e === void 0 ? void 0 : _e.messagesPrompt) ||
                        data.workspace.rawPreferences.prompt ||
                        data.copilot.messagesPrompt ||
                        '',
                    defaultMessagesPrompt: data.copilot.messagesPrompt || '',
                    functionsPrompt: ((_f = data.workspace.rawPreferences.copilot) === null || _f === void 0 ? void 0 : _f.functionsPrompt) || '',
                    defaultFunctionsPrompt: data.copilot.functionsPrompt || '',
                    functions: mapFunctionsToDescriptions((0, lodash_1.uniqBy)([
                        ...(data.workspace.rawPreferences.copilot.functions || []),
                        ...(copilotFunctions || []),
                    ], 'name')),
                    defaultFunctions: mapFunctionsToDescriptions(copilotFunctions || []),
                },
            loading,
            error,
        };
    }, [data, error, loading]);
};
exports.default = useGetCopilotPrompts;
