"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooltipPosition = void 0;
//
//            (TOP_LEFT_INNER)     (TOP)    (TOP_RIGHT_INNER)
// (TOP_LEFT)                       5px                       (TOP_RIGHT)
//            -----------------------------------------------
//           |(OVER_TOP_LEFT)   (OVER_TOP)   (OVER_TOP_RIGHT)|
//           |                                               |
//     (LEFT)|(OVER_LEFT)                        (OVER_RIGHT)|(RIGHT)
//           |                                               |
//           |(OVER_BTM_LEFT)   (OVER_BTM)   (OVER_BTM_RIGHT)|
//            -----------------------------------------------
// (BTM_LEFT)                      5px                        (BTM_RIGHT)
//           (BTM_LEFT_INNER)     (BTM)    (BTM_RIGHT_INNER)
//
var TooltipPosition;
(function (TooltipPosition) {
    TooltipPosition["POSITION_RIGHT"] = "positionRight";
    TooltipPosition["POSITION_LEFT"] = "positionLeft";
    TooltipPosition["POSITION_BOTTOM"] = "positionBottom";
    TooltipPosition["POSITION_BOTTOM_RIGHT"] = "positionBottomRight";
    TooltipPosition["POSITION_BOTTOM_RIGHT_INNER"] = "positionBottomRightInner";
    TooltipPosition["POSITION_BOTTOM_LEFT"] = "positionBottomLeft";
    TooltipPosition["POSITION_BOTTOM_LEFT_INNER"] = "positionBottomLeftInner";
    TooltipPosition["POSITION_TOP"] = "positionTop";
    TooltipPosition["POSITION_TOP_RIGHT"] = "positionTopRight";
    TooltipPosition["POSITION_TOP_RIGHT_INNER"] = "positionTopRightInner";
    TooltipPosition["POSITION_TOP_LEFT"] = "positionTopLeft";
    TooltipPosition["POSITION_TOP_LEFT_INNER"] = "positionTopLeftInner";
    TooltipPosition["POSITION_OVER_RIGHT"] = "positionOverRight";
    TooltipPosition["POSITION_OVER_LEFT"] = "positionOverLeft";
    TooltipPosition["POSITION_OVER_TOP"] = "positionOverTop";
    TooltipPosition["POSITION_OVER_TOP_RIGHT"] = "positionOverTopRight";
    TooltipPosition["POSITION_OVER_TOP_LEFT"] = "positionOverTopLeft";
    TooltipPosition["POSITION_OVER_BOTTOM"] = "positionOverBottom";
    TooltipPosition["POSITION_OVER_BOTTOM_RIGHT"] = "positionOverBottomRight";
    TooltipPosition["POSITION_OVER_BOTTOM_LEFT"] = "positionOverBottomLeft";
})(TooltipPosition = exports.TooltipPosition || (exports.TooltipPosition = {}));
