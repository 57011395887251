"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SAMPLE_TASKS = void 0;
exports.SAMPLE_TASKS = [
    'Create a weekend adventure itinerary',
    'Find after-school activities for kids',
    'Get quotes from local service providers',
    'Find a venue to host an event',
    'Find the best candidates for a contract job',
    'Design flyers for an event',
    'Write a blog post or article with images',
];
