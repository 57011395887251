"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskCreationReason = exports.TrackingEvent = void 0;
var TrackingEvent;
(function (TrackingEvent) {
    TrackingEvent["APP_OPENED"] = "app_opened";
    TrackingEvent["APP_PERFORMANCE"] = "app-performance";
    TrackingEvent["APP_TIMER"] = "app-timer";
    TrackingEvent["APP_PROFILE"] = "app-profile";
    TrackingEvent["ASSISTANT_COPILOT_TRIGGERED"] = "assistant-copilot_triggered";
    TrackingEvent["ASSISTANT_COPILOT_OPTION_SELECTED"] = "assistant-copilot_option-selected";
    TrackingEvent["ASSISTANT_COPILOT_COPY_CLICKED"] = "assistant-copilot_copy-clicked";
    TrackingEvent["ATTACH_FILES"] = "attach_files";
    TrackingEvent["AUTHENTICATION_STARTED"] = "authentication_started";
    TrackingEvent["BUTTON_PRESSED"] = "button_pressed";
    TrackingEvent["CALENDAR_NAVIGATED"] = "calendar_navigated";
    TrackingEvent["COPILOT_TRIGGERED"] = "copilot_triggered";
    TrackingEvent["DISMISSED_TOPIC_STATUS_PICKER"] = "dismissed_topic_status_picker";
    TrackingEvent["EVENT_SLOTS_DISPLAYED"] = "event_slots_displayed";
    TrackingEvent["EXECUTIVE_BOOKMARKED"] = "executive_bookmarked";
    TrackingEvent["EXTENSION_POPUP_SHOWN"] = "extension-popup_shown";
    TrackingEvent["FEATURE_FLAG_READ"] = "feature_flag_read";
    TrackingEvent["USER_FEATURE_FLAG_READ"] = "user_feature_flag_read";
    TrackingEvent["FIELD_TYPE_CHANGED"] = "field_type_changed";
    TrackingEvent["IMAGE_LOADING_ERROR"] = "image_loading-error";
    TrackingEvent["INSIGHTS_ACKED"] = "insights_acked";
    TrackingEvent["INSIGHTS_OPEN_CALENDAR"] = "insights_openCalendar";
    TrackingEvent["NOTIFICATIONS_ENABLED"] = "notifications_enabled";
    TrackingEvent["ONBOARDING_CHECKLIST_DISPLAYED"] = "onboarding_checklist_displayed";
    TrackingEvent["ONBOARDING_STEP_UPDATED"] = "onboarding_step_updated";
    TrackingEvent["OPENED_TOPIC_STATUS_PICKER"] = "opened_topic_status_picker";
    TrackingEvent["PAGE_PRINT"] = "page_print";
    TrackingEvent["PAGE_SESSION"] = "page_session";
    TrackingEvent["PROFILE_PROGRESS_UPDATED"] = "profile_progress_updated";
    TrackingEvent["PROJECT_NAMED"] = "project_named";
    TrackingEvent["SCHEDULE_CHANGED_CALENDAR_TIMEZONE"] = "schedule_changed-calendar-timezone";
    TrackingEvent["SEARCH_RESULT"] = "search_result";
    TrackingEvent["SELECTION_CHANGED"] = "selection_changed";
    TrackingEvent["SESSION_ENDED"] = "session_ended";
    TrackingEvent["SESSION_STARTED"] = "session_started";
    TrackingEvent["TAB_CLICKED"] = "tab_clicked";
    TrackingEvent["TASK_CREATED"] = "task_created";
    TrackingEvent["TASK_DELETED"] = "task_deleted";
    TrackingEvent["TODO_LIST_SORTING_UPDATE"] = "todolist-sorting_update";
    TrackingEvent["TODO_LIST_TOGGLE_DISPLAY_EMAIL_DIGEST"] = "todolist-toggle_display_email_digest";
    TrackingEvent["TOPIC_ACTIVITY_ACTIONED"] = "topic-activity_actioned";
    TrackingEvent["TOPIC_ASSISTANT_COPILOT_SENT_TEXT"] = "topic-assistant-copilot_sent_text";
    TrackingEvent["TOPIC_CANCEL_VOICE"] = "topic_cancel-voice";
    TrackingEvent["TOPIC_CLICKED_THREAD"] = "topic_clicked-thread";
    TrackingEvent["TOPIC_LOADED_IMAGE"] = "topic_loaded-image";
    TrackingEvent["TOPIC_MODAL_OPENED"] = "topic_modal_opened";
    TrackingEvent["TOPIC_NAMED"] = "topic_named";
    TrackingEvent["TOPIC_SCHEDULED"] = "topic_scheduled";
    TrackingEvent["TOPIC_SENDING_FAILED_TEXT"] = "topic_sending_failed-text";
    TrackingEvent["TOPIC_SENT_TEXT"] = "topic_sent-text";
    TrackingEvent["TOPIC_SENT_VOICE"] = "topic-sent_voice";
    TrackingEvent["TOPIC_START_VOICE"] = "topic_start-voice";
    TrackingEvent["TOPIC_UPDATED_DUE_DATE"] = "topic_updated-due-date";
    TrackingEvent["TOPIC_UPDATED_PROJECT"] = "topic_updated-project";
    TrackingEvent["UNIVERSAL_LINK_OPENED"] = "universal_link_opened";
    TrackingEvent["UPDATED_TOPIC_STATUS"] = "updated_topic_status";
    // TIME TRACKING
    TrackingEvent["TIME_TRACKING_FETCH_MORE_ENTRIES"] = "time-tracking_fetch-more-entries";
    TrackingEvent["TIME_TRACKING_COMPOSER_WARNING_MESSAGE"] = "time-tracking_composer-warning_message";
    TrackingEvent["TIME_TRACKING_UPDATE_TAG"] = "time-tracking_update-tag";
    TrackingEvent["TIME_TRACKING_UPDATE_TASK"] = "time-tracking_update-task";
    TrackingEvent["TIME_TRACKING_SHOW_MANUAL_ENTRY"] = "time-tracking_show-manual-entry";
    TrackingEvent["TIME_TRACKING_HIDE_MANUAL_ENTRY"] = "time-tracking_hide-manual-entry";
    TrackingEvent["TIME_TRACKING_NAVIGATE_TO_WORKSPACE"] = "time-tracking_navigate-to-workspace";
    TrackingEvent["TIME_TRACKING_MANUAL_ENTRY_TIME_RANGE_CHANGED"] = "time-tracking_manual-entry-time-range-changed";
    TrackingEvent["TIME_TRACKING_TIME_RANGE_CREATE"] = "time-tracking_time-range-create";
    TrackingEvent["TIME_TRACKING_TIME_RANGE_EDIT"] = "time-tracking_time-range-edit";
    // SETTINGS
    TrackingEvent["SETTINGS_UPDATED"] = "settings_updated";
    // ADHOC TODO POOL
    TrackingEvent["ADHOC_TODO_BRIEF_SECTION_FOCUSED"] = "adhoc-todo_brief-section-focused";
    TrackingEvent["ADHOC_TODO_FOLLOW_UP_QUESTIONS_ANSWERED"] = "adhoc-todo_follow-up-questions-answered";
})(TrackingEvent = exports.TrackingEvent || (exports.TrackingEvent = {}));
var TaskCreationReason;
(function (TaskCreationReason) {
    TaskCreationReason["SET_TITLE"] = "title_set";
    TaskCreationReason["TEXT_MESSAGE_SENT"] = "text_message_sent";
    TaskCreationReason["NOTE_ADDED"] = "note_added";
    TaskCreationReason["SUMMARY_ADDED"] = "summary_added";
    TaskCreationReason["MOVED_TO_PROJECT"] = "moved_to_project";
    TaskCreationReason["STATUS_UPDATED"] = "status_updated";
    TaskCreationReason["DUE_DATE_SET"] = "due_date_set";
    TaskCreationReason["TEMPLATE_USED"] = "template_used";
    TaskCreationReason["EMAIL_THREAD_ATTACHED"] = "email_thread_attached";
    TaskCreationReason["TIME_TRACKING"] = "time_tracking";
    TaskCreationReason["FROM_CHAT_MESSAGE"] = "from_chat_message";
    TaskCreationReason["VOICE_RECORDED"] = "voice_recorded";
    TaskCreationReason["FROM_URL"] = "from_url";
    TaskCreationReason["FROM_DELEGATION_COACH"] = "from_delegation_coach";
})(TaskCreationReason = exports.TaskCreationReason || (exports.TaskCreationReason = {}));
