"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTimeTrackingTagFragment = exports.toTimeTrackingLocalTag = void 0;
const toTimeTrackingLocalTag = (tag) => ({
    __typename: 'TimeTrackingLocalTag',
    id: tag.id,
    category: Object.assign(Object.assign({}, tag.category), { __typename: 'TimeTrackingLocalCategory' }),
    label: tag.label,
});
exports.toTimeTrackingLocalTag = toTimeTrackingLocalTag;
const toTimeTrackingTagFragment = (tag) => (Object.assign(Object.assign({}, tag), { category: Object.assign(Object.assign({}, tag.category), { __typename: 'TimeTrackingTagCategory' }), __typename: 'TimeTrackingTag' }));
exports.toTimeTrackingTagFragment = toTimeTrackingTagFragment;
