"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function notNull(item) {
    return !!item;
}
const legacyHoldToFCEvent = (legacyHoldGroup, legacyHold) => {
    if (!legacyHold.start || !legacyHold.end) {
        return null;
    }
    const fcEvent = {
        id: legacyHold.id,
        title: legacyHoldGroup.label || '',
        allDay: false,
        start: legacyHold.start.toJSDate(),
        end: legacyHold.end.toJSDate(),
        extendedProps: {
            legacyHoldGroup,
            legacyHold,
        },
        classNames: ['hold'],
    };
    return fcEvent;
};
const legacyHoldGroupToFCEventSource = (legacyHoldGroup) => ({
    id: legacyHoldGroup.id,
    editable: true,
    events: legacyHoldGroup.holds
        .map((legacyHold) => legacyHoldToFCEvent(legacyHoldGroup, legacyHold))
        .filter(notNull),
    borderColor: '#9082f7',
    backgroundColor: 'white',
    textColor: '#644bff',
});
exports.default = legacyHoldGroupToFCEventSource;
