"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const onboardingBusinessHours_1 = require("../onboardingBusinessHours");
const getBusinessHours = (matching) => {
    var _a;
    return ((_a = matching === null || matching === void 0 ? void 0 : matching.profiles[0]) === null || _a === void 0 ? void 0 : _a.country)
        ? (0, onboardingBusinessHours_1.onboardingBusinessHours)(matching.profiles[0].country)
        : onboardingBusinessHours_1.NY_BUSINESS_HOURS;
};
exports.default = getBusinessHours;
