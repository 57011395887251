"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DelegationCoachCategoryType = void 0;
var DelegationCoachCategoryType;
(function (DelegationCoachCategoryType) {
    DelegationCoachCategoryType["TEAM"] = "TEAM";
    DelegationCoachCategoryType["FINANCE"] = "FINANCE";
    DelegationCoachCategoryType["COMMUNICATIONS"] = "COMMUNICATIONS";
    DelegationCoachCategoryType["DOCUMENT"] = "DOCUMENT";
    DelegationCoachCategoryType["SURPRISE"] = "SURPRISE";
    DelegationCoachCategoryType["STARRED"] = "STARRED";
})(DelegationCoachCategoryType = exports.DelegationCoachCategoryType || (exports.DelegationCoachCategoryType = {}));
