"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useIsDocumentFocused = () => {
    const [isDocumentFocused, setIsDocumentFocused] = (0, react_1.useState)(document.hasFocus());
    (0, react_1.useEffect)(() => {
        const onFocus = () => setIsDocumentFocused(true);
        const onBlur = () => setIsDocumentFocused(false);
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, [setIsDocumentFocused]);
    return isDocumentFocused;
};
exports.default = useIsDocumentFocused;
