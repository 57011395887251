"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onboardingBusinessHoursDisplayString = exports.onboardingBusinessHours = exports.NY_BUSINESS_HOURS = void 0;
const luxon_1 = require("luxon");
// values from Airtable mapped to time zones
const COUNTRY_TO_TIME_ZONE = {
    'US + Canada / EST': 'America/New_York',
    'US + Canada / PST': 'America/Los_Angeles',
    'US + Canada / CT': 'America/Chicago',
    'US + Canada / MT': 'America/Denver',
    'PST (US + Canada)': 'America/Los_Angeles',
    'CT (US + Canada)': 'America/Chicago',
    'EST (US + Canada)': 'America/New_York',
    'MT (US + Canada)': 'America/Chicago',
    'CET (France)': 'Europe/Paris',
    'New York City, NY': 'America/New_York',
    // default to ET for US & Canada
    US: 'America/New_York',
    Canada: 'America/New_York',
    France: 'Europe/Paris',
    Belgium: 'Europe/Brussels',
    Germany: 'Europe/Paris',
    Italy: 'Europe/Paris',
    'United Kingdom': 'Europe/London',
    Netherlands: 'Europe/Paris',
};
exports.NY_BUSINESS_HOURS = {
    startHour: 9,
    endHour: 18,
    timeZoneName: 'America/New_York',
    nbWeekRange: 2,
};
const onboardingBusinessHours = (country) => {
    const timeZone = COUNTRY_TO_TIME_ZONE[country];
    return {
        startHour: 9,
        endHour: 18,
        timeZoneName: timeZone || 'America/New_York',
        nbWeekRange: 2,
    };
};
exports.onboardingBusinessHours = onboardingBusinessHours;
const onboardingBusinessHoursDisplayString = (businessHours) => {
    const { startHour, endHour, timeZoneName } = businessHours;
    const localZone = luxon_1.DateTime.local().zoneName;
    const start = luxon_1.DateTime.fromObject({ hour: startHour }, { zone: timeZoneName }).setZone(localZone);
    const end = luxon_1.DateTime.fromObject({ hour: endHour }, { zone: timeZoneName }).setZone(localZone);
    return `${start.toFormat('ha')} - ${end.toFormat('ha ZZZZ')}`;
};
exports.onboardingBusinessHoursDisplayString = onboardingBusinessHoursDisplayString;
