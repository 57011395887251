"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.labelBold = exports.labelSemibold = exports.labelMedium = exports.labelRegular = exports.captionBold = exports.captionSemibold = exports.captionMedium = exports.captionRegular = exports.calloutBold = exports.calloutSemibold = exports.calloutMedium = exports.calloutRegular = exports.bodyBold = exports.bodySemibold = exports.bodyMedium = exports.bodyRegular = exports.titleBold = exports.titleSemibold = exports.titleMedium = exports.titleRegular = exports.headlineBold = exports.headlineRegular = exports.headingBold = exports.textHero = exports.heavyText = exports.boldText = exports.semiboldText = exports.mediumText = exports.baseText = void 0;
const styled_components_1 = require("styled-components");
const colors_1 = require("./colors");
const devices_1 = require("./devices");
// HELPERS
exports.baseText = (0, styled_components_1.css) `
  color: ${colors_1.black};
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
`;
exports.mediumText = (0, styled_components_1.css) `
  font-weight: 500;
`;
exports.semiboldText = (0, styled_components_1.css) `
  font-weight: 600;
`;
exports.boldText = (0, styled_components_1.css) `
  font-weight: 700;
`;
exports.heavyText = (0, styled_components_1.css) `
  font-weight: 800;
`;
// HERO
exports.textHero = (0, styled_components_1.css) `
  ${exports.baseText}
  ${exports.boldText}
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -1px;
`;
// HEADING
exports.headingBold = (0, styled_components_1.css) `
  ${exports.baseText}
  ${exports.boldText}
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0px;
`;
// HEADLINE
exports.headlineRegular = (0, styled_components_1.css) `
  ${exports.baseText}
  font-size: 24px;
  line-height: 135%;
`;
exports.headlineBold = (0, styled_components_1.css) `
  ${exports.headlineRegular}
  ${exports.boldText}
`;
// TITLE
exports.titleRegular = (0, styled_components_1.css) `
  ${exports.baseText}
  font-size: 18px;
  line-height: 140%;
`;
exports.titleMedium = (0, styled_components_1.css) `
  ${exports.titleRegular}
  ${exports.mediumText}
`;
exports.titleSemibold = (0, styled_components_1.css) `
  ${exports.titleRegular}
  ${exports.semiboldText}
`;
exports.titleBold = (0, styled_components_1.css) `
  ${exports.titleRegular}
  ${exports.boldText}
`;
// BODY
exports.bodyRegular = (0, styled_components_1.css) `
  ${exports.baseText}
  font-size: 15px;
  line-height: 140%;

  ${(0, devices_1.screenS)((0, styled_components_1.css) `
    font-size: 16px;
  `)}
`;
exports.bodyMedium = (0, styled_components_1.css) `
  ${exports.bodyRegular}
  ${exports.mediumText}
`;
exports.bodySemibold = (0, styled_components_1.css) `
  ${exports.bodyRegular}
  ${exports.semiboldText}
`;
exports.bodyBold = (0, styled_components_1.css) `
  ${exports.bodyRegular}
  ${exports.boldText}
`;
// CALLOUT
exports.calloutRegular = (0, styled_components_1.css) `
  ${exports.baseText}
  font-size: 14px;
  line-height: 140%;
`;
exports.calloutMedium = (0, styled_components_1.css) `
  ${exports.calloutRegular}
  ${exports.mediumText}
`;
exports.calloutSemibold = (0, styled_components_1.css) `
  ${exports.calloutRegular}
  ${exports.semiboldText}
`;
exports.calloutBold = (0, styled_components_1.css) `
  ${exports.calloutRegular}
  ${exports.boldText}
`;
// CAPTION
exports.captionRegular = (0, styled_components_1.css) `
  ${exports.baseText}
  font-size: 13px;
  line-height: 140%;
`;
exports.captionMedium = (0, styled_components_1.css) `
  ${exports.captionRegular}
  ${exports.mediumText}
`;
exports.captionSemibold = (0, styled_components_1.css) `
  ${exports.captionRegular}
  ${exports.semiboldText}
`;
exports.captionBold = (0, styled_components_1.css) `
  ${exports.captionRegular}
  ${exports.boldText}
`;
// LABEL
exports.labelRegular = (0, styled_components_1.css) `
  ${exports.baseText}
  font-size: 11px;
  line-height: 140%;
`;
exports.labelMedium = (0, styled_components_1.css) `
  ${exports.labelRegular}
  ${exports.mediumText}
`;
exports.labelSemibold = (0, styled_components_1.css) `
  ${exports.labelRegular}
  ${exports.semiboldText}
`;
exports.labelBold = (0, styled_components_1.css) `
  ${exports.labelRegular}
  ${exports.boldText}
`;
