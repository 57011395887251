"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const react_1 = require("react");
var MonthAction;
(function (MonthAction) {
    MonthAction[MonthAction["PREV"] = 0] = "PREV";
    MonthAction[MonthAction["NEXT"] = 1] = "NEXT";
})(MonthAction || (MonthAction = {}));
const useMonthWalker = (startMonth) => {
    const [displayMonth, updateNow] = (0, react_1.useReducer)((displayMonth, action) => {
        switch (action) {
            case MonthAction.PREV:
                return displayMonth.minus({ months: 1 });
            case MonthAction.NEXT:
                return displayMonth.plus({ months: 1 });
        }
    }, (startMonth || luxon_1.DateTime.local()).startOf('month'));
    const back = (0, react_1.useCallback)(() => updateNow(MonthAction.PREV), []);
    const forward = (0, react_1.useCallback)(() => updateNow(MonthAction.NEXT), []);
    return [displayMonth, back, forward];
};
exports.default = useMonthWalker;
