"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable  camelcase */
const react_stripe_js_1 = require("@stripe/react-stripe-js");
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const mapBillingAddressToStripeAddress = (_a) => {
    var { postalCode } = _a, rest = __rest(_a, ["postalCode"]);
    return (Object.assign(Object.assign({}, rest), { postal_code: postalCode }));
};
const useSaveBilling = () => {
    const stripe = (0, react_stripe_js_1.useStripe)();
    const elements = (0, react_stripe_js_1.useElements)();
    const [createBillingSetupIntentMutation, { error: createBillingSetupIntentError },] = (0, generated_1.useCreateBillingSetupIntentMutation)();
    const [updateBillingMutation, { error: updateBillingMutationError }] = (0, generated_1.useUpdateBillingMutation)();
    const [isSavingState, setIsSavingState] = (0, react_1.useState)(false);
    const [errorState, setErrorState] = (0, react_1.useState)();
    const saveBilling = (0, react_1.useCallback)(({ email, name, address }) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSavingState(true);
        try {
            if (!stripe) {
                throw new Error('Stripe is not defined');
            }
            if (!elements) {
                throw new Error('Stripe elements are not defined');
            }
            const cardElement = elements.getElement('card');
            if (!cardElement) {
                throw new Error('Stripe card element is not defined');
            }
            // Initiate a setup intent on the server
            const { data, errors: createBillingSetupIntentErrors } = yield createBillingSetupIntentMutation();
            if (createBillingSetupIntentErrors ||
                !(data && data.createBillingSetupIntent)) {
                throw new Error(`unable to createBillingSetupIntent: ${createBillingSetupIntentErrors
                    ? createBillingSetupIntentErrors.join(', ')
                    : ''}`);
            }
            const { setupIntent, error: stripeSetupIntentError } = yield stripe.confirmCardSetup(data.createBillingSetupIntent.clientSecret, {
                payment_method: {
                    billing_details: {
                        email,
                        name,
                        address: mapBillingAddressToStripeAddress(address),
                    },
                    card: cardElement,
                },
            });
            if (stripeSetupIntentError || !setupIntent) {
                throw new Error(stripeSetupIntentError
                    ? stripeSetupIntentError.message
                    : 'stripe.confirmCardSetup failed: setupIntent is undefined');
            }
            const paymentMethodId = setupIntent.payment_method;
            if (!paymentMethodId) {
                throw new Error('setupIntent.payment_method is not defined');
            }
            yield updateBillingMutation({
                variables: {
                    input: {
                        email,
                        name,
                        address,
                        paymentMethodId,
                    },
                },
            });
        }
        catch (error) {
            setErrorState(error);
            setIsSavingState(false);
            throw error;
        }
    }), [
        setIsSavingState,
        elements,
        createBillingSetupIntentMutation,
        stripe,
        updateBillingMutation,
    ]);
    return [
        saveBilling,
        {
            loading: isSavingState,
            error: errorState ||
                createBillingSetupIntentError ||
                updateBillingMutationError,
        },
    ];
};
exports.default = useSaveBilling;
