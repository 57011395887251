"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const useScreenSize_1 = require("uni/hooks/useScreenSize");
const styled_1 = require("./styled");
const HARD_LIMIT_RESPONSE_LENGTH = 500;
const SOFT_LIMIT_RESPONSE_LENGTH = 450;
const GeneratedQuestion = ({ id, onChange, isFocused, onFocus, onBlur, onFocusNext, isAnswerable, isValid = true, showErrorIfInvalid = true, answer = '', description, 
// 💩 Assuming that no placeholder means it's the Other Comments question
placeholder = 'Additional details', }) => {
    const isMobile = (0, useScreenSize_1.useIsMobileScreenSize)();
    const inputRef = (0, react_1.useRef)(null);
    // Focus the input when the component is updated and isFocused is true
    (0, react_1.useEffect)(() => {
        if (isFocused && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.selectionStart = inputRef.current.selectionEnd =
                inputRef.current.value.length;
        }
    }, [isFocused]);
    const onInputClick = (0, react_1.useCallback)(() => {
        var _a;
        if (!isAnswerable)
            return;
        onFocus();
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [isAnswerable, onFocus]);
    const onChangeAndSetResponse = (0, react_1.useCallback)((val) => {
        onChange(id, val);
    }, [onChange, id]);
    const onKeyDown = (0, react_1.useCallback)((e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onFocusNext(true);
        }
        else if (e.key === 'Tab') {
            e.preventDefault();
            if (placeholder && answer === '' && !e.shiftKey) {
                onChangeAndSetResponse(placeholder);
            }
            else {
                onFocusNext(!e.shiftKey);
            }
        }
    }, [onFocusNext, placeholder, answer, onChangeAndSetResponse]);
    const focusedOrHasResponse = isFocused || !!answer;
    const showInput = focusedOrHasResponse || isMobile; // Always show the input on mobile
    return (react_1.default.createElement(styled_1.QuestionContainer, { "$isValid": isValid || !showErrorIfInvalid, onClick: onInputClick },
        !isValid && showErrorIfInvalid ? (react_1.default.createElement(styled_1.CircleCrossmarkIcon, null)) : answer && isValid ? (react_1.default.createElement(styled_1.CircleCheckIcon, null)) : (react_1.default.createElement(styled_1.CircleIcon, null)),
        react_1.default.createElement(styled_1.InputContainer, null,
            react_1.default.createElement(styled_1.InputLabel, { "$smallLabel": showInput }, description),
            isAnswerable && (react_1.default.createElement(styled_1.QuestionInput, { ref: inputRef, "$hide": !showInput, onBlur: onBlur, value: answer, placeholder: !answer ? placeholder : undefined, onChange: onChangeAndSetResponse, onKeyDown: onKeyDown, minRows: 1, maxRows: isMobile ? 999 : 4, maxLength: HARD_LIMIT_RESPONSE_LENGTH, accessory: react_1.default.createElement(styled_1.CharacterCount, { textLength: answer.length, softLimit: SOFT_LIMIT_RESPONSE_LENGTH, hardLimit: HARD_LIMIT_RESPONSE_LENGTH, show: answer.length >= SOFT_LIMIT_RESPONSE_LENGTH }) })))));
};
exports.default = react_1.default.memo(GeneratedQuestion);
