"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ClientsManager_1 = require("uni/components/apollo/ClientsManager");
const generated_1 = require("uni/graphql/hooks/generated");
const useTimeTrackingLastLockedAtEntry = () => {
    const { getNonWorkspacedApolloClient } = (0, ClientsManager_1.useClientsManager)();
    return (0, generated_1.useTimeTrackingLastLockedAtEntryQuery)({
        client: getNonWorkspacedApolloClient(),
        fetchPolicy: 'cache-and-network',
    });
};
exports.default = useTimeTrackingLastLockedAtEntry;
