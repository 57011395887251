"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const react_1 = require("react");
const cache_1 = require("uni/graphql/helpers/cache");
const useUpdateFragment = (fragmentName, fragment) => {
    const client = (0, client_1.useApolloClient)();
    return (0, react_1.useCallback)((id, input, forcedClient) => {
        const localClient = forcedClient || client;
        const frag = (0, cache_1.findFragment)(localClient, {
            id,
            fragment,
            fragmentName,
        });
        const data = typeof input === 'function'
            ? input(frag)
            : frag
                ? Object.assign(Object.assign({}, frag), input) : null;
        if (data) {
            localClient.writeFragment({
                id,
                fragment,
                fragmentName,
                data,
            });
        }
    }, [fragmentName, fragment, client]);
};
exports.default = useUpdateFragment;
