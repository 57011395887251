"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shadowed = exports.circle = exports.maxScreenWidth = exports.minScreenWidth = exports.shape = exports.disc = exports.rounded = exports.square = exports.flexCenter = exports.LOW_Z_INDEX = exports.MIDDLE_Z_INDEX = exports.HIGH_Z_INDEX = void 0;
const styled_components_1 = require("styled-components");
exports.HIGH_Z_INDEX = 10000;
exports.MIDDLE_Z_INDEX = 50;
exports.LOW_Z_INDEX = -100;
exports.flexCenter = (0, styled_components_1.css) `
  align-items: center;
  justify-content: center;
`;
const square = (size) => (0, styled_components_1.css) `
  height: ${size}px;
  width: ${size}px;
`;
exports.square = square;
const rounded = (radiusPx = 9999) => (0, styled_components_1.css) `
  border-radius: ${radiusPx}px;
`;
exports.rounded = rounded;
const disc = (size) => (0, styled_components_1.css) `
  ${(0, exports.square)(size)}
  ${(0, exports.rounded)(size)}
  flex-shrink: 0;
`;
exports.disc = disc;
const shape = (width, height, radius) => (0, styled_components_1.css) `
  width: ${width}px;
  height: ${height}px;
  ${radius ? (0, exports.rounded)(radius) : ''}
`;
exports.shape = shape;
const minScreenWidth = (width) => (content) => (0, styled_components_1.css) `
      @media screen and (min-width: ${width}px) {
        ${content}
      }
    `;
exports.minScreenWidth = minScreenWidth;
const maxScreenWidth = (width) => (content) => (0, styled_components_1.css) `
      @media screen and (max-width: ${width}px) {
        ${content}
      }
    `;
exports.maxScreenWidth = maxScreenWidth;
const circle = (size) => (0, exports.shape)(size, size, size);
exports.circle = circle;
const shadowed = (offsetX = 0, offsetY = 2, radius = 4, color = null, alpha = 0.14) => (0, styled_components_1.css) `
  box-shadow: ${offsetX}px ${offsetY}px ${radius}px
    ${color || `rgba(1, 1, 1, ${alpha})`};
`;
exports.shadowed = shadowed;
