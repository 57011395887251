"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const datadog_1 = require("uni/lib/logger/datadog");
const useRegisterBrowser = () => {
    const [mutate, { data, loading, error }] = (0, generated_1.useRegisterBrowserMutation)();
    const execute = (0, react_1.useCallback)(({ endpoint, p256dh, auth }) => __awaiter(void 0, void 0, void 0, function* () {
        return yield mutate({
            variables: {
                input: {
                    endpoint,
                    p256dh,
                    auth,
                },
            },
        });
    }), [mutate]);
    (0, react_1.useEffect)(() => {
        if (loading)
            return;
        if (error) {
            (0, datadog_1.logErrorToDatadog)('web-push.register-browser')(error);
        }
        else if (data) {
            (0, datadog_1.logToDatadog)('web-push.register-browser', {
                isSuccess: data.registerBrowser.isSuccess,
            });
        }
    }, [data, error, loading]);
    return [execute, { data, loading, error }];
};
exports.default = useRegisterBrowser;
