"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const generated_1 = require("uni/graphql/hooks/generated");
const datadog_1 = require("uni/lib/logger/datadog");
function getPoolTaskStatusDisplayName(status) {
    switch (status) {
        case generated_1.TodoStatus.READY:
            return 'Delegated';
        case generated_1.TodoStatus.IN_PROGRESS:
            return 'In Progress';
        case generated_1.TodoStatus.DONE:
            return 'Done';
        case generated_1.TodoStatus.DRAFT:
            return `Drafts`;
        default:
            (0, datadog_1.logToDatadog)('PoolTaskStatusList: status not handled', {
                status,
            }, datadog_1.LogLevel.WARN);
    }
}
exports.default = getPoolTaskStatusDisplayName;
