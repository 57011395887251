"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ASSISTANT_SUBTITLE2 = exports.ASSISTANT_SUBTITLE1 = exports.ASSISTANT_TITLE = exports.EXEC_SUBTITLE2 = exports.getExecSubtitle1 = exports.EXEC_TITLE = void 0;
exports.EXEC_TITLE = 'other executives save time';
const getExecSubtitle1 = (assistantGivenNameCapitalized) => `${assistantGivenNameCapitalized} can take care of your most common recurring tasks.`;
exports.getExecSubtitle1 = getExecSubtitle1;
exports.EXEC_SUBTITLE2 = '2 minutes to set up a Template, hours saved every week.';
exports.ASSISTANT_TITLE = 'super assistants work';
exports.ASSISTANT_SUBTITLE1 = `Autopilot your exec's calendar, inbox, finances and more!`;
exports.ASSISTANT_SUBTITLE2 = 'Templates make it easy with guided setup & built-in tips from all-star assistants.';
