"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleListTerm = exports.matchesFilter = exports.hasActiveFilter = exports.normalizeCountry = exports.normalizeHoursRange = exports.TabFilter = void 0;
const DEFAULT_NULL_VALUE = '--';
var TabFilter;
(function (TabFilter) {
    TabFilter["All"] = "all";
    TabFilter["Bookmarked"] = "bookmarked";
})(TabFilter = exports.TabFilter || (exports.TabFilter = {}));
const normalizeHoursRange = (hoursNeededRange, defaultValue = DEFAULT_NULL_VALUE) => hoursNeededRange
    ? hoursNeededRange.to
        ? `${hoursNeededRange.from}-${hoursNeededRange.to} hrs`
        : `${hoursNeededRange.from}+ hrs`
    : defaultValue;
exports.normalizeHoursRange = normalizeHoursRange;
const normalizeCountry = (country) => country || DEFAULT_NULL_VALUE;
exports.normalizeCountry = normalizeCountry;
function hasActiveFilter(filter) {
    return filter.timezones.length > 0 || filter.hoursNeeded.length > 0;
}
exports.hasActiveFilter = hasActiveFilter;
function matchesFilter(filter, matching) {
    var _a, _b;
    if (filter.tab === TabFilter.Bookmarked && !matching.isBookmarked) {
        return false;
    }
    if (filter.timezones.length &&
        !filter.timezones.includes((0, exports.normalizeCountry)((_a = matching.profiles[0]) === null || _a === void 0 ? void 0 : _a.country))) {
        return false;
    }
    if (filter.hoursNeeded.length &&
        !filter.hoursNeeded.includes((0, exports.normalizeHoursRange)((_b = matching.profiles[0]) === null || _b === void 0 ? void 0 : _b.hoursNeededRange))) {
        return false;
    }
    return true;
}
exports.matchesFilter = matchesFilter;
const toggleListTerm = (originalList, term) => {
    const newList = originalList.filter((item) => item !== term);
    if (newList.length === originalList.length) {
        newList.push(term);
    }
    return newList;
};
exports.toggleListTerm = toggleListTerm;
