"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const PopUpBoundaryRectProvider_1 = require("../PopUpBoundaryRectProvider");
const types_1 = require("./types");
const useDerivedStyle = ({ anchor, direction = types_1.PopoverDirection.BOTTOM_RIGHT, safeMargin = {}, isOpen, }) => {
    // Use BoundaryRect in case the app does not take up the full viewport (eg.
    // when an Intercom banner is present)
    const boundaryRect = (0, PopUpBoundaryRectProvider_1.usePopUpBoundaryRect)(!isOpen);
    const { x: safeMarginX = 0, y: safeMarginY = 0 } = safeMargin;
    const xFromLeft = anchor.x - boundaryRect.x;
    const yFromTop = anchor.y - boundaryRect.y;
    const translateXFromLeft = `${xFromLeft}px`;
    const translateXFromRight = `calc(${xFromLeft}px - 100%)`;
    const translateXMin = `${safeMarginX}px`;
    const translateXMax = `calc(${boundaryRect.width}px - 100% - ${safeMarginX}px)`;
    const translateYFromTop = `${yFromTop}px`;
    const translateYFromBottom = `calc(${yFromTop}px - 100%)`;
    const translateYMin = `${safeMarginY}px`;
    const translateYMax = `calc(${boundaryRect.height}px - 100% - ${safeMarginY}px)`;
    const transformStyle = (0, react_1.useMemo)(() => {
        switch (direction) {
            case types_1.PopoverDirection.TOP:
                return {
                    transform: `
            translateX(min(max(${translateXFromLeft} - 50%, ${translateXMin}), ${translateXMax}))
            translateY(max(${translateYFromBottom}, ${translateYMin}))
          `,
                };
            case types_1.PopoverDirection.RIGHT:
                return {
                    transform: `
            translateX(min(${translateXFromLeft}, ${translateXMax}))
            translateY(min(max(calc(${translateYFromTop} - 50%), ${translateYMin}), ${translateYMax}))
          `,
                };
            case types_1.PopoverDirection.BOTTOM:
                return {
                    transform: `
            translateX(min(max(${translateXFromLeft} - 50%, ${translateXMin}), ${translateXMax}))
            translateY(min(${translateYFromTop}, ${translateYMax}))
          `,
                };
            case types_1.PopoverDirection.LEFT:
                return {
                    transform: `
            translateX(max(${translateXFromRight}, ${translateXMin}))
            translateY(min(max(calc(${translateYFromTop} - 50%), ${translateYMin}), ${translateYMax}))
          `,
                };
            case types_1.PopoverDirection.TOP_LEFT:
                return {
                    transform: `
            translateX(max(${translateXFromRight}, ${translateXMin}))
            translateY(max(${translateYFromBottom}, ${translateYMin}))
          `,
                };
            case types_1.PopoverDirection.TOP_RIGHT:
                return {
                    transform: `
            translateX(min(${translateXFromLeft}, ${translateXMax}))
            translateY(max(${translateYFromBottom}, ${translateYMin}))
          `,
                };
            case types_1.PopoverDirection.BOTTOM_LEFT:
                return {
                    transform: `
            translateX(max(${translateXFromRight}, ${translateXMin}))
            translateY(min(${translateYFromTop}, ${translateYMax}))
          `,
                };
            case types_1.PopoverDirection.BOTTOM_RIGHT:
                return {
                    transform: `
            translateX(min(${translateXFromLeft}, ${translateXMax}))
            translateY(min(${translateYFromTop}, ${translateYMax}))
          `,
                };
        }
    }, [
        direction,
        translateXFromLeft,
        translateXFromRight,
        translateXMin,
        translateXMax,
        translateYFromTop,
        translateYFromBottom,
        translateYMin,
        translateYMax,
    ]);
    return (0, react_1.useMemo)(() => {
        return Object.assign({ position: 'absolute', top: 0, left: 0 }, transformStyle);
    }, [transformStyle]);
};
exports.default = useDerivedStyle;
