"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnchorYConfig = exports.AnchorXConfig = void 0;
const react_1 = require("react");
const react_dom_1 = require("react-dom");
var AnchorXConfig;
(function (AnchorXConfig) {
    AnchorXConfig["LEFT"] = "left";
    AnchorXConfig["RIGHT"] = "right";
    AnchorXConfig["CENTER"] = "center";
})(AnchorXConfig = exports.AnchorXConfig || (exports.AnchorXConfig = {}));
var AnchorYConfig;
(function (AnchorYConfig) {
    AnchorYConfig["TOP"] = "top";
    AnchorYConfig["BOTTOM"] = "bottom";
})(AnchorYConfig = exports.AnchorYConfig || (exports.AnchorYConfig = {}));
const usePopOverAnchor = (ref, anchorXConfig, anchorYConfig) => {
    const [anchor, setAnchor] = (0, react_1.useState)();
    const onOpen = (0, react_1.useCallback)(() => {
        const node = (0, react_dom_1.findDOMNode)(ref.current);
        if (!(node instanceof Element)) {
            return;
        }
        const rect = node.getBoundingClientRect();
        const anchorX = anchorXConfig === AnchorXConfig.CENTER
            ? rect.right - rect.width / 2
            : rect[anchorXConfig];
        setAnchor({ x: anchorX, y: rect[anchorYConfig] });
    }, [ref, anchorXConfig, anchorYConfig, setAnchor]);
    return (0, react_1.useMemo)(() => ({
        anchor,
        onOpen,
        onClose: () => {
            setAnchor(undefined);
        },
    }), [anchor, onOpen]);
};
exports.default = usePopOverAnchor;
