"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const isPrivateOnboardingTemplateTopic = (topic) => {
    if (topic.__typename !== 'Todo') {
        return false;
    }
    if (topic.labels && !topic.labels.includes('NEW_EXECUTIVE_WORKSPACE')) {
        return false;
    }
    return topic.isPrivate;
};
exports.default = isPrivateOnboardingTemplateTopic;
