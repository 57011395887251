"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useSelfDestructBoolean = (duration = 7000) => {
    const [d] = (0, react_1.useState)(() => duration);
    const [alive, setAlive] = (0, react_1.useState)(false);
    const timeout = (0, react_1.useRef)(null);
    const trigger = (0, react_1.useCallback)(() => {
        setAlive(true);
        if (timeout.current) {
            window.clearTimeout(timeout.current);
        }
        timeout.current = window.setTimeout(() => {
            setAlive(false);
        }, d);
    }, [setAlive, d]);
    (0, react_1.useEffect)(() => () => {
        if (timeout.current) {
            window.clearTimeout(timeout.current);
        }
    }, []);
    return [alive, trigger];
};
exports.default = useSelfDestructBoolean;
