"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const useReadMatchingsListInAppReminder = () => {
    const [readMatchingsListInAppReminderMutation, { error }] = (0, generated_1.useReadMatchingsListInAppReminderMutation)();
    const execute = () => {
        readMatchingsListInAppReminderMutation({
            optimisticResponse: {
                __typename: 'Mutation',
                readMatchingsListInAppReminder: {
                    __typename: 'ReadMatchingsListInAppReminderOutput',
                    matchingsList: {
                        __typename: 'MatchingsList',
                        // 💩 Yeah, what are you gonna do???
                        id: 'id',
                        inAppReminderText: null,
                    },
                },
            },
        });
    };
    return (0, react_1.useMemo)(() => [execute, { error }], [execute, error]);
};
exports.default = useReadMatchingsListInAppReminder;
