"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// check if browser supports the promise version of Notification.requestPermission()
// https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API#feature-detecting_the_requestpermission_promise
const checkNotificationPromise = () => {
    try {
        // eslint-disable-next-line promise/prefer-await-to-then, promise/catch-or-return, promise/valid-params
        Notification.requestPermission().then();
    }
    catch (e) {
        return false;
    }
    return true;
};
const requestNotificationPermission = () => {
    if (checkNotificationPromise()) {
        return Notification.requestPermission();
    }
    return new Promise((resolve) => {
        Notification.requestPermission((permission) => {
            resolve(permission);
        });
    });
};
exports.default = requestNotificationPermission;
