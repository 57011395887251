"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabContent = exports.Tab = exports.LightLabel = exports.HeavyLabel = exports.Labels = exports.TabBar = exports.StyledLockedIcon = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const locked_s_svg_1 = require("uni/system/icons/locked-s.svg");
exports.StyledLockedIcon = (0, styled_components_1.default)(locked_s_svg_1.ReactComponent) `
  margin-right: 2px;
  color: ${colors_1.gray6};
`;
exports.TabBar = styled_components_1.default.div `
  display: flex;
  border-bottom: 1px solid ${colors_1.gray3};
`;
exports.Labels = styled_components_1.default.div `
  position: relative;
`;
exports.HeavyLabel = styled_components_1.default.span `
  ${text_1.bodyBold};
  color: ${colors_1.black};
`;
exports.LightLabel = styled_components_1.default.span `
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
`;
exports.Tab = styled_components_1.default.div `
  ${text_1.bodyMedium};
  color: ${colors_1.gray6};
  cursor: pointer;
  display: flex;
  margin-right: 24px;
  padding-bottom: 9.5px;
  border-bottom: 2.5px solid transparent;

  ${exports.HeavyLabel} {
    visibility: hidden;
  }
  ${exports.LightLabel} {
    visibility: visible;
  }

  ${(props) => !!props.selected &&
    (0, styled_components_1.css) `
      border-color: ${colors_1.purple5};
      ${exports.HeavyLabel} {
        visibility: visible;
      }
      ${exports.LightLabel} {
        visibility: hidden;
      }
    `}
`;
exports.TabContent = styled_components_1.default.div `
  height: 21px;
  display: flex;
  align-items: center;
`;
