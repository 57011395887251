"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styled_1 = require("./styled");
const MonthlySelector = ({ recurrence, onChange }) => {
    const updateRecurrence = (0, react_1.useCallback)((event) => {
        const textUpdated = event.target.value || '';
        const day = textUpdated === '' ? 0 : parseInt(textUpdated);
        if (day >= 0 && day <= 31) {
            onChange(Object.assign(Object.assign({}, recurrence), { day }));
        }
    }, [onChange, recurrence]);
    const didFocus = (0, react_1.useCallback)((event) => {
        event.target.select();
        updateRecurrence(event);
    }, [updateRecurrence]);
    return (react_1.default.createElement(styled_1.Outer, null,
        react_1.default.createElement(styled_1.Text, null, 'Will repeat monthly on day'),
        react_1.default.createElement(styled_1.Input, { onFocus: didFocus, autoFocus: true, onChange: updateRecurrence, value: String(recurrence.day || '') })));
};
exports.default = MonthlySelector;
