"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const SCREENS_1 = __importStar(require("./SCREENS"));
const useScreens = () => {
    const { data } = (0, generated_1.useGetOnboardingStepsQuery)();
    const shouldDisplayVoiceNoteScreens = !data || !data.workspace.onboarding
        ? false
        : data.workspace.onboarding.steps.some(({ id }) => id === generated_1.OnboardingStepId.DEMO);
    const screens = (0, react_1.useMemo)(() => shouldDisplayVoiceNoteScreens
        ? SCREENS_1.default
        : SCREENS_1.default.filter(({ route }) => route !== SCREENS_1.SEND_A_VOICE_NOTE_ROUTE && route !== SCREENS_1.TRANSCRIPT_ROUTE), [shouldDisplayVoiceNoteScreens]);
    return screens;
};
exports.default = useScreens;
