"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkExperienceProperty = void 0;
const react_1 = require("react");
const uuid_1 = require("uuid");
var WorkExperienceProperty;
(function (WorkExperienceProperty) {
    WorkExperienceProperty["COMPANY_NAME"] = "companyName";
    WorkExperienceProperty["TITLE"] = "title";
    WorkExperienceProperty["START_MONTH"] = "startMonth";
    WorkExperienceProperty["START_YEAR"] = "startYear";
    WorkExperienceProperty["END_MONTH"] = "endMonth";
    WorkExperienceProperty["END_YEAR"] = "endYear";
    WorkExperienceProperty["CURRENTLY_WORKING"] = "currentlyWorking";
    WorkExperienceProperty["DESCRIPTION"] = "description";
})(WorkExperienceProperty = exports.WorkExperienceProperty || (exports.WorkExperienceProperty = {}));
const useWorkExperience = (defaultValue) => {
    const [workExperiences, setWorkExperiences] = (0, react_1.useState)(defaultValue.map((workExperience) => {
        var _a, _b;
        return (Object.assign(Object.assign({}, workExperience), { 
            // create unique ids to identify when editing
            id: (0, uuid_1.v4)(), currentlyWorking: !workExperience.endDate, endDate: {
                year: ((_a = workExperience.endDate) === null || _a === void 0 ? void 0 : _a.year) || undefined,
                month: (_b = workExperience.endDate) === null || _b === void 0 ? void 0 : _b.month,
            } }));
    }));
    // if workExperience is empty, add a new empty work experience
    (0, react_1.useEffect)(() => {
        if (workExperiences.length === 0) {
            const today = new Date();
            setWorkExperiences([
                {
                    __typename: 'WorkExperience',
                    id: (0, uuid_1.v4)(),
                    title: '',
                    companyName: '',
                    startDate: {
                        __typename: 'AnniversaryDate',
                        month: today.getMonth(),
                        year: today.getFullYear(),
                    },
                },
            ]);
        }
    }, [workExperiences]);
    const updateTextValue = (0, react_1.useCallback)((newValue, experienceToUpdateId, experienceType) => {
        setWorkExperiences(workExperiences.map((experience) => {
            // Only update the corresponding experience
            if (experience.id !== experienceToUpdateId)
                return experience;
            return Object.assign(Object.assign({}, experience), { [experienceType]: newValue });
        }));
    }, [workExperiences]);
    const updateDateValue = (0, react_1.useCallback)((newValue, experienceToUpdateId, experienceType) => {
        setWorkExperiences(workExperiences.map((experience) => {
            // Only update the corresponding experience
            if (experience.id !== experienceToUpdateId)
                return experience;
            switch (experienceType) {
                case WorkExperienceProperty.START_MONTH:
                    return Object.assign(Object.assign({}, experience), { startDate: Object.assign(Object.assign({}, experience.startDate), { month: newValue }) });
                case WorkExperienceProperty.START_YEAR:
                    return Object.assign(Object.assign({}, experience), { startDate: Object.assign(Object.assign({}, experience.startDate), { year: newValue }) });
                case WorkExperienceProperty.END_MONTH:
                    return Object.assign(Object.assign({}, experience), { endDate: Object.assign(Object.assign({}, experience.endDate), { month: newValue }) });
                case WorkExperienceProperty.END_YEAR:
                    return Object.assign(Object.assign({}, experience), { endDate: Object.assign(Object.assign({}, experience.endDate), { year: newValue }) });
                default:
                    return experience;
            }
        }));
    }, [workExperiences]);
    const updateCurrentlyWorkingValue = (0, react_1.useCallback)((newValue, experienceToUpdateId) => {
        setWorkExperiences(workExperiences.map((experience) => {
            // Only update the corresponding experience
            if (experience.id !== experienceToUpdateId)
                return experience;
            return Object.assign(Object.assign({}, experience), { currentlyWorking: newValue });
        }));
    }, [workExperiences]);
    const removeLastExperience = (0, react_1.useCallback)(() => {
        setWorkExperiences(workExperiences.slice(0, workExperiences.length - 1));
    }, [workExperiences]);
    const addExperience = (0, react_1.useCallback)(() => {
        const today = new Date();
        const newExperience = {
            __typename: 'WorkExperience',
            id: (0, uuid_1.v4)(),
            title: '',
            companyName: '',
            startDate: {
                __typename: 'AnniversaryDate',
                month: today.getMonth(),
                year: today.getFullYear(),
            },
        };
        setWorkExperiences([...workExperiences, newExperience]);
    }, [workExperiences]);
    return {
        workExperiences,
        updateTextValue,
        updateDateValue,
        updateCurrentlyWorkingValue,
        removeLastExperience,
        addExperience,
    };
};
exports.default = useWorkExperience;
