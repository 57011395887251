"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toTimeTrackingEntryTaskInfo = (task) => {
    var _a;
    const { topic = undefined, template = undefined } = (() => {
        if ((task === null || task === void 0 ? void 0 : task.__typename) === 'TimeTrackingEntryTemplate') {
            return {
                template: task,
                topic: null,
            };
        }
        else if ((task === null || task === void 0 ? void 0 : task.__typename) === 'TimeTrackingTopic') {
            return { template: null, topic: task };
        }
        else if (task === null) {
            // Clear template and topic
            return { template: null, topic: null };
        }
        else {
            return {};
        }
    })();
    return {
        topic,
        template,
        title: (_a = (topic || template)) === null || _a === void 0 ? void 0 : _a.title,
    };
};
exports.default = toTimeTrackingEntryTaskInfo;
