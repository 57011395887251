"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateGQLToLuxonWeekday = exports.translateLuxonToGQLWeekday = void 0;
const generated_1 = require("uni/graphql/hooks/generated");
const GQL_TO_LUXON_WEEKDAYS = {
    [generated_1.WeekDay.MONDAY]: 1,
    [generated_1.WeekDay.TUESDAY]: 2,
    [generated_1.WeekDay.WEDNESDAY]: 3,
    [generated_1.WeekDay.THURSDAY]: 4,
    [generated_1.WeekDay.FRIDAY]: 5,
    [generated_1.WeekDay.SATURDAY]: 6,
    [generated_1.WeekDay.SUNDAY]: 7,
};
const LUXON_TO_WEEKDAYS = {
    '1': generated_1.WeekDay.MONDAY,
    '2': generated_1.WeekDay.TUESDAY,
    '3': generated_1.WeekDay.WEDNESDAY,
    '4': generated_1.WeekDay.THURSDAY,
    '5': generated_1.WeekDay.FRIDAY,
    '6': generated_1.WeekDay.SATURDAY,
    '7': generated_1.WeekDay.SUNDAY,
};
const translateLuxonToGQLWeekday = (wd) => LUXON_TO_WEEKDAYS['' + wd] || generated_1.WeekDay.MONDAY;
exports.translateLuxonToGQLWeekday = translateLuxonToGQLWeekday;
const translateGQLToLuxonWeekday = (wd) => GQL_TO_LUXON_WEEKDAYS[wd];
exports.translateGQLToLuxonWeekday = translateGQLToLuxonWeekday;
