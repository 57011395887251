"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useComposerKeyPressHandler(text, setText, onEnter, isEmailDigestMode, ref, onBlur, isMessageSendingDisabled) {
    const onKeyPress = (0, react_1.useCallback)((event) => {
        var _a;
        if (event.key === 'Enter') {
            let shouldSubmit = true;
            if (event.metaKey) {
                // Forced submit with Meta+Enter
                shouldSubmit = true;
            }
            else if (event.shiftKey || event.altKey || event.ctrlKey) {
                // Shift+Enter or Alt+Enter or Ctrl+Enter => newline, don’t submit
                shouldSubmit = false;
                if (event.altKey || event.ctrlKey) {
                    // Alt+Enter and Ctrl+Enter don’t insert a new line, so add it manually
                    setText(text + '\n');
                }
            }
            if (isMessageSendingDisabled)
                return;
            if (shouldSubmit && (!!text || isEmailDigestMode)) {
                event.preventDefault();
                onEnter();
                if (isEmailDigestMode) {
                    if (ref === null || ref === void 0 ? void 0 : ref.current)
                        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.blur();
                    if (onBlur)
                        onBlur();
                }
            }
        }
    }, [
        isMessageSendingDisabled,
        text,
        isEmailDigestMode,
        setText,
        onEnter,
        ref,
        onBlur,
    ]);
    return { onKeyPress };
}
exports.default = useComposerKeyPressHandler;
