"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const resolvers = {
    User: {
        fields: {
            accounts: {
                merge: false,
            },
        },
    },
};
exports.default = resolvers;
