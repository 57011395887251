"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const metrics_1 = require("uni/lib/metrics");
const types_1 = require("uni/lib/metrics/types");
const types_2 = require("uni/lib/topicTemplates/types");
const useCreateTodoFromDelegationCoachSuggestion = ({ delegationCoachTaskSuggestionsId, taskSuggestions, }) => {
    const [createTodoFromDelegationCoachSuggestionMutation, result] = (0, generated_1.useCreateTodoFromDelegationCoachSuggestionMutation)();
    const createTodoFromDelegationCoachSuggestion = (0, react_1.useCallback)(({ suggestionId, topicId, }) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield createTodoFromDelegationCoachSuggestionMutation({
            variables: {
                input: {
                    id: suggestionId,
                    todoId: topicId,
                    delegationCoachTaskSuggestionsId,
                },
            },
            optimisticResponse: {
                __typename: 'Mutation',
                createTodoFromDelegationCoachSuggestion: {
                    __typename: 'CreateTodoFromDelegationCoachSuggestionOutput',
                    delegationCoachTaskSuggestionsTimelineItem: {
                        id: delegationCoachTaskSuggestionsId,
                        __typename: 'DelegationCoachTaskSuggestionsTimelineItem',
                        taskSuggestions: taskSuggestions.map((_a) => {
                            var { id } = _a, rest = __rest(_a, ["id"]);
                            if (id !== suggestionId)
                                return Object.assign({ id }, rest);
                            return Object.assign(Object.assign({ id }, rest), { hasTopicBeenCreated: true });
                        }),
                    },
                },
            },
        });
        metrics_1.metrics.track(types_1.TrackingEvent.TASK_CREATED, {
            topic: {
                id: topicId,
            },
            reason: types_1.TaskCreationReason.FROM_DELEGATION_COACH,
            source: types_2.Source.DelegationCoach,
        });
        return result;
    }), [
        createTodoFromDelegationCoachSuggestionMutation,
        delegationCoachTaskSuggestionsId,
        taskSuggestions,
    ]);
    return [createTodoFromDelegationCoachSuggestion, result];
};
exports.default = useCreateTodoFromDelegationCoachSuggestion;
