"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetSearchParams = void 0;
const react_1 = require("react");
const react_router_1 = require("react-router");
const react_router_dom_1 = require("react-router-dom");
const useSearchParams = () => {
    const location = (0, react_router_1.useLocation)();
    return (0, react_1.useMemo)(() => new URLSearchParams(location.search), [location.search]);
};
const useSetSearchParams = () => {
    const history = (0, react_router_dom_1.useHistory)();
    return (0, react_1.useCallback)((key, value) => {
        // Get the current URLSearchParams
        const searchParams = new URLSearchParams(window.location.search);
        // Set the new query parameter
        searchParams.set(key, value);
        // Update the URL with the new query parameters
        history.push({ search: searchParams.toString() });
    }, [history]);
};
exports.useSetSearchParams = useSetSearchParams;
exports.default = useSearchParams;
