"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ClientsManager_1 = require("uni/components/apollo/ClientsManager");
const generated_1 = require("uni/graphql/hooks/generated");
const useAppBootChecks_1 = require("uni/graphql/hooks/useAppBootChecks");
const usePlacePoolTodoOrder = (options) => {
    const { getNonWorkspacedApolloClient } = (0, ClientsManager_1.useClientsManager)();
    return (0, generated_1.usePlacePoolTodoOrderMutation)(Object.assign(Object.assign({}, options), { onCompleted: (data) => {
            var _a;
            if (!data)
                return;
            const nonWorkspacedApolloClient = getNonWorkspacedApolloClient();
            (0, useAppBootChecks_1.updatePoolTokenBalance)(nonWorkspacedApolloClient.cache, data.placePoolTodoOrder.poolTokenBalance.availablePoolTokenCount);
            (_a = options === null || options === void 0 ? void 0 : options.onCompleted) === null || _a === void 0 ? void 0 : _a.call(options, data);
        } }));
};
exports.default = usePlacePoolTodoOrder;
