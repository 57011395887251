"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const metrics_1 = require("uni/lib/metrics");
const types_1 = require("uni/lib/metrics/types");
const styled_1 = require("./styled");
const TabSelector = ({ className, tabs, currentTab, onTabClicked: onTabClickedProp, source, }) => {
    const onTabClicked = (0, react_1.useCallback)(({ id, label, isPrivate = false }) => {
        metrics_1.metrics.track(types_1.TrackingEvent.BUTTON_PRESSED, {
            name: 'tab_selected',
            source,
            tabId: id,
            label,
            isPrivate,
        });
        onTabClickedProp(id);
    }, [onTabClickedProp, source]);
    return (react_1.default.createElement(styled_1.TabBar, { className: className }, tabs === null || tabs === void 0 ? void 0 : tabs.map((tab) => (react_1.default.createElement(styled_1.Tab, { key: tab.id, onClick: () => onTabClicked(tab), selected: currentTab === tab.id },
        react_1.default.createElement(styled_1.TabContent, null,
            !!tab.isPrivate && react_1.default.createElement(styled_1.StyledLockedIcon, null),
            react_1.default.createElement(styled_1.Labels, null,
                react_1.default.createElement(styled_1.HeavyLabel, null, tab.label),
                react_1.default.createElement(styled_1.LightLabel, null, tab.label))))))));
};
exports.default = TabSelector;
