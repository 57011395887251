"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrimaryButton = exports.StepperContainer = exports.FooterGroup = exports.FlexDirectionColumnScreenSizeThreshold = exports.DotsContainer = exports.Loader = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const buttons_1 = require("uni/style/buttons");
const colors_1 = require("uni/style/colors");
const devices_1 = require("uni/style/devices");
const text_1 = require("uni/style/text");
exports.Outer = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  justify-content: ${({ $center }) => ($center ? 'center' : 'space-between')};
  flex-shrink: 0;
  min-height: 96px;

  border-top: 1px solid ${colors_1.gray2};

  ${(0, devices_1.screenS)((0, styled_components_1.css) `
    flex-direction: column;
    padding: 4px 16px;
    gap: 16px;
  `)}
`;
exports.Loader = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  ${text_1.titleMedium}
  color: ${colors_1.gray6};
`;
exports.DotsContainer = styled_components_1.default.div `
  width: 24px;
`;
var FlexDirectionColumnScreenSizeThreshold;
(function (FlexDirectionColumnScreenSizeThreshold) {
    FlexDirectionColumnScreenSizeThreshold[FlexDirectionColumnScreenSizeThreshold["S"] = 0] = "S";
    FlexDirectionColumnScreenSizeThreshold[FlexDirectionColumnScreenSizeThreshold["M"] = 1] = "M";
})(FlexDirectionColumnScreenSizeThreshold = exports.FlexDirectionColumnScreenSizeThreshold || (exports.FlexDirectionColumnScreenSizeThreshold = {}));
exports.FooterGroup = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  gap: 20px;

  ${({ $flexDirectionColumnScreenSizeThreshold }) => {
    switch ($flexDirectionColumnScreenSizeThreshold) {
        case FlexDirectionColumnScreenSizeThreshold.S:
            return (0, devices_1.screenS)((0, styled_components_1.css) `
          flex-direction: column;
        `);
        case FlexDirectionColumnScreenSizeThreshold.M:
            return (0, devices_1.screenM)((0, styled_components_1.css) `
          flex-direction: column;
        `);
    }
}}

  ${(0, devices_1.screenS)((0, styled_components_1.css) `
    gap: 12px;
    width: 100%;
  `)}
`;
exports.StepperContainer = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${text_1.calloutMedium}
  color: ${colors_1.gray6};

  ${(0, devices_1.screenS)((0, styled_components_1.css) `
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `)}
`;
// The two characters after the color are the hex value for the alpha channel
const violetBackgroundGradient = (0, styled_components_1.css) `
  background: linear-gradient(
      0deg,
      ${(0, colors_1.hexaToRgba)(colors_1.violet4, 0)} 0%,
      ${(0, colors_1.hexaToRgba)(colors_1.violet4, 0.7)} 100%
    ),
    ${colors_1.violet5};
`;
const violetHoverGradient = (0, styled_components_1.css) `
  background: linear-gradient(
      0deg,
      ${(0, colors_1.hexaToRgba)(colors_1.violet4, 0.4)} 0%,
      ${(0, colors_1.hexaToRgba)(colors_1.violet4, 1)} 100%
    ),
    ${colors_1.violet5} !important;
`;
exports.PrimaryButton = (0, styled_components_1.default)(buttons_1.SuperBigButtonPrimary) `
  box-shadow: none;
  gap: 8px;

  ${({ $isPendingReevaluation }) => $isPendingReevaluation &&
    (0, styled_components_1.css) `
      ${violetBackgroundGradient}

      &:hover:not(:disabled) {
        ${violetHoverGradient}
      }
    `}
`;
