"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_1 = require("@apollo/client");
const datadog_1 = require("uni/lib/logger/datadog");
const OPS_TO_LOG = [
    // feature related
    'markTodoAsUnread',
    'markTodoAsRead',
    // performance / big query logging
    'getTopicById',
    'getMainTopicList',
    'getProjectTopicList',
    'getCompletedTopicList',
    'getUnreadTopics',
];
const performanceTrackingLink = new client_1.ApolloLink((operation, forward) => {
    const startTime = new Date().getTime();
    return forward
        ? forward(operation).map((result) => {
            const operationDefinition = operation.query.definitions.find((def) => def.kind === 'OperationDefinition');
            if (operationDefinition &&
                // 💩 TS in the code editor recognizes the type, but, for some reason,
                // throws during the build, thinking this can be any type of DefinitionNode
                // which is why we add this check
                'operation' in operationDefinition &&
                operationDefinition.operation === 'subscription') {
                // skip subscriptions because their elapsed time is from the initialization of the subscription
                // which is not useful for performance tracking
                return result;
            }
            const operationName = operation.operationName;
            const ellapsedMs = new Date().getTime() - startTime;
            if (operationName && OPS_TO_LOG.includes(operationName)) {
                (0, datadog_1.logToDatadog)('graphql.operation', { operationName }, datadog_1.LogLevel.DEBUG);
            }
            if (ellapsedMs > 3000) {
                (0, datadog_1.logToDatadog)('graphql.performance', { operationName, ellapsedMs }, datadog_1.LogLevel.ERROR);
            }
            else if (ellapsedMs > 1500) {
                (0, datadog_1.logToDatadog)('graphql.performance', { operationName, ellapsedMs }, datadog_1.LogLevel.WARN);
            }
            return result;
        })
        : null;
});
exports.default = performanceTrackingLink;
