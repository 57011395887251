"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionType = void 0;
var SectionType;
(function (SectionType) {
    SectionType[SectionType["Infos"] = 0] = "Infos";
    SectionType[SectionType["Action"] = 1] = "Action";
    SectionType[SectionType["OutgoingMessages"] = 2] = "OutgoingMessages";
    SectionType[SectionType["IncomingMessages"] = 3] = "IncomingMessages";
})(SectionType = exports.SectionType || (exports.SectionType = {}));
