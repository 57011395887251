"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const containHoldAtDt = (time, selectedHoldGroup) => selectedHoldGroup.holds.some((h) => h.start.toMillis() === time.toMillis());
const isSlotAvailableAt = (start, selectedHoldGroup) => {
    if (start.diffNow().valueOf() < 0) {
        return false;
    }
    if (selectedHoldGroup && containHoldAtDt(start, selectedHoldGroup)) {
        return false;
    }
    return true;
};
exports.default = isSlotAvailableAt;
