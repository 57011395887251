"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const useIsStepCompleted = (stepId) => {
    const { data, loading } = (0, generated_1.useGetOnboardingStepsQuery)();
    const isStepCompleted = (0, react_1.useMemo)(() => {
        if (!data || !data.workspace.onboarding) {
            return false;
        }
        const step = data.workspace.onboarding.steps.find(({ id }) => id === stepId);
        if (step) {
            return step.isCompleted;
        }
        else {
            return false;
        }
    }, [data, stepId]);
    return { isStepCompleted, loading };
};
exports.default = useIsStepCompleted;
