"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronRightIcon = exports.ChevronLeftIcon = exports.Button = exports.Left = exports.Toolbar = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const reset_1 = require("uni/style/reset");
const text_1 = require("uni/style/text");
const chevron_left_s_svg_1 = require("uni/system/icons/chevron-left-s.svg");
const chevron_right_s_svg_1 = require("uni/system/icons/chevron-right-s.svg");
exports.Toolbar = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin: 8px 0 16px 24px;
`;
exports.Left = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
exports.Button = styled_components_1.default.button `
  ${reset_1.buttonReset}
  ${text_1.calloutRegular}
  padding: 0 10px;
  min-height: 28px;
  margin-right: 12px;
  border: 1px solid ${colors_1.gray3};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  ${({ disabled }) => disabled &&
    // Todo check that mix($grayColor, white, 50%) is equivalent to gray3
    `
      color: ${colors_1.gray3};
      cursor: default;
    `}
`;
const chevronStyle = (0, styled_components_1.css) `
  color: ${colors_1.gray6};
  cursor: pointer;
`;
exports.ChevronLeftIcon = (0, styled_components_1.default)(chevron_left_s_svg_1.ReactComponent) `
  ${chevronStyle}

  ${({ disabled }) => disabled &&
    `
  color: ${colors_1.gray4};
  cursor: default;
  `}
`;
exports.ChevronRightIcon = (0, styled_components_1.default)(chevron_right_s_svg_1.ReactComponent) `
  ${chevronStyle}
`;
