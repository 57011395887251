"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useCallIfStillMounted = () => {
    const mountedRef = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);
    return (cb) => {
        if (mountedRef.current) {
            cb();
        }
    };
};
exports.default = useCallIfStillMounted;
