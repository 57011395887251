"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDossierInput = exports.toDossierValueInput = void 0;
const generated_1 = require("../../generated");
const toDossierValueInput = (value) => {
    var _a, _b, _c, _d, _e, _f;
    switch (value.__typename) {
        case 'DossierValueEmail':
            return {
                email: value.email,
            };
        case 'DossierValueAnniversaryDate':
            const { day, month, year } = value.anniversaryDate;
            return { anniversaryDate: { day, month, year } };
        case 'DossierValuePlace':
            return {
                place: {
                    name: value.place.name,
                    address: {
                        line1: (_a = value.place.address) === null || _a === void 0 ? void 0 : _a.line1,
                        line2: (_b = value.place.address) === null || _b === void 0 ? void 0 : _b.line2,
                        locality: (_c = value.place.address) === null || _c === void 0 ? void 0 : _c.locality,
                        postalCode: (_d = value.place.address) === null || _d === void 0 ? void 0 : _d.postalCode,
                        regionShort: (_e = value.place.address) === null || _e === void 0 ? void 0 : _e.regionShort,
                        countryCode: (_f = value.place.address) === null || _f === void 0 ? void 0 : _f.countryCode,
                    },
                },
            };
        case 'DossierValuePhoneNumber':
            return {
                phoneNumber: value.phoneNumber,
            };
        case 'DossierValueText':
            return {
                text: value.text,
            };
        default:
            return null;
    }
};
exports.toDossierValueInput = toDossierValueInput;
const toDossierInput = (input) => {
    if (!input)
        return null;
    switch (input.type) {
        case generated_1.DossierInputableType.EMAIL:
            return {
                __typename: 'DossierInputableEmail',
                maxValues: 1,
            };
        case generated_1.DossierInputableType.PHONE_NUMBER:
            return {
                __typename: 'DossierInputablePhoneNumber',
                maxValues: 1,
            };
        case generated_1.DossierInputableType.ANNIVERSARY_DATE:
            return {
                __typename: 'DossierInputableAnniversaryDate',
                maxValues: 1,
            };
        case generated_1.DossierInputableType.PLACE:
            return {
                __typename: 'DossierInputablePlace',
                maxValues: 1,
            };
        default:
            return null;
    }
};
exports.toDossierInput = toDossierInput;
