"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const toDateString = (date, { weekday = true, friendly = true, short = false, shortNextWeek = false, shortPrevWeek = false, }) => {
    const now = luxon_1.DateTime.local();
    if (friendly) {
        if (date.hasSame(now, 'day')) {
            return 'Today';
        }
        else if (date.hasSame(now.plus({ days: -1 }), 'day')) {
            return 'Yesterday';
        }
        else if (date.hasSame(now.plus({ days: +1 }), 'day')) {
            return 'Tomorrow';
        }
    }
    const daysFromDate = date.diffNow('days').days;
    const isInPrev7Days = daysFromDate < 0 && daysFromDate > -7;
    if (shortPrevWeek && isInPrev7Days) {
        return date.toLocaleString({
            weekday: short ? 'short' : 'long',
        });
    }
    const isInNext7Days = daysFromDate > 0 && daysFromDate < 7;
    if (shortNextWeek && isInNext7Days) {
        return date.toLocaleString({
            weekday: short ? 'short' : 'long',
        });
    }
    return date.toLocaleString({
        weekday: weekday ? (short ? 'short' : 'long') : undefined,
        day: 'numeric',
        month: short ? 'short' : 'long',
        year: date.hasSame(now, 'year') ? undefined : 'numeric',
    });
};
const toAbsoluteString = (date, options) => {
    options = Object.assign({ shortToday: true, time: true }, options);
    if (options.shortToday && date.hasSame(luxon_1.DateTime.local(), 'day')) {
        return date.toLocaleString(luxon_1.DateTime.TIME_SIMPLE);
    }
    else if (!options.time) {
        return toDateString(date, options);
    }
    else {
        return `${toDateString(date, options)} ${date.toLocaleString(luxon_1.DateTime.TIME_SIMPLE)}`;
    }
};
exports.default = toAbsoluteString;
