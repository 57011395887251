"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const DEFAULT_TIMEOUT = 3000;
const useDelayedEffect = (callback, { condition = true, timeout = DEFAULT_TIMEOUT }) => {
    (0, react_1.useEffect)(() => {
        if (condition) {
            const timeoutRef = setTimeout(callback, timeout);
            return () => clearTimeout(timeoutRef);
        }
    }, [condition, timeout, callback]);
};
exports.default = useDelayedEffect;
