"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortFromOption = void 0;
const luxon_1 = require("luxon");
const types_1 = require("./types");
const pushNullHoursNeededRangeToEnd = (next) => (a, b) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return ((_b = (_a = a.profiles[0]) === null || _a === void 0 ? void 0 : _a.hoursNeededRange) === null || _b === void 0 ? void 0 : _b.from) === undefined ||
        ((_d = (_c = a.profiles[0]) === null || _c === void 0 ? void 0 : _c.hoursNeededRange) === null || _d === void 0 ? void 0 : _d.from) === null
        ? 1
        : ((_f = (_e = b.profiles[0]) === null || _e === void 0 ? void 0 : _e.hoursNeededRange) === null || _f === void 0 ? void 0 : _f.from) === undefined ||
            ((_h = (_g = b.profiles[0]) === null || _g === void 0 ? void 0 : _g.hoursNeededRange) === null || _h === void 0 ? void 0 : _h.from) === null
            ? -1
            : next(a, b);
};
// TODO sorting by recommended: waiting on API from backend
// const sortByRecommended: SortFunction =
const sortByMostRecent = (a, b) => {
    const aCreatedAt = luxon_1.DateTime.fromISO(a.createdAt);
    const bCreatedAt = luxon_1.DateTime.fromISO(b.createdAt);
    const duration = bCreatedAt.diff(aCreatedAt).toObject().milliseconds;
    return duration !== null && duration !== void 0 ? duration : 0;
};
const sortByHoursLowHigh = (a, b) => {
    var _a, _b, _c, _d;
    return (((_b = (_a = a.profiles[0]) === null || _a === void 0 ? void 0 : _a.hoursNeededRange) === null || _b === void 0 ? void 0 : _b.from) || 0) -
        (((_d = (_c = b.profiles[0]) === null || _c === void 0 ? void 0 : _c.hoursNeededRange) === null || _d === void 0 ? void 0 : _d.from) || 0);
};
const sortByHoursHighLow = (a, b) => -sortByHoursLowHigh(a, b);
const sortFromOption = (sortBy) => {
    switch (sortBy) {
        // TODO sorting by recommended: waiting on API from backend
        // case SortByOption.RECOMMENDED:
        //   return sortByRecommended
        case types_1.SortByOption.MOST_RECENT:
            return sortByMostRecent;
        case types_1.SortByOption.HOURS_LOW_HIGH:
            return pushNullHoursNeededRangeToEnd(sortByHoursLowHigh);
        case types_1.SortByOption.HOURS_HIGH_LOW:
            return pushNullHoursNeededRangeToEnd(sortByHoursHighLow);
    }
};
exports.sortFromOption = sortFromOption;
