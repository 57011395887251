"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const ROUTES_1 = require("uni/components/adhoc/ROUTES");
const toEmailMessage = ({ executiveGivenName, assistantGivenName, assistantFormattedAddress, taskTitle, taskId, goodFitForTaskText, howWillYouGetStartedText, dueDate, }) => `
Hi ${executiveGivenName},
  
I’m ${assistantGivenName}, a Double assistant ${!!assistantFormattedAddress ? `in ${assistantFormattedAddress}` : ''}.
I’m getting started on your task, so here’s what you can expect:

**Your request:** ${taskTitle} ([link to task](https://app.withdouble.com${ROUTES_1.POOL_PATH_PREFIX}/${taskId}))

**What makes me a great fit for this:**
${goodFitForTaskText}

**How I’m getting started:**
${howWillYouGetStartedText}

**I plan to have an update for you by:**
${dueDate.toLocaleString(luxon_1.DateTime.DATE_FULL)}

As I dive in, feel free to hit reply if you have any questions.
Otherwise, nothing for you to do - will write back soon!

${assistantGivenName},
Executive Assistant at Double
`;
exports.default = toEmailMessage;
