"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkDoneButton = exports.CheckmarkIcon = exports.AppText = exports.AppHeader = exports.AppTitle = exports.AppIcon = exports.AppBox = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const buttons_1 = require("uni/style/buttons");
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const checkmark_xs_svg_1 = require("uni/system/icons/checkmark-xs.svg");
const styled_1 = require("../../styled");
exports.AppBox = styled_components_1.default.div `
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
  align-items: stretch;

  border: 1px solid ${colors_1.gray3};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 16px;

  ${({ filled }) => !!filled &&
    (0, styled_components_1.css) `
      border-color: ${colors_1.gray1};
      background-color: ${colors_1.gray1};
    `}
`;
exports.AppIcon = styled_components_1.default.div `
  width: 32px;
  height: 32px;
  border-radius: 32px;
  flex: 0 0 auto;
  margin-right: 12px;

  img {
    width: 100%;
    height: 100%;
  }
`;
exports.AppTitle = styled_components_1.default.h2 `
  ${text_1.titleMedium}
  margin: 0;
  flex: 1;
`;
exports.AppHeader = styled_components_1.default.div `
  display: flex;
  align-items: center;
`;
exports.AppText = (0, styled_components_1.default)(styled_1.Body) `
  text-align: left;
  margin: 10px 0 12px;
  padding-left: 44px;
`;
exports.CheckmarkIcon = (0, styled_components_1.default)(checkmark_xs_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
  border: 1px solid ${colors_1.gray3};
  box-sizing: border-box;
  border-radius: 40px;
  height: 36px;
  width: 36px;
  padding: 5px;
`;
exports.MarkDoneButton = styled_components_1.default.button `
  ${buttons_1.mediumButtonSecondary}
`;
