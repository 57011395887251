"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronRightIcon = exports.ChevronDownIcon = exports.Action = exports.UnreadLabel = exports.Label = exports.Content = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const chevron_down_filled_xs_svg_1 = require("uni/system/icons/chevron-down-filled-xs.svg");
const chevron_right_filled_xs_svg_1 = require("uni/system/icons/chevron-right-filled-xs.svg");
const styled_1 = require("../styled");
exports.Outer = styled_components_1.default.div `
  ${styled_1.sharedSectionHeaderOuter}

  cursor: pointer;

  ${({ $isCollapsed }) => $isCollapsed &&
    (0, styled_components_1.css) `
      .label {
        border-bottom: none;
      }
    `}
`;
exports.Content = styled_components_1.default.div `
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 18px;
  flex: 1;
`;
exports.Label = styled_components_1.default.span `
  ${text_1.titleBold}
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 10px;
`;
exports.UnreadLabel = styled_components_1.default.span `
  ${text_1.bodySemibold}
  flex: 1 1 auto;
  color: ${colors_1.purple5};
`;
exports.Action = styled_components_1.default.span `
  ${text_1.calloutRegular}
  color: ${colors_1.gray6};
  cursor: pointer;
  padding-right: 12px;

  ${({ $isActive }) => $isActive &&
    (0, styled_components_1.css) `
      ${text_1.bodySemibold}
      color: ${colors_1.purple5};
    `}
`;
const Chevron = styled_components_1.default.div `
  color: ${colors_1.gray5};
  user-select: none;
`;
exports.ChevronDownIcon = (0, styled_components_1.default)(chevron_down_filled_xs_svg_1.ReactComponent) `
  ${Chevron}
`;
exports.ChevronRightIcon = (0, styled_components_1.default)(chevron_right_filled_xs_svg_1.ReactComponent) `
  ${Chevron}
`;
