"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronDownIcon = exports.Input = exports.InputOuter = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const reset_1 = require("uni/style/reset");
const text_1 = require("uni/style/text");
const chevron_down_16px_svg_1 = require("uni/system/icons/chevron-down-16px.svg");
exports.Outer = styled_components_1.default.div `
  overflow: hidden;
`;
exports.InputOuter = styled_components_1.default.div `
  border-radius: 8px;
  display: flex;
  cursor: text;

  & > * {
    ${reset_1.inputReset};
    ${text_1.bodyRegular};
    padding: 3px 9px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    color: ${colors_1.gray6};
  }

  background-color: ${colors_1.white};
  border: 0.5px solid ${colors_1.gray2};
  width: 132px;

  ${({ grayed }) => grayed &&
    (0, styled_components_1.css) `
      background-color: ${colors_1.gray1};
      border-color: transparent;
      width: auto;
    `}

  ${({ disabled }) => disabled &&
    (0, styled_components_1.css) `
      border-color: ${colors_1.gray4};
    `}
`;
exports.Input = styled_components_1.default.input `
  ${({ disabled }) => disabled && 'display: none;'}

  &::placeholder {
    color: ${colors_1.gray5};
  }
`;
exports.ChevronDownIcon = (0, styled_components_1.default)(chevron_down_16px_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
`;
