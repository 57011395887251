"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useLocalStorage = ({ key, initialValue, }) => {
    const [valueState, setValueState] = (0, react_1.useState)(() => {
        const storedValue = window.localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });
    const setValue = (0, react_1.useCallback)((value) => {
        window.localStorage.setItem(key, JSON.stringify(value));
        setValueState(value);
    }, [key]);
    const clearValue = (0, react_1.useCallback)(() => {
        window.localStorage.removeItem(key);
    }, [key]);
    return [valueState, setValue, clearValue];
};
exports.default = useLocalStorage;
