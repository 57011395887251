"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const generated_1 = require("../hooks/generated");
// Very much inspired from double.native's
// https://github.com/withDouble/double.native/blob/master/Features/Topics/Sources/GraphQL/Extensions/TodoInfo+Sugar.swift#L21
const getTodoStatus = ({ recurrence, completedAt, unsafeStatus, overrideStatus, }) => {
    if (overrideStatus) {
        return overrideStatus;
    }
    if (!recurrence || !completedAt) {
        return unsafeStatus;
    }
    const completedAtDateTime = luxon_1.DateTime.fromISO(completedAt);
    if (unsafeStatus === generated_1.TodoStatus.DONE) {
        return recurrence.previousRecurrenceDateTime &&
            completedAtDateTime >= recurrence.previousRecurrenceDateTime
            ? // Show ready status if the recurring todo is outstanding, otherwise set it as snoozed
                generated_1.TodoStatus.SNOOZED
            : generated_1.TodoStatus.READY;
    }
    else {
        return unsafeStatus;
    }
};
exports.default = getTodoStatus;
