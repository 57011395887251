"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LEGACY_COMPLETED_ADHOC_PATH_PREFIX = exports.ADHOC_PATH = exports.ADHOC_PATH_PREFIX = exports.COMPLETED = exports.IN_PROGRESS = exports.FINDING_EA = exports.CHECKOUT_FAILED = exports.CHECKOUT = exports.CLOSED = exports.EMAIL = exports.CONTACT = exports.REVIEW_BRIEF = exports.FOLLOW_UP_QUESTIONS = exports.CREATE = exports.POOL_PATH = exports.POOL_PATH_PREFIX = exports.ADHOC_ID = void 0;
exports.ADHOC_ID = ':id';
exports.POOL_PATH_PREFIX = '/pool';
exports.POOL_PATH = `${exports.POOL_PATH_PREFIX}/${exports.ADHOC_ID}`;
// Double Pool
exports.CREATE = `${exports.POOL_PATH_PREFIX}/create`;
exports.FOLLOW_UP_QUESTIONS = `${exports.POOL_PATH_PREFIX}/follow-up-questions/${exports.ADHOC_ID}`;
exports.REVIEW_BRIEF = `${exports.POOL_PATH_PREFIX}/review-brief/${exports.ADHOC_ID}`;
exports.CONTACT = `${exports.POOL_PATH_PREFIX}/contact/${exports.ADHOC_ID}`;
exports.EMAIL = `${exports.POOL_PATH_PREFIX}/contact/email/${exports.ADHOC_ID}`;
// Double Pool and Double GPT
exports.CLOSED = `${exports.POOL_PATH_PREFIX}/closed/${exports.ADHOC_ID}`;
exports.CHECKOUT = `${exports.POOL_PATH_PREFIX}/checkout/${exports.ADHOC_ID}`;
exports.CHECKOUT_FAILED = `${exports.POOL_PATH_PREFIX}/checkout-error/${exports.ADHOC_ID}`;
exports.FINDING_EA = `${exports.POOL_PATH_PREFIX}/finding-ea/${exports.ADHOC_ID}`;
exports.IN_PROGRESS = `${exports.POOL_PATH_PREFIX}/in-progress/${exports.ADHOC_ID}`;
exports.COMPLETED = `${exports.POOL_PATH_PREFIX}/completed/${exports.ADHOC_ID}`;
// Legacy Routes
exports.ADHOC_PATH_PREFIX = '/adhoc';
exports.ADHOC_PATH = `${exports.ADHOC_PATH_PREFIX}/${exports.ADHOC_ID}`;
exports.LEGACY_COMPLETED_ADHOC_PATH_PREFIX = `${exports.ADHOC_PATH_PREFIX}/completed/${exports.ADHOC_ID}`;
