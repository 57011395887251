"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const vars_1 = require("./vars");
const resolvers = {
    Query: {
        fields: {
            assistantReportingIsRead: {
                read: () => {
                    const today = luxon_1.DateTime.fromJSDate(new Date());
                    const value = (0, vars_1.assistantReportingLastSeen)();
                    return value
                        ? luxon_1.DateTime.fromISO(value) > today.startOf('week').set({ hour: 11 })
                        : false;
                },
            },
        },
    },
};
exports.default = resolvers;
