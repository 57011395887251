"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const metrics_1 = require("uni/lib/metrics");
const types_1 = require("uni/lib/metrics/types");
const generated_1 = require("./generated");
const useBookmarkMatching = () => {
    const [mutate, result] = (0, generated_1.useBookmarkMatchingMutation)();
    const execute = (0, react_1.useCallback)((matchingId, isBookmarked) => __awaiter(void 0, void 0, void 0, function* () {
        metrics_1.metrics.track(types_1.TrackingEvent.EXECUTIVE_BOOKMARKED, {
            name: 'matching-catalog',
            leadId: matchingId,
            matchingId,
            bookmarked: isBookmarked,
        });
        const optimisticResponse = {
            __typename: 'Mutation',
            bookmarkMatching: {
                __typename: 'BookmarkMatchingOutput',
                matching: {
                    __typename: 'Matching',
                    id: matchingId,
                    isBookmarked,
                },
            },
        };
        return yield mutate({
            variables: {
                input: {
                    matchingId,
                    isBookmarked,
                },
            },
            optimisticResponse,
        });
    }), [mutate]);
    return (0, react_1.useMemo)(() => [execute, result], [execute, result]);
};
exports.default = useBookmarkMatching;
