"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidAdditionalHoursRanges = void 0;
const MAX = 40;
const INTERVAL = 5;
exports.ValidAdditionalHoursRanges = Array.from(Array(MAX / INTERVAL).keys()).map((i) => {
    return {
        from: INTERVAL * i,
        to: INTERVAL * (i + 1),
    };
});
