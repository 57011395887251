"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sharedSectionHeaderOuter = void 0;
const styled_components_1 = require("styled-components");
const devices_1 = require("uni/style/devices");
exports.sharedSectionHeaderOuter = (0, styled_components_1.css) `
  height: 40px;
  border-radius: 8px;
  margin: 32px auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(0, devices_1.screenM)((0, styled_components_1.css) `
    margin: 16px auto 0 auto;
  `)}
`;
