"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const INIT_LOADING_STATE = {
    loading: true,
    error: null,
    value: null,
};
const usePromise = (promise) => {
    const [state, setState] = (0, react_1.useState)(INIT_LOADING_STATE);
    // Use useMemo so that it's triggered BEFORE renders (≠ useEffect)
    (0, react_1.useMemo)(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // Reinitialize state when promise changes
                setState(INIT_LOADING_STATE);
                const value = yield promise;
                setState({
                    loading: false,
                    error: null,
                    value,
                });
            }
            catch (error) {
                setState({
                    loading: false,
                    error: (error || null),
                    value: null,
                });
            }
        }))();
    }, [promise]);
    return state;
};
exports.default = usePromise;
