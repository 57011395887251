"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const ClientsManager_1 = require("uni/components/apollo/ClientsManager");
const generated_1 = require("uni/graphql/hooks/generated");
const datadog_1 = require("uni/lib/logger/datadog");
const useWipeout = () => {
    const { getNonWorkspacedApolloClient } = (0, ClientsManager_1.useClientsManager)();
    const [logout] = (0, generated_1.useLogoutMutation)({
        // Use main apollo client
        client: getNonWorkspacedApolloClient(),
        // And refresh logged-in status
        refetchQueries: [generated_1.namedOperations.Query.isLoggedIn],
    });
    return (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield logout();
            // Let's see if we do not reload, what happens
            // LIVING ON THE EDGE
            // window.location.reload()
        }
        catch (error) {
            (0, datadog_1.logErrorToDatadog)('useWipeout')(error);
        }
    }), [logout]);
};
exports.default = useWipeout;
