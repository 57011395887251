"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const trash_s_svg_1 = require("uni/system/icons/trash-s.svg");
const styled_1 = require("./styled");
const ImageInput = ({ initialURL, onFileChange: onFileChangeProp, onReset: onResetProp, }) => {
    const [urlState, setUrlState] = (0, react_1.useState)(initialURL);
    const onFileChange = (0, react_1.useCallback)((e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            if (file.size > 5 * 1024 * 1024) {
                alert('File size should be less than 5MB');
                return;
            }
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width < 100 || img.height < 100) {
                    alert('Image should be at least 100px');
                    return;
                }
            };
            setUrlState(img.src);
            onFileChangeProp(file);
        }
    }, [onFileChangeProp]);
    const onReset = (0, react_1.useCallback)(() => {
        onResetProp();
        setUrlState(initialURL);
    }, [initialURL, onResetProp]);
    return (react_1.default.createElement(styled_1.Outer, null,
        react_1.default.createElement(styled_1.MainImageContainer, null,
            urlState ? react_1.default.createElement(styled_1.IconButton, { icon: trash_s_svg_1.ReactComponent, onClick: onReset }) : null,
            react_1.default.createElement(styled_1.ImageContainer, { "$hasImage": !!urlState },
                react_1.default.createElement("input", { 
                    // Use a unique key to reset the input when the url changes
                    // This is needed to allow the user to upload the same image again
                    key: urlState, type: 'file', accept: 'image/*', onChange: onFileChange }),
                urlState ? react_1.default.createElement(styled_1.UploadedImage, { src: urlState }) : react_1.default.createElement(styled_1.UploadIcon, null))),
        react_1.default.createElement(styled_1.Text, { "$hidden": !!urlState }, 'Upload Image')));
};
exports.default = ImageInput;
