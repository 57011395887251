"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("./generated");
const useUnreadTopicsCount = () => {
    const { data: unreadTopicsCountsData } = (0, generated_1.useGetUnreadTopicsCountsQuery)({
        fetchPolicy: 'cache-first',
    });
    return (0, react_1.useMemo)(() => unreadTopicsCountsData
        ? (+unreadTopicsCountsData.workspaceById.unreadTopicsCounts.silent ||
            0) +
            (+unreadTopicsCountsData.workspaceById.unreadTopicsCounts.loud || 0)
        : 0, [unreadTopicsCountsData]);
};
exports.default = useUnreadTopicsCount;
