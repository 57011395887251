"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_1 = require("react-router");
const ROUTES_1 = require("uni/components/ROUTES");
const useIsPoolWorkspace_1 = require("./workspace/useIsPoolWorkspace");
const useNavigateToWorkspace = () => {
    const history = (0, react_router_1.useHistory)();
    return (0, react_1.useCallback)(({ workspaceId, assistantModel, }) => {
        if (workspaceId) {
            history.push(`${(0, useIsPoolWorkspace_1.isPoolWorkspace)({ assistantModel })
                ? ROUTES_1.POOL_WORKSPACES_ROUTE
                : ROUTES_1.MATCHED_WORKSPACES_ROUTE}/${workspaceId}`);
        }
        else {
            history.push(`/`);
        }
    }, [history]);
};
exports.default = useNavigateToWorkspace;
