"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharWarningLevel = void 0;
const react_1 = __importStar(require("react"));
const Tooltip_1 = __importStar(require("uni/system/Tooltip"));
const styled_1 = require("./styled");
var CharWarningLevel;
(function (CharWarningLevel) {
    CharWarningLevel[CharWarningLevel["NONE"] = 0] = "NONE";
    CharWarningLevel[CharWarningLevel["SOFT"] = 1] = "SOFT";
    CharWarningLevel[CharWarningLevel["HARD"] = 2] = "HARD";
})(CharWarningLevel = exports.CharWarningLevel || (exports.CharWarningLevel = {}));
const CharacterCount = react_1.default.forwardRef((props, ref) => {
    const { hardText = 'This text is too long', softText = 'This text is a bit long', textLength, softLimit, className, hardLimit, showTooltip = false, style, show = true, } = props;
    const warningLevel = (0, react_1.useMemo)(() => textLength >= hardLimit
        ? CharWarningLevel.HARD
        : softLimit && textLength >= softLimit
            ? CharWarningLevel.SOFT
            : CharWarningLevel.NONE, [hardLimit, softLimit, textLength]);
    const tooltipText = (0, react_1.useMemo)(() => warningLevel === CharWarningLevel.HARD
        ? hardText
        : warningLevel === CharWarningLevel.SOFT
            ? softText
            : '', [warningLevel, hardText, softText]);
    if (!show) {
        return null;
    }
    return (react_1.default.createElement(styled_1.Outer, { className: className, style: style, ref: ref },
        react_1.default.createElement(styled_1.CharCount, { "$level": warningLevel }, `${textLength}/${hardLimit}`),
        tooltipText && (react_1.default.createElement(Tooltip_1.default, { text: tooltipText, show: showTooltip, position: Tooltip_1.TooltipPosition.POSITION_BOTTOM_LEFT }))));
});
exports.default = CharacterCount;
