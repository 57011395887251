"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const types_1 = require("./utils/types");
const CopilotContext = (0, react_1.createContext)({
    scheduleTriggerCopilot: () => {
        // Do not throw an error here as it may be used in contexts where Copilot is
        // not proivded
    },
    holdTrigger: () => {
        throw new Error('CopilotContext not implemented');
    },
    resumeTrigger: () => {
        throw new Error('CopilotContext not implemented');
    },
    loadingIndicatorStatus: types_1.LoadingIndicatorStatus.HIDDEN,
});
exports.default = CopilotContext;
