"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const datadog_1 = require("uni/lib/logger/datadog");
const presentNotification = ({ title, message, body, emoji, icon, image }, onClick = () => { }) => {
    if (!Notification) {
        return;
    }
    // 🛡️ Shouldn't be necessary, but just in case
    if (Notification.permission !== 'granted') {
        return;
    }
    if (!message && body) {
        message = body;
    }
    if (emoji) {
        message = `${emoji} ${message}`;
    }
    // Options: https://web-push-book.gauntface.com/demos/notification-examples/
    const n = new Notification(title || message || '', {
        body: title ? message : undefined,
        icon,
        image,
    });
    n.onerror = (error) => (0, datadog_1.logErrorToDatadog)('notification.failed')(error);
    n.onclick = () => {
        n.close();
        onClick();
    };
};
exports.default = presentNotification;
