"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const types_1 = require("../utils/types");
// Only to be used by Copilot Providers
const useLoadingIndicatorStatus = ({ enabledState, isTriggerCopilotEnabled, isTriggerHeld, scheduleTrigger, }) => (0, react_1.useMemo)(() => {
    if (!isTriggerCopilotEnabled) {
        return types_1.LoadingIndicatorStatus.HIDDEN;
    }
    if (scheduleTrigger) {
        return types_1.LoadingIndicatorStatus.ANIMATED;
    }
    if (isTriggerHeld && enabledState) {
        return types_1.LoadingIndicatorStatus.HOLD;
    }
    return types_1.LoadingIndicatorStatus.HIDDEN;
}, [enabledState, isTriggerCopilotEnabled, isTriggerHeld, scheduleTrigger]);
exports.default = useLoadingIndicatorStatus;
