"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DollarIcon = exports.CalendarIcon = exports.TokenIcon = exports.AvatarCircleIcon = exports.FooterBigButton = exports.HeaderBigButton = exports.Footer = exports.MarkdownOuter = exports.DetailSectionHeader = exports.ScrollableContent = exports.InfoItem = exports.Separator = exports.SubtitleRow = exports.Subtitle = exports.Title = exports.Header = exports.DetailOuter = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const buttons_1 = require("uni/style/buttons");
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const avatar_circle_xs_svg_1 = require("uni/system/icons/avatar-circle-xs.svg");
const calendar_xs_svg_1 = require("uni/system/icons/calendar-xs.svg");
const dollar_xs_svg_1 = require("uni/system/icons/dollar-xs.svg");
const token_xs_svg_1 = require("uni/system/icons/token-xs.svg");
exports.Outer = styled_components_1.default.div `
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
exports.DetailOuter = styled_components_1.default.div `
  flex: 1;
  overflow: hidden;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  padding: 40px 0px 0px;
  & > * {
    padding: 0 24px;
  }

  & > :last-child {
    padding-bottom: 40px;
  }
`;
exports.Header = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
exports.Title = styled_components_1.default.span `
  margin-top: 24px;
  ${text_1.titleBold};
  color: ${colors_1.black};
`;
exports.Subtitle = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
  gap: 7px;
`;
exports.SubtitleRow = styled_components_1.default.div `
  display: flex;
  align-items: center;
  gap: 24px;
`;
exports.Separator = styled_components_1.default.div `
  margin-top: 32px;
  align-self: stretch;
  height: 1px;
  background-color: ${colors_1.gray2};
  padding: 0;
`;
exports.InfoItem = styled_components_1.default.div `
  display: flex;
  gap: 3px;
  align-items: center;
`;
exports.ScrollableContent = styled_components_1.default.div `
  display: flex;
  align-items: flex-start;
  overflow: auto;
  flex-direction: column;
`;
exports.DetailSectionHeader = styled_components_1.default.span `
  margin-top: 32px;
  ${text_1.bodyMedium};
  color: ${colors_1.gray6};
`;
exports.MarkdownOuter = styled_components_1.default.div `
  ${text_1.bodyRegular};
`;
exports.Footer = styled_components_1.default.div `
  cursor: pointer;
  align-self: stretch;

  border-top: 1px solid ${colors_1.gray2};
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  ${text_1.bodyRegular};
  color: ${colors_1.purple5};
`;
exports.HeaderBigButton = styled_components_1.default.button `
  margin-top: 24px;
  ${buttons_1.mediumButtonPrimary}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
exports.FooterBigButton = styled_components_1.default.button `
  margin-top: 32px;
  ${buttons_1.mediumButtonPrimary}
`;
const subtitleIconStyle = (0, styled_components_1.css) `
  color: ${colors_1.gray6};
`;
exports.AvatarCircleIcon = (0, styled_components_1.default)(avatar_circle_xs_svg_1.ReactComponent) `
  ${subtitleIconStyle}
`;
exports.TokenIcon = (0, styled_components_1.default)(token_xs_svg_1.ReactComponent) `
  ${subtitleIconStyle}
`;
exports.CalendarIcon = (0, styled_components_1.default)(calendar_xs_svg_1.ReactComponent) `
  ${subtitleIconStyle}
`;
exports.DollarIcon = (0, styled_components_1.default)(dollar_xs_svg_1.ReactComponent) `
  ${subtitleIconStyle}
`;
