"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StopButton = exports.PauseButton = exports.TimerButton = exports.ManualEntryInfo = exports.TimerButtonText = exports.RightGroup = exports.LeftGroup = exports.Inner = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const timerButtons_1 = require("../../timerButtons");
exports.Outer = styled_components_1.default.div `
  border-top: 1px solid ${colors_1.gray2};
  padding: 12px 16px;
  background-color: ${({ grayed }) => (grayed ? colors_1.gray1 : colors_1.white)};

  // 💩 To avoid overflowing, MainEntryForm define these border radius but is not
  // overflow: hidden for the Tooltips to be visible
  // Will be fixed with https://double.height.app/T-4964
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
`;
exports.Inner = styled_components_1.default.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;

  position: relative;
`;
exports.LeftGroup = styled_components_1.default.div `
  ${text_1.bodyMedium};
  color: ${colors_1.gray6};
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
exports.RightGroup = styled_components_1.default.div `
  display: flex;
  column-gap: 6px;
`;
exports.TimerButtonText = styled_components_1.default.span `
  margin-left: 4px;
`;
exports.ManualEntryInfo = styled_components_1.default.span `
  ${text_1.calloutMedium}
  color: ${colors_1.gray6};

  flex: 1;

  cursor: pointer;

  text-align: left;
  user-select: none;
`;
const buttonStyle = (0, styled_components_1.css) `
  padding-left: 3px;
`;
exports.TimerButton = (0, styled_components_1.default)(timerButtons_1.TimerButton) `
  ${buttonStyle}
`;
exports.PauseButton = (0, styled_components_1.default)(timerButtons_1.PauseButton) `
  ${buttonStyle}
`;
exports.StopButton = (0, styled_components_1.default)(timerButtons_1.StopButton) `
  ${buttonStyle}
`;
