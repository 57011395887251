"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
const HOTJAR_ID_SCRIPT_TAG_ID = 'hotjar-pool-script';
const useInitHotjarTrackingEffect = () => {
    (0, react_1.useEffect)(() => {
        if (document.getElementById(HOTJAR_ID_SCRIPT_TAG_ID))
            return;
        const script = document.createElement('script');
        script.id = HOTJAR_ID_SCRIPT_TAG_ID;
        script.innerHTML = `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(script);
        return () => {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
            if (window.hj) {
                window.hj = undefined;
            }
            if (window._hjSettings) {
                window._hjSettings = undefined;
            }
        };
    }, []);
};
exports.default = useInitHotjarTrackingEffect;
