"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const WEDNESDAY_WEEK_DAY = 3;
// Digest is sent every Wednesday at 12pm EST
const getEmailDigestSendingDateTime = () => {
    const now = luxon_1.DateTime.now();
    const todayNoonEST = now
        .setZone('America/New_York')
        .set({ hour: 12, minute: 0, second: 0 });
    let nextWednesdayNoonUTC;
    if (todayNoonEST.weekday < WEDNESDAY_WEEK_DAY) {
        nextWednesdayNoonUTC = todayNoonEST.set({ weekday: WEDNESDAY_WEEK_DAY });
    }
    else if (todayNoonEST.weekday === WEDNESDAY_WEEK_DAY) {
        if (todayNoonEST < now) {
            nextWednesdayNoonUTC = todayNoonEST.set({
                weekNumber: todayNoonEST.weekNumber + 1,
            });
        }
        else {
            nextWednesdayNoonUTC = todayNoonEST;
        }
    }
    // WEDNESDAY_WEEK_DAY < todayNoonEST.weekday
    else {
        nextWednesdayNoonUTC = todayNoonEST.set({
            weekday: WEDNESDAY_WEEK_DAY,
            weekNumber: todayNoonEST.weekNumber + 1,
        });
    }
    return nextWednesdayNoonUTC.setZone(luxon_1.DateTime.local().zoneName);
};
exports.default = getEmailDigestSendingDateTime;
