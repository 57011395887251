"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const colors_1 = require("uni/style/colors");
const types_1 = require("../types");
const styled_1 = require("./styled");
const AvailabilityStatus = ({ availabilityStatus }) => {
    const { borderColor, textColor } = (0, react_1.useMemo)(() => {
        switch (availabilityStatus) {
            case types_1.AdhocTodoAvailabilityStatus.ASSIGNED_TO_SELF:
                return { borderColor: colors_1.green3, textColor: colors_1.green5 };
            case types_1.AdhocTodoAvailabilityStatus.AVAILABLE:
                return { borderColor: colors_1.blue3, textColor: colors_1.blue5 };
            case types_1.AdhocTodoAvailabilityStatus.COMPLETED_BY_SELF:
            case types_1.AdhocTodoAvailabilityStatus.NOT_AVAILABLE:
                return { borderColor: colors_1.gray3, textColor: colors_1.gray6 };
        }
    }, [availabilityStatus]);
    const label = (0, react_1.useMemo)(() => {
        switch (availabilityStatus) {
            case types_1.AdhocTodoAvailabilityStatus.ASSIGNED_TO_SELF:
                return 'ASSIGNED TO YOU';
            case types_1.AdhocTodoAvailabilityStatus.AVAILABLE:
                return 'AVAILABLE';
            case types_1.AdhocTodoAvailabilityStatus.COMPLETED_BY_SELF:
                return 'COMPLETED BY YOU';
            case types_1.AdhocTodoAvailabilityStatus.NOT_AVAILABLE:
                return 'NOT AVAILABLE';
        }
    }, [availabilityStatus]);
    return (react_1.default.createElement(styled_1.Outer, { "$borderColor": borderColor, "$textColor": textColor }, label));
};
exports.default = AvailabilityStatus;
