"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchPosition = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
exports.Outer = styled_components_1.default.div `
  cursor: pointer;

  margin: 14px 0 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 8px;

  background-color: ${colors_1.gray2};
`;
exports.SwitchPosition = styled_components_1.default.div `
  z-index: 10;

  ${({ $active }) => $active
    ? (0, styled_components_1.css) `
          background: ${colors_1.white};
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.06),
            0px 1px 2px 0px rgba(0, 0, 0, 0.15);
        `
    : ''}
  border-radius: 6px;
  margin: 2px;
`;
