"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeAdditionalHrsRange = exports.ADDITIONAL_HRS_OPTIONS = exports.formatAdditionalHrsForMatchingCatalog = void 0;
const types_1 = require("uni/components/MatchingCatalog/Detail/types");
const formatAdditionalHrsForMatchingCatalog = (from, to) => (to ? `${from}-${to} hours` : `${from}+ hours`);
exports.formatAdditionalHrsForMatchingCatalog = formatAdditionalHrsForMatchingCatalog;
exports.ADDITIONAL_HRS_OPTIONS = types_1.ValidAdditionalHoursRanges.map((additionalHrs) => {
    return {
        title: (0, exports.formatAdditionalHrsForMatchingCatalog)(additionalHrs.from, additionalHrs.to),
    };
});
const normalizeAdditionalHrsRange = (range) => {
    if (!range) {
        return { from: 0, to: 5 };
    }
    if (!range.to) {
        return {
            from: range.from,
        };
    }
    if (range.to >= 40) {
        return {
            from: 40,
        };
    }
    return {
        from: range.to - 5,
        to: range.to,
    };
};
exports.normalizeAdditionalHrsRange = normalizeAdditionalHrsRange;
