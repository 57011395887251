"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const useUpdateAdditionalHrs = () => {
    const [updateMe, { data, loading, error }] = (0, generated_1.useUpdateMeMutation)();
    const { data: assistantProfileData } = (0, generated_1.useGetAssistantProfileQuery)();
    const updateAdditionalHrs = (0, react_1.useCallback)((additionalHrs) => __awaiter(void 0, void 0, void 0, function* () {
        const input = {
            targetAdditionalWeeklyHours: additionalHrs,
        };
        return yield updateMe({
            variables: { input },
            optimisticResponse: (assistantProfileData === null || assistantProfileData === void 0 ? void 0 : assistantProfileData.me)
                ? {
                    __typename: 'Mutation',
                    updateMe: {
                        __typename: 'UpdateMeOutput',
                        me: Object.assign(Object.assign({}, assistantProfileData.me), input),
                    },
                }
                : undefined,
        });
    }), [assistantProfileData, updateMe]);
    return [updateAdditionalHrs, { data, loading, error }];
};
exports.default = useUpdateAdditionalHrs;
