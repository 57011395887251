"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupType = void 0;
var GroupType;
(function (GroupType) {
    GroupType["MATCHED"] = "Matched";
    GroupType["INTEREST_SUBMITTED"] = "Interest submitted";
    GroupType["NOT_APPLIED"] = "Not applied";
    GroupType["NO_MATCH"] = "Unavailable";
})(GroupType = exports.GroupType || (exports.GroupType = {}));
