"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const generated_1 = require("uni/graphql/hooks/generated");
const buttons_1 = require("uni/style/buttons");
const AdhocTodoModalProvider_1 = require("../../AdhocTodoModalProvider");
const styled_1 = require("./styled");
const PoolPendingActivity = ({ poolTodo, onSeeDetailsLinkClick }) => {
    const { adhocTodoId, status } = poolTodo;
    const isDraft = status === generated_1.TodoStatus.DRAFT;
    const { openAdhocTodoModal } = (0, react_1.useContext)(AdhocTodoModalProvider_1.AdhocTodoModalContext);
    const openModal = (0, react_1.useCallback)(() => {
        openAdhocTodoModal(adhocTodoId);
    }, [adhocTodoId, openAdhocTodoModal]);
    if (!(isDraft || status === 'READY'))
        return null;
    const hasOnSeeDetailsLinkClick = !!onSeeDetailsLinkClick;
    return (react_1.default.createElement(styled_1.Outer, null,
        react_1.default.createElement(styled_1.Profiles, null),
        react_1.default.createElement(styled_1.Inner, null,
            react_1.default.createElement(styled_1.Text, null,
                react_1.default.createElement(styled_1.Title, null, isDraft
                    ? 'Ready to delegate to a professional EA?'
                    : 'We’re finding the ideal EA for your task'),
                react_1.default.createElement(styled_1.Subtitle, null, isDraft
                    ? 'This task has not been delegated yet.'
                    : 'Chat with your EA will be available shortly')),
            isDraft && (react_1.default.createElement(styled_1.CTAOuter, null,
                react_1.default.createElement(buttons_1.BigButtonPrimary, { onClick: openModal }, hasOnSeeDetailsLinkClick
                    ? 'Delegate to an EA'
                    : 'Continue editing'),
                hasOnSeeDetailsLinkClick && (react_1.default.createElement(styled_1.Link, { onClick: onSeeDetailsLinkClick }, 'See details'))))
        // : (
        //   /* TODO: T-7636 on Cancel Click Mutation */
        // <PoolBigButtonSecondary>{'Cancel request'}</PoolBigButtonSecondary>
        // )
        )));
};
exports.default = PoolPendingActivity;
