"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullCalendarDoubleStyle = void 0;
const styled_components_1 = require("styled-components");
const devices_1 = require("uni/style/devices");
exports.FullCalendarDoubleStyle = (0, styled_components_1.createGlobalStyle) `
  .double-fc-hidden {
    display: none;
  }

  .fc-theme-standard {
    .fc-list {
      border: none;
    }

    .fc-list-day-cushion {
      background: none;
    }
  }

  .fc-scroller {
    ${(0, devices_1.screenS)((0, styled_components_1.css) `
      overflow: visible !important;
    `)}
  }

  .fc .fc-list-day-cushion {
    padding: 0px;
  }

  .fc-list-day {
    :first-child {
      .fc-list-day-cushion {
        & > div {
          margin-top: 0px;
        }
      }
    }
  }

  .fc .fc-list-table tr td {
    padding: 0px;
  }

  .fc .fc-list-table tr:last-child td {
    ${(0, devices_1.screenS)((0, styled_components_1.css) `
      padding: 0px 0px 24px;
    `)}
  }

  .fc-list-day > th {
    border: none;
  }

  .fc-list-event > td {
    border: none;
  }

  .fc .fc-list-event:hover td {
    background: none;
  }

  .fc-list-event-graphic {
      display: none;
  }

  .fc-list-empty {
    border-radius: 16px;
  }
`;
