"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generated_1 = require("uni/graphql/hooks/generated");
const useGetTodolistSortingLabel = () => {
    return (0, react_1.useCallback)((todolistSorting) => {
        if (todolistSorting === generated_1.TodoListSorting.DUE_DATES) {
            return 'By due date';
        }
        else {
            return 'By status';
        }
    }, []);
};
exports.default = useGetTodolistSortingLabel;
