"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toTimeTrackingEntryDateInfo = ({ startedAtDateTime, endedAtDateTime, }) => ({
    startedAt: startedAtDateTime === null || startedAtDateTime === void 0 ? void 0 : startedAtDateTime.toISO(),
    endedAt: endedAtDateTime === null || endedAtDateTime === void 0 ? void 0 : endedAtDateTime.toISO(),
    duration: startedAtDateTime && endedAtDateTime
        ? endedAtDateTime.diff(startedAtDateTime).toISO()
        : null,
});
exports.default = toTimeTrackingEntryDateInfo;
