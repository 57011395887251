"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useCompletedTopicListQuery_1 = require("../useCompletedTopicListQuery");
const useEmailDigestTopicListQuery_1 = require("../useEmailDigestTopicListQuery");
const useMainTopicListQuery_1 = require("../useMainTopicListQuery");
const useProjectTopicListQuery_1 = require("../useProjectTopicListQuery");
const mayInsertTopicIntoTopicListQueries = (cache, topic) => {
    var _a;
    if ((0, useMainTopicListQuery_1.shouldBeInList)(topic)) {
        (0, useMainTopicListQuery_1.insertTopic)(cache, topic);
    }
    if ((0, useEmailDigestTopicListQuery_1.shouldBeInList)(topic)) {
        (0, useEmailDigestTopicListQuery_1.insertTopic)(cache, topic);
    }
    if ((0, useCompletedTopicListQuery_1.shouldBeInList)(topic)) {
        (0, useCompletedTopicListQuery_1.insertTopic)(cache, topic);
    }
    if (topic.__typename === 'Todo') {
        const projectId = (_a = topic.project) === null || _a === void 0 ? void 0 : _a.id;
        if (projectId) {
            (0, useProjectTopicListQuery_1.insertTopic)(cache, topic, projectId);
        }
    }
};
exports.default = mayInsertTopicIntoTopicListQueries;
