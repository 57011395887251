"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// value must be milliseconds
const fromMilliseconds = (value, unit) => {
    switch (unit) {
        case 'millisecond':
        case 'milliseconds':
            return value;
        case 'second':
        case 'seconds':
            return value / 1000;
        default:
            throw new Error(`Unsupported unit: ${unit}`);
    }
};
exports.default = fromMilliseconds;
