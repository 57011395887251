"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
/**
 * Fetch more entries if last visible section is missing entries (i.e. no duration)
 */
const useOnSectionHeaderVisible = ({ entries, editableEntrySections, lockedEntrySections, onFetchMore, }) => {
    const [lastVisibleSectionDate, setLastVisibleSectionDate] = (0, react_1.useState)(null);
    /**
     * Reset last visible section date if entries have been removed.
     * Keep in mind that another tab might have removed entries by querying
     * the entry list with no _after_ cursor.
     *
     * See `timeTrackingEntriesList` in `uni/graphql/pagination.ts`
     */
    const prevEntriesLength = (0, react_1.useRef)(entries.length);
    (0, react_1.useEffect)(() => {
        if (entries.length < prevEntriesLength.current) {
            // Reset last visible section date if entries have been removed
            setLastVisibleSectionDate(null);
        }
        prevEntriesLength.current = entries.length;
    }, [entries]);
    (0, react_1.useEffect)(() => {
        if (!lastVisibleSectionDate)
            return;
        // ⚡️ Search through locked sections only if not empty
        const section = (lockedEntrySections.length !== 0
            ? lockedEntrySections
            : editableEntrySections).find(({ date }) => date.equals(lastVisibleSectionDate));
        if (!section || section.duration)
            return;
        onFetchMore();
    }, [
        editableEntrySections,
        lastVisibleSectionDate,
        lockedEntrySections,
        onFetchMore,
    ]);
    return (0, react_1.useCallback)((date) => {
        if (lastVisibleSectionDate && lastVisibleSectionDate <= date)
            return;
        setLastVisibleSectionDate(date);
    }, [lastVisibleSectionDate]);
};
exports.default = useOnSectionHeaderVisible;
