"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useDoubleClick = (onClick, onDoubleClick, timeout = 250) => {
    const clickTimeout = (0, react_1.useRef)(null);
    const clearClickTimeout = (0, react_1.useCallback)(() => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
        }
    }, []);
    const handleClick = (0, react_1.useCallback)(() => {
        const isDoubleClicking = !!clickTimeout.current;
        clearClickTimeout();
        if (isDoubleClicking) {
            onDoubleClick();
        }
        else {
            clickTimeout.current = setTimeout(() => {
                clearClickTimeout();
                onClick();
            }, timeout);
        }
    }, [clearClickTimeout, onClick, onDoubleClick, timeout]);
    return handleClick;
};
exports.default = useDoubleClick;
