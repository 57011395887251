"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const DEFAULT_PAYOUT_PER_TOKEN = 1500; // 1500 cents, 15 USD
const HARD_CODED_PAY_CURRENCY_CODE = 'USD';
const useAdhocTodoFormattedAssistantCompensation = (adhocTodo) => {
    var _a;
    return (0, react_1.useMemo)(() => {
        var _a, _b, _c;
        const lineItem = (_b = (_a = adhocTodo.checkoutSession) === null || _a === void 0 ? void 0 : _a.lineItems) === null || _b === void 0 ? void 0 : _b[0];
        if (!(lineItem === null || lineItem === void 0 ? void 0 : lineItem.quantity))
            return null;
        const assistantPayoutPerTokenString = (_c = lineItem.price) === null || _c === void 0 ? void 0 : _c.metadata.assistantPayout;
        const assistantPayoutPerToken = assistantPayoutPerTokenString
            ? parseInt(assistantPayoutPerTokenString)
            : DEFAULT_PAYOUT_PER_TOKEN;
        return Intl.NumberFormat(navigator.language, {
            style: 'currency',
            currency: HARD_CODED_PAY_CURRENCY_CODE,
        }).format((lineItem.quantity * assistantPayoutPerToken) / 100);
    }, [(_a = adhocTodo.checkoutSession) === null || _a === void 0 ? void 0 : _a.lineItems]);
};
exports.default = useAdhocTodoFormattedAssistantCompensation;
