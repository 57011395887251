"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useOnVisible = (element, callback) => {
    const observer = (0, react_1.useRef)();
    (0, react_1.useEffect)(() => {
        if (element) {
            observer.current = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    callback();
                }
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            });
            observer.current.observe(element);
        }
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [element, callback]);
};
exports.default = useOnVisible;
