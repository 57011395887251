"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoreIcon = exports.ErrorIcon = exports.Outer = exports.BubbleContextualButton = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const mixins_1 = require("uni/style/mixins");
const error_solid_l_svg_1 = require("uni/system/icons/error-solid-l.svg");
const more_xs_svg_1 = require("uni/system/icons/more-xs.svg");
// TODO export these and use in Bubble
const avatarSize = 3 * 8;
// TODO this shouldn't get that much added to it
const gutterWidth = avatarSize + 3 * 8;
exports.BubbleContextualButton = styled_components_1.default.div `
  ${(0, mixins_1.square)(32)};
  user-select: none;
  cursor: pointer;
  align-self: center;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $hasError }) => $hasError &&
    (0, styled_components_1.css) `
      opacity: 1;
    `}
`;
exports.Outer = styled_components_1.default.div `
  width: 100%;
  margin: 8px 0;
  padding: 0 ${gutterWidth}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;

  ${({ $isRight }) => $isRight && `flex-direction: row-reverse;`}

  /* TODO This needs to be changed */
  ${({ $noGutter }) => $noGutter &&
    (0, styled_components_1.css) `
      padding: 0 16px;
    `}
  
  ${({ $short }) => $short &&
    (0, styled_components_1.css) `
      margin-top: 4px;
    `}

  ${({ $tight }) => $tight &&
    (0, styled_components_1.css) `
      margin-top: 0;
    `}

  ${({ $contextualMenu }) => $contextualMenu &&
    (0, styled_components_1.css) `
      &:hover ${exports.BubbleContextualButton} {
        opacity: 1;
      }
    `}
`;
exports.ErrorIcon = (0, styled_components_1.default)(error_solid_l_svg_1.ReactComponent) `
  color: ${colors_1.flamingo5};
`;
exports.MoreIcon = (0, styled_components_1.default)(more_xs_svg_1.ReactComponent) `
  color: ${colors_1.gray6};
`;
