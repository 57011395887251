"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.composerState = void 0;
const client_1 = require("@apollo/client");
exports.composerState = (0, client_1.makeVar)({
    focused: false,
    highlighted: false,
    silent: false,
    forceFocus: false,
    uploadState: null,
    forceUpdateValueFromDraft: false,
    __typename: 'ComposerState',
});
const resolvers = {
    Query: {
        fields: {
            composer: {
                read: () => (0, exports.composerState)(),
            },
        },
    },
};
exports.default = resolvers;
