"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApplicationStage = exports.renderText = exports.renderTextWithLineBreaks = void 0;
const react_1 = __importStar(require("react"));
const generated_1 = require("uni/graphql/hooks/generated");
const types_1 = require("./types");
function renderTextWithLineBreaks(text) {
    text = (text || '').trim();
    if (!text) {
        return '';
    }
    return text.split('\n').map((line, index) => (react_1.default.createElement(react_1.Fragment, { key: index },
        index > 0 && react_1.default.createElement("br", null),
        line)));
}
exports.renderTextWithLineBreaks = renderTextWithLineBreaks;
function renderText(text) {
    if (!text) {
        return text;
    }
    return renderTextWithLineBreaks(text.replace(/\\-/g, '-'));
}
exports.renderText = renderText;
const MATCHING_STATUS_TO_APPLICATION_STAGE = {
    [generated_1.MatchingAssistantStatus.ONBOARDED]: types_1.ApplicationStage.ONBOARDED,
    [generated_1.MatchingAssistantStatus.SCHEDULED]: types_1.ApplicationStage.SCHEDULED,
    [generated_1.MatchingAssistantStatus.NO_MATCH]: types_1.ApplicationStage.NO_MATCH,
    [generated_1.MatchingAssistantStatus.CONFIRMED]: types_1.ApplicationStage.ACCEPTED,
};
const getApplicationStage = (matching) => {
    var _a;
    const stageFromStatus = matching.status && MATCHING_STATUS_TO_APPLICATION_STAGE[matching.status];
    if (stageFromStatus) {
        return stageFromStatus;
    }
    switch ((_a = matching.proposal) === null || _a === void 0 ? void 0 : _a.reply) {
        case generated_1.MatchingProposalReply.YES:
            return types_1.ApplicationStage.ACCEPTED;
        case generated_1.MatchingProposalReply.NO:
            return types_1.ApplicationStage.DECLINE;
        case generated_1.MatchingProposalReply.NEED_MORE_INFO:
            return types_1.ApplicationStage.MORE_INFO;
    }
    if (matching.proposal) {
        return types_1.ApplicationStage.IS_WAITING_FOR_MY_REPLY;
    }
    if (matching.application) {
        return types_1.ApplicationStage.APPLIED;
    }
    return null;
};
exports.getApplicationStage = getApplicationStage;
