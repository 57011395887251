"use strict";
/**
 * New color palette
 * https://www.figma.com/file/CIYSAMSkgFs9DgAabdEyk6Ua/UI-Kit?node-id=11712%3A1979
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.violet3 = exports.violet2 = exports.violet1 = exports.blue6 = exports.blue5 = exports.blue4 = exports.blue3 = exports.blue2 = exports.blue1 = exports.green6 = exports.green5 = exports.green4 = exports.green3 = exports.green2 = exports.green1 = exports.yellow6 = exports.yellow5 = exports.yellow4 = exports.yellow3 = exports.yellow2 = exports.yellow1 = exports.orange6 = exports.orange5 = exports.orange4 = exports.orange3 = exports.orange2 = exports.orange1 = exports.flamingo6 = exports.flamingo5 = exports.flamingo4 = exports.flamingo3 = exports.flamingo2 = exports.flamingo1 = exports.doublePurple = exports.purple6 = exports.purple5 = exports.purple4 = exports.purple3 = exports.purple2 = exports.purple1 = exports.gray8 = exports.gray7 = exports.gray6 = exports.gray5 = exports.gray4 = exports.gray3 = exports.gray2 = exports.gray1 = exports.white = exports.black = void 0;
exports.hexaToRgba = exports.lake2 = exports.lake = exports.corn2 = exports.corn = exports.water2 = exports.water = exports.rose = exports.lavender2 = exports.lavender = exports.rose6 = exports.rose5 = exports.rose4 = exports.rose3 = exports.rose2 = exports.rose1 = exports.violet6 = exports.violet5 = exports.violet4 = void 0;
exports.black = '#161618';
exports.white = '#ffffff';
exports.gray1 = '#f7f8f9';
exports.gray2 = '#ecedf0';
exports.gray3 = '#e1e2e7';
exports.gray4 = '#d4d6dd';
exports.gray5 = '#c1c4cd';
exports.gray6 = '#818691';
exports.gray7 = '#4c4e55';
exports.gray8 = '#313236';
exports.purple1 = '#ece9fd';
exports.purple2 = '#d0c9fb';
exports.purple3 = '#b0a6f9';
exports.purple4 = '#9082f7';
exports.purple5 = '#644bff';
exports.purple6 = '#581bf5';
exports.doublePurple = exports.purple5;
exports.flamingo1 = '#fcefee';
exports.flamingo2 = '#f8d6d2';
exports.flamingo3 = '#f4bab5';
exports.flamingo4 = '#f19f97';
exports.flamingo5 = '#ff6e66';
exports.flamingo6 = '#eb5144';
exports.orange1 = '#faeee8';
exports.orange2 = '#f2d3c5';
exports.orange3 = '#eab69f';
exports.orange4 = '#e29879';
exports.orange5 = '#e66636';
exports.orange6 = '#c54628';
exports.yellow1 = '#fdf6eb';
exports.yellow2 = '#f9e9cb';
exports.yellow3 = '#f5d9a9';
exports.yellow4 = '#f0ca87';
exports.yellow5 = '#f4b144';
exports.yellow6 = '#df8d3c';
exports.green1 = '#edf7ee';
exports.green2 = '#d2ead5';
exports.green3 = '#b5ddb9';
exports.green4 = '#98ce9e';
exports.green5 = '#54bc6d';
exports.green6 = '#4d9549';
exports.blue1 = '#eaf4fd';
exports.blue2 = '#bfdef8';
exports.blue3 = '#99cbf4';
exports.blue4 = '#75b8f0';
exports.blue5 = '#2a92e8';
exports.blue6 = '#146eb8';
exports.violet1 = '#f5e9fa';
exports.violet2 = '#debaef';
exports.violet3 = '#cd97e6';
exports.violet4 = '#bd75de';
exports.violet5 = '#9727cc';
exports.violet6 = '#712097';
exports.rose1 = '#fcf0f7';
exports.rose2 = '#f4cee7';
exports.rose3 = '#eeb5db';
exports.rose4 = '#e99ccf';
exports.rose5 = '#dc71ba';
exports.rose6 = '#b53c8e';
/**
 * Colors for Client Reporting
 */
exports.lavender = '#a375f1';
exports.lavender2 = '#835ef5';
exports.rose = '#e378c1';
exports.water = '#58a5f8';
exports.water2 = '#6978ff';
exports.corn = '#f6bc6c';
exports.corn2 = '#ed9f51';
exports.lake = '#6bc8d0';
exports.lake2 = '#3eb6c0';
const hexaToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
exports.hexaToRgba = hexaToRgba;
