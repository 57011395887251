"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const constants_1 = require("../constants");
const useGetHandoffProgress = ({ folders }) => {
    return (0, react_1.useMemo)(() => {
        if (!folders)
            return -1;
        return (folders
            //Only look for the handoff tab folder
            .flatMap((folder) => (folder.id === constants_1.HANDOFF_ID ? folder.sections : []))
            .flatMap((section) => (!section.isStandard ? [] : section.fields))
            .reduce(([completed, total], field) => {
            var _a;
            if (field.isStandard) {
                total++;
                // count standard fields that have a non-empty value or non-empty notepad if no input type
                if (((_a = field.values) === null || _a === void 0 ? void 0 : _a[0]) || (!field.input && field.notepad)) {
                    completed++;
                }
            }
            return [completed, total];
        }, [0, 0])
            .reduce((completed, total) => Math.round((completed / total) * 100)) ||
            0);
    }, [folders]);
};
exports.default = useGetHandoffProgress;
