"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const error_1 = require("@apollo/client/link/error");
const datadog_1 = require("uni/lib/logger/datadog");
const logGraphQLError = (0, datadog_1.logErrorToDatadog)('apollo.graphql_error');
const logNetworkError = (0, datadog_1.logErrorToDatadog)('apollo.network_error');
const createErrorLink = () => (0, error_1.onError)(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((graphQLError) => {
            logGraphQLError(graphQLError, {
                locations: graphQLError.locations &&
                    Array.isArray(graphQLError.locations) &&
                    graphQLError.locations.map((l) => l.line),
                paths: graphQLError.path &&
                    Array.isArray(graphQLError.path) &&
                    graphQLError.path.map((p) => p),
            });
        });
    }
    if (networkError) {
        logNetworkError(networkError, {
            name: networkError.name,
            stack: JSON.stringify(networkError.stack || '{}'),
        });
    }
});
exports.default = createErrorLink;
