"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_1 = require("react-router");
const react_router_dom_1 = require("react-router-dom");
const useDeleteSearchParam = () => {
    const location = (0, react_router_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    return (0, react_1.useCallback)((paramKey) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(paramKey);
        const newSearch = searchParams.toString();
        history.replace({
            search: newSearch,
        });
    }, [history, location.search]);
};
exports.default = useDeleteSearchParam;
