"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThumbsDownIcon = exports.ThumbsUpIcon = exports.Outer = exports.outerStyle = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const thumb_down_solid_s_svg_1 = require("uni/system/icons/thumb-down-solid-s.svg");
const thumb_up_solid_s_svg_1 = require("uni/system/icons/thumb-up-solid-s.svg");
exports.outerStyle = (0, styled_components_1.css) `
  display: flex;
  padding: 6px;
  align-items: flex-start;
  border-radius: 8px;
`;
exports.Outer = styled_components_1.default.div `
  ${exports.outerStyle}
  background-color: ${({ $isPositive }) => ($isPositive ? colors_1.green1 : colors_1.flamingo1)};
`;
exports.ThumbsUpIcon = (0, styled_components_1.default)(thumb_up_solid_s_svg_1.ReactComponent) `
  color: ${colors_1.green5};
`;
exports.ThumbsDownIcon = (0, styled_components_1.default)(thumb_down_solid_s_svg_1.ReactComponent) `
  color: ${colors_1.flamingo5};
`;
