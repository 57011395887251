"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const ClientsManager_1 = require("uni/components/apollo/ClientsManager");
const generated_1 = require("uni/graphql/hooks/generated");
const useGetTimeTrackingTag = () => {
    const { data: tagListData } = (0, generated_1.useTimeTrackingTagsListQuery)({
        fetchPolicy: 'cache-and-network',
    });
    const { getNonWorkspacedApolloClient } = (0, ClientsManager_1.useClientsManager)();
    const client = getNonWorkspacedApolloClient();
    return (0, react_1.useCallback)((topicTitle) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const result = yield client.query({
            query: generated_1.GetInferredTimeTrackingTagDocument,
            variables: {
                input: {
                    topicTitle,
                },
            },
        });
        if (!result.data)
            return undefined;
        const tagId = (_a = result.data.getInferredTimeTrackingTag) === null || _a === void 0 ? void 0 : _a.tagId;
        if (!tagId || !tagListData)
            return undefined;
        return tagListData.timeTrackingTagsList.items.find((tag) => tag.id === tagId);
    }), [client, tagListData]);
};
exports.default = useGetTimeTrackingTag;
