"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
// custom useEffect hook that skips the initial render
const useDidUpdateEffect = (callback, deps) => {
    const didMountRef = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        if (didMountRef.current)
            return callback();
        else
            didMountRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
exports.default = useDidUpdateEffect;
