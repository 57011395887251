"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
// Add a small margin of error, for Edge
const ADJUST_LINES_THRESHOLD = 2;
const useAdjustTextareaLines = ({ ref, numberOfLines = [2, 10] }) => (0, react_1.useCallback)(() => {
    if (ref && ref.current) {
        const textarea = ref.current;
        // const prevRows = parseInt(textarea.getAttribute('rows') || '1', 10)
        const [minRows, maxRows] = numberOfLines;
        let rows = minRows;
        let hasScroll = false;
        let prevHeight = textarea.clientHeight;
        let height;
        // Incrementally change number of rows to avoid scroll
        do {
            textarea.setAttribute('rows', `${rows}`);
            height = textarea.clientHeight;
            hasScroll = textarea.scrollHeight > height + ADJUST_LINES_THRESHOLD;
        } while (
        // Disable increasing of textarea
        // !this._dontIncreaseOnAdjust &&
        // Textarea has a scroll bar (= all text doesn’t fit)
        hasScroll &&
            // Textarea is height is changing (= element is actually being resized)
            // Although height does not change on first loop, so bypass the test for first loop)
            (prevHeight !== height || rows === minRows) &&
            // We did not reach the maximum number of rows allowed
            rows < maxRows &&
            // Increment number of lines for next loop
            ++rows &&
            // Prepare prevHeight for next loop
            (prevHeight = height));
    }
}, [numberOfLines, ref]);
exports.default = useAdjustTextareaLines;
