"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useSwitch = (initialValue = false) => {
    const [value, setValue] = (0, react_1.useState)(initialValue);
    return [
        value,
        (0, react_1.useCallback)(() => setValue((prev) => !prev), [setValue]),
        (0, react_1.useCallback)(() => setValue(true), [setValue]),
        (0, react_1.useCallback)(() => setValue(false), [setValue]),
        setValue,
    ];
};
exports.default = useSwitch;
