"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EMAIL_ADDRESS_REGEX = /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{2,}$/i;
const validateEmails = (emails) => {
    for (let i = 0; i < emails.length; i++) {
        const valid = EMAIL_ADDRESS_REGEX.test(emails[i]);
        if (!valid) {
            return false;
        }
    }
    return true;
};
exports.default = validateEmails;
