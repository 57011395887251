"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardElement = exports.Input = exports.Section = exports.Button = exports.Label = exports.SectionTitle = exports.ChevronLeftIcon = exports.NavigationContainer = exports.WarningIcon = exports.NoticeContent = exports.NoticeErrorContainer = exports.Outer = void 0;
const react_stripe_elements_1 = require("react-stripe-elements");
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const chevron_left_s_svg_1 = require("uni/system/icons/chevron-left-s.svg");
const warning_xs_svg_1 = require("uni/system/icons/warning-xs.svg");
exports.Outer = styled_components_1.default.div `
  padding: 20px;
  margin: auto;
  max-width: 680px;
  position: relative;
`;
exports.NoticeErrorContainer = styled_components_1.default.div `
  position: absolute;
  display: flex;
  align-items: center;
  padding: 8px;
  right: 80px;
  left: 80px;
  top: 10px;

  /* Secondary / Flamingo1 */
  background: ${colors_1.flamingo1};
  border-radius: 8px;
`;
exports.NoticeContent = styled_components_1.default.div `
  ${text_1.bodyMedium}
  font-size: 14px;
  color: ${colors_1.flamingo5};
`;
exports.WarningIcon = (0, styled_components_1.default)(warning_xs_svg_1.ReactComponent) `
  color: ${colors_1.flamingo5};
  margin-right: 8px;
`;
exports.NavigationContainer = styled_components_1.default.div `
  margin-bottom: 48px;
`;
exports.ChevronLeftIcon = (0, styled_components_1.default)(chevron_left_s_svg_1.ReactComponent) `
  cursor: pointer;
  color: ${colors_1.gray6};
`;
exports.SectionTitle = styled_components_1.default.div `
  ${text_1.titleMedium}
  color: ${colors_1.black};
  margin-bottom: 24px;
  user-select: none;
`;
exports.Label = styled_components_1.default.div `
  ${text_1.calloutRegular}
  color: ${colors_1.gray6};
  margin-bottom: 10px;
`;
exports.Button = styled_components_1.default.button `
  ${text_1.bodySemibold}
  background: ${colors_1.gray2};
  border: 2px solid ${colors_1.gray2};
  border-radius: 24px;
  padding: 6px 13px;
  cursor: pointer;
  margin-right: 14px;

  ${({ $isPrimary }) => $isPrimary &&
    (0, styled_components_1.css) `
      background: ${colors_1.purple5};
      border: 2px solid ${colors_1.purple5};
      color: white;

      &:hover {
        background: ${colors_1.purple6};
        border: 2px solid ${colors_1.purple6};
      }
    `}

  ${({ $isDisabled }) => $isDisabled &&
    (0, styled_components_1.css) `
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
exports.Section = styled_components_1.default.div `
  margin-top: 40px;
  display: flex;

  ${({ $column }) => $column &&
    (0, styled_components_1.css) `
      flex-flow: column;
    `}

  ${({ $noSeparator }) => !$noSeparator &&
    (0, styled_components_1.css) `
      border-top: none !important;
      padding-top: 0 !important;
    `}

  & + & {
    border-top: 1px solid ${colors_1.gray2};
    padding-top: 40px;
  }
`;
const inputStyles = (0, styled_components_1.css) `
  ${text_1.bodyRegular}
  background: #ecedf0;
  border-radius: 10px;
  padding: 8px 10px 7px 10px;
  border: 2px solid #ecedf0;
  margin-bottom: 16px;

  &::placeholder {
    color: #9095a2;
  }
`;
const focusStyles = (0, styled_components_1.css) `
  border: 2px solid #9082f7;
  background: white;
`;
const errorStyles = (0, styled_components_1.css) `
  border: 2px solid ${colors_1.flamingo6};
`;
exports.Input = styled_components_1.default.input `
  ${inputStyles}

  ${({ $hasError }) => $hasError && errorStyles}

    &:focus {
    ${focusStyles}
  }
`;
exports.CardElement = (0, styled_components_1.default)(react_stripe_elements_1.CardElement) `
  ${inputStyles}
  height: 40px;
  padding-top: 9px;
  ${({ $isFocused }) => $isFocused && focusStyles}
  ${({ $hasError }) => $hasError && errorStyles}
`;
