"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const luxon_1 = require("luxon");
const formatDueDate = ({ dueDate, isDueAsap = false }, now = luxon_1.DateTime.local()) => {
    if (dueDate.hasSame(now, 'day')) {
        if (isDueAsap) {
            return 'ASAP';
        }
        return 'Today';
    }
    if (dueDate.hasSame(now.plus({ day: 1 }), 'day')) {
        return 'Tomorrow';
    }
    const days = dueDate.diff(now, 'days').days;
    if (days > 0 && days <= 7) {
        return dueDate.toLocaleString({ weekday: 'long' });
    }
    return dueDate
        .toLocaleString({
        weekday: 'short',
        day: 'numeric',
        month: 'short',
    })
        .replace(',', '');
};
exports.default = formatDueDate;
