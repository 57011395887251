"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Date = exports.OptionsOuter = exports.Title = exports.Outer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
// 💩 Import is all over the place but that'll do for now
const styled_1 = require("uni/components/timelineWidgets/Bubble/styled");
const colors_1 = require("uni/style/colors");
const text_1 = require("uni/style/text");
const styled_2 = require("./DelegationCoachOption/styled");
exports.Outer = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;
// 💩 Avatar + padding
const guttered = (0, styled_components_1.css) `
  margin-left: 48px;
  padding: 0 12px;

  max-width: ${styled_1.bubbleMaxWidthRatio * 100}%;
`;
exports.Title = styled_components_1.default.h1 `
  margin: 0 0 12px;
  ${text_1.headingBold}
  ${guttered}
`;
exports.OptionsOuter = styled_components_1.default.div `
  ${guttered}
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: ${styled_2.GAP}px;
`;
exports.Date = styled_components_1.default.div `
  ${guttered}
  ${text_1.calloutRegular}
  color: ${colors_1.gray6};
  margin-bottom: 10px;
`;
